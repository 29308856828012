@import '~@angular/material/theming';
@import '../themes/color-helpers';
// Plus imports for other components in your app.

// CAPTURE ME COLORS
$cp-primary-color: $mat-teal;
$cp-accent-color: $mat-amber;

// Typography
$typography: mat-typography-config(
	$font-family:   'Roboto, "Helvetica Neue", sans-serif',
	$display-4:     mat-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
	$display-3:     mat-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
	$display-2:     mat-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
	$display-1:     mat-typography-level(34px, 40px, 400),
	$headline:      mat-typography-level(24px, 32px, 500),
	$title:         mat-typography-level(20px, 32px, 500),
	$subheading-2:  mat-typography-level(16px, 28px, 400),
	$subheading-1:  mat-typography-level(15px, 24px, 400),
	$body-2:        mat-typography-level(14px, 24px, 500),
	$body-1:        mat-typography-level(14px, 20px, 400),
	$caption:       mat-typography-level(12px, 20px, 400),
	$button:        mat-typography-level(14px, 14px, 500),
	// Line-height must be unit-less fraction of the font-size.
	$input:         mat-typography-level(inherit, 1.125, 400)
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core($typography);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$cp-primary-palette: mat-palette($cp-primary-color);
$cp-accent-palette:  mat-palette($cp-accent-color, 500, 200, 700);

// The warn palette is optional (defaults to red).
$cp-warn-palette:    mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$cp-light-theme: mat-light-theme((
  color: (
    primary: $cp-primary-palette,
    accent: $cp-accent-palette,
    warn: $cp-warn-palette,  
	)
));

$mat-dark-theme-background: (
  status-bar: black,
  app-bar:    #212121,
  background: #121212,
  hover:      rgba(white, 0.04), // TODO(kara): check style with Material Design UX
  card:       #121212,
  dialog:     #212121,
  disabled-button: rgba(white, 0.12),
  raised-button: map-get($mat-grey, 800),
  focused-button: $light-focused,
  selected-button: #212121,
  selected-disabled-button: map-get($mat-grey, 800),
  disabled-button-toggle: black,
  unselected-chip: map-get($mat-grey, 700),
  disabled-list-option: black,
  tooltip: map-get($mat-grey, 700),
);
$cp-dark-theme: mat-dark-theme((
  color: (
    primary: mat-palette($cp-primary-color, 200, 100, 400),
    accent: mat-palette($cp-accent-color, 500, 200, 700),
    warn: mat-palette($mat-deep-orange)
	),
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
$white-palette: (
  50: white,
  100: white,
  200: white,
  300: white,
  400: white,
  500: white,
  600: white,
  700: white,
  800: white,
  900: white,
  A100: white,
  A200: white,
  A400: white,
  A700: white,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  )
);

@import "./component-themes";

.light-theme {
	@include angular-material-theme($cp-light-theme);
	// Include all custom component themes
	@include app-custom-component-themes($cp-light-theme, $typography);

	$palettes: (
		primary: $cp-primary-palette,
		accent: $cp-accent-palette,
		"white": $white-palette, 
		warn: $cp-warn-palette
	);
	@include app-color-classes($palettes);
	#theme-color-ref { background-color: mat-color($cp-primary-color, 700) !important }

	mat-chip.mat-chip.mat-standard-chip {
		border: 1px solid $dark-dividers;
	
		.mat-icon {
			color: $dark-secondary-text;
		}
	}
}


.dark-theme {
	@include angular-material-theme($cp-dark-theme);
	// Include all custom component themes
	@include app-custom-component-themes($cp-dark-theme, $typography);

	$palettes: (
		primary: $cp-primary-palette,
		accent: $cp-accent-palette,
		"white": $white-palette, 
		warn: $cp-warn-palette
	);
	@include app-color-classes($palettes);
  #theme-color-ref { background-color: #000000 !important }

	.mat-progress-bar-background {
    fill: #323232;
  }
  .mat-progress-bar-buffer {
    background-color: #323232;
  }
	mat-chip.mat-chip.mat-standard-chip {
		border: 1px solid $light-dividers;
	
		.mat-icon {
			color: $light-secondary-text;
		}
	}

	.mat-drawer-backdrop.mat-drawer-shown {
		background-color: rgba(0, 0, 0, .6)
	}
}




mat-toolbar.mat-toolbar {
	@include mat-elevation-transition;
	@include mat-elevation(2);

	.width-container {
		max-width: calc(532px - 32px);
	}

	.toolbar-items {
		display: flex;
		align-items: center;
		width: 100%;
	
		button[mat-icon-button]:first-child {
			margin-left: -8px;
			margin-right: 22px;
		}

		.mat-button-base:last-child {
			margin-right: -8px;
		}

		.mat-button-base {
			flex-grow: 0;
			flex-shrink: 0;
		}
	}

	.titles {
		display: inline-flex;
		flex: 1 1 auto;
		flex-direction: column;
		flex-wrap: nowrap;
		align-items: flex-start;
		min-width: 0;

		padding: 6px 0;
		white-space: pre-wrap;

		span.title, 
		span.subtitle:not(.overflow) {
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			display: inline-block;
			max-width: 100%;
		}

		span.title {
			line-height: 24px;
			margin: 0;
		}
		span.subtitle {
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
			opacity: .87;
		}
	}
}


$bn-background: map-get($cp-primary-palette, 500);
$bn-text: map-get(map-get($cp-primary-palette, contrast), 500);
nav.mat-tab-nav-bar[mat-bottom-navigation] {

	@include mat-elevation-transition;
	@include mat-elevation(2);

	background-color: $bn-background;
	color: $bn-text;

	border-bottom: 0;


	mat-ink-bar.mat-ink-bar {
		background-color: $bn-text !important;
	}


	a.mat-tab-link {
    text-transform: uppercase;
    letter-spacing: .089em;
    font-weight: 500;
		color: $bn-text;

		&.mat-tab-label-active {
			opacity: 1;
		}

		&[has-icon] {
			height: 56px;
		}
	}

}


// Bottomsheet
.app-sheet-maxwidth {
	.mat-bottom-sheet-container {
		max-width: 532px;
		min-width: 0px;
		width: 100vw;
		padding: 0;
	}

	&.full-height .mat-bottom-sheet-container {
    max-height: calc(100vh - 56px - 56px);
		height: calc(100vh - 56px - 56px);
	}
}
.app-darker-backdrop {
	background-color: rgba(0, 0, 0, .32);
}


/// CHIPS
.light-theme, .dark-theme {
	mat-chip.mat-chip.mat-standard-chip {
		background-color: transparent;
		font-weight: 400;

		.mat-icon {
			opacity: 1;
		}
	}	
}


/// Mat-fab with text
.mat-fab[has-text] {
	width: auto;
	border-radius: 24px;
	height: 48px;
	pointer-events: all;

	.mat-button-wrapper {
		padding: 12px;
    padding-right: 20px;
		height: 24px;

		span {
			display: flex;
		}
		
		> * {
			vertical-align: top;
		}

		mat-icon {
			margin-right: 8px;
		}
	}
}
.mat-mini-fab:not([has-text]),
.mat-fab:not([has-text]) {
	.mat-button-wrapper>* {
		vertical-align: initial !important;
	}
}

.mat-button-base[has-icon] {

	padding: 0 16px 0 12px;

	.mat-button-wrapper {
		display: flex;
		align-items: center;

		mat-icon {
			margin-right: 8px;
		}
	}
}


[icon-with-text] {
	display: flex;
	align-items: center;

	&[is-inline] {
		display: inline-flex;
	}

	mat-icon {
		margin-right: 4px;
		flex-shrink: 0;
	}

	&[icon-start] {
		align-items: flex-start;
	}
}
