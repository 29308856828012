/* You can add global styles to this file, and also import other style files */

@import './style-helpers.scss';

html, body {
	height: 100%;
	width: 100%;
	/* Disables pull-to-refresh but allows overscroll glow effects. */
	overscroll-behavior-y: contain;
}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


//App max-width
.width-container {
	max-width: 532px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	box-sizing: border-box;

	&.scrollable {
		overflow-y: auto;
	}
}

.scroll-container {
	overflow-y: auto;
}

// All fields default max width
form  {
	width: 100%;
	mat-form-field {
		display: inline;
		width: 100%;
	}
}

// Button text
.mat-button-base {
	.mat-button-wrapper {
		text-transform: uppercase;
    letter-spacing: .089em;
    font-weight: 500;
	}
}

// Button icon
button.mat-button .mat-icon,
a.mat-button .mat-icon,
a.mat-raised-button .mat-icon,
a.mat-flat-button .mat-icon,
a.mat-stroked-button .mat-icon {
  margin: -4px 0 -4px -4px;
	height: 24px;
	width: 24px;
	vertical-align: middle;
	vertical-align: text-bottom;
}


.round {
	border-radius: 50%;
}

:focus {
	outline: none !important;
}

a {
	text-decoration: none;
	color: inherit;
}



.animate-rotate {
	animation: rotate 1s ease-out infinite;

	@keyframes rotate {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}

// Lightbox edit
.lightbox-back{
	background-image: none !important;

	&::after {
		content: "\e5c4";
		font-family: 'Material Icons Outlined';
		font-weight: normal;
		font-style: normal;
		font-size: 24px;
		line-height: 1;
		letter-spacing: normal;
		text-transform: none;
		display: inline-block;
		white-space: nowrap;
		word-wrap: normal;
		direction: ltr;
		text-rendering: optimizeLegibility;
		-webkit-font-smoothing: antialiased;

		height: 56px;
		line-height: 56px;
		width: 56px;
		text-align: center;
	}
}


// info panel
.info-panel {

	border-radius: 5px;
	padding: 14px;
	
	p:last-child {
		margin: 0 !important
	}
}