
@mixin app-profile-picture-theme($config-or-theme, $typography) {

	$foreground: map-get($config-or-theme, foreground);
	$secondary-text: mat-color($foreground, secondary-text);
	$primary: map-get($config-or-theme, primary);
	$is-dark: map-get($config-or-theme, is-dark);

	.profile-picture {
		@if $is-dark {
			background-color: mat-color($primary, lighter);
			color: mat-contrast($primary, A100)
		} @else {
			background-color: mat-color($primary, 100);
			color: mat-contrast($primary, 500)
		}
	}
}