
@mixin app-chat-bubble-theme($config-or-theme, $typography) {

	$foreground: map-get($config-or-theme, foreground);
	$secondary-text: mat-color($foreground, secondary-text);
	$primary: map-get($config-or-theme, primary);
	$accent: map-get($config-or-theme, accent);

	$lighter-primary: mat-color($primary, lighter);
	$lighter-primary-text: mat-contrast($primary, lighter);

	$lighter-accent: mat-color($accent, lighter);
	$lighter-accent-text: mat-contrast($accent, lighter);

	.chat-bubble-wrapper {
		
		.bubble {
			&.me {
				background-color: $lighter-accent;
				color: $lighter-accent-text;

				&::before, &::after {
					color: $lighter-accent;
				}
			}


			&.contact {
				background-color: $lighter-primary;
				color: $lighter-primary-text;

				&::before, &::after {
					color: $lighter-primary;
				}
			}

			.message-time {
				font-size: mat-font-size($typography, caption);
				line-height: 14px; // align to bottom of the text
				color: $secondary-text;
			}
		}
	}


	// app-chat-bubble {

	// 	// &.me {
	// 	// 	// My message group
	// 	// 	& + app-chat-bubble.me {
	// 	// 		// :not(.last-of-group)
	// 	// 		color: red !important
	// 	// 	}
	// 	// 	& + app-chat-bubble:not(.me) {
	// 	// 		// .last-of-group
	// 	// 		color: green !important
	// 	// 	}
	// 	// }
	// 	// &:not(.me) + app-chat-bubble.contact {
	// 	// 	// .first-of-group
	// 	// 	color: blue !important
	// 	// }



	// 	// &.contact {
	// 	// 	// My message group
	// 	// 	& + app-chat-bubble.contact {
	// 	// 		// :not(.last-of-group)
	// 	// 		color: purple !important
	// 	// 	}
	// 	// 	& + app-chat-bubble:not(.contact) {
	// 	// 		// .last-of-group
	// 	// 		color: yellow !important
	// 	// 	}
	// 	// }
	// 	// &:not(.contact) + app-chat-bubble.me {
	// 	// 	// .first-of-group
	// 	// 	color: pink !important
	// 	// }





	// 	&.me {
	// 		& + app-chat-bubble.me {
	// 			// Not last of me
	// 			color: blue !important
	// 		}
	// 		& + app-chat-bubble.contact {
	// 			// Last of contact
	// 			color: red !important
	// 		}
	// 	}


	// 	&.contact {
	// 		& + app-chat-bubble.contact {
	// 			// Not last of contact
	// 			color: green !important
	// 		}
	// 		& + app-chat-bubble.me {
	// 			// Last of me
	// 			color: yellow !important
	// 		}
	// 	}

	// }
}