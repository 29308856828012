
@mixin app-scaffold-theme($config-or-theme, $typography) {

	$foreground: map-get($config-or-theme, foreground);
	$background: map-get($config-or-theme, background);
	$primary: map-get($config-or-theme, primary);

	$secondary-text: mat-color($foreground, secondary-text);

	.main-app-scaffold {

		background-color: mat-color($background, card);

		.mat-nav-list {
			.mat-list-item {

				// default color, grey

				color: mat-color($foreground, text);
				mat-icon {
					color: inherit;
				}


				box-sizing: border-box;
				border-left: 0px solid currentColor;

				transition: background-color, .1s ease-out, color .1s ease-out, border .1s ease-out;
				.mat-list-item-content {
					transition: padding .1s ease-out;

					mat-icon {
						transition: color .1s ease-out;
					}
					.mat-line {
						font-weight: inherit;
					}
				}

				&.mat-list-item-active {

					border-left: 4px solid currentColor;

					background-color: mat-color($primary, .08) !important;
					color: darken(mat-color($primary), 10%);
					font-weight: 500;

					.mat-list-item-content {
						padding-left: 12px;
					}

				}

			}
		}

	}

}