
@mixin app-list-item-theme($config-or-theme, $typography) {

	$foreground: map-get($config-or-theme, foreground);
	$background: map-get($config-or-theme, background);

	$secondary-text: mat-color($foreground, secondary-text);

	.list-item {

		.title-row {

			.item-title {
				font-size: mat-font-size($typography, subheading-2)
				// @include mat-typography-level-to-styles($typography, subheading-2);
			}

			.item-title-suffix {
				color: $secondary-text
			}
		}

		.content-row {
			color: $secondary-text
		}




		&.album {
			.item-image:not(:empty) {
				background-color: mat-color($background, app-bar);
			}

			.title-row {

				.item-title {
					color: $secondary-text;
					font-size: 10px;
					text-transform: uppercase;
					font-weight: 500;
					letter-spacing: .15em;
					margin-bottom: 4px;
					line-height: 11px;
					// @include mat-typography-level-to-styles($typography, subheading-2);
				}
				.item-title-suffix {
					line-height: 16px;
					margin-top: -5px;
				}
			}

			.subtitle-row {
				font-size: mat-font-size($typography, subheading-2);
				line-height: 24px;
			}

		}
  }

}