@mixin app-toolbar-scaffold-theme($config-or-theme, $typography) {
	$background: map-get($config-or-theme, background);

	.toolbar-scaffold-wrapper {

		.scroll-content {
			background-color: mat-color($background, card);
		}

		.loading-overlay {
			background-color: rgba(mat-color($background, background), .8);
		}
		
	}

}