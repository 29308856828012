.red-50-bg {
  background-color: #ffebee !important;
}

.red-50 {
  background-color: #ffebee !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.red-50[disabled] {
  background-color: rgba(255, 235, 238, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.red-50-fg {
  color: #ffebee !important;
}

.red-50-border {
  border-color: #ffebee !important;
}

.red-50-border-top {
  border-top-color: #ffebee !important;
}

.red-50-border-right {
  border-right-color: #ffebee !important;
}

.red-50-border-bottom {
  border-bottom-color: #ffebee !important;
}

.red-50-border-left {
  border-left-color: #ffebee !important;
}

.red-100-bg {
  background-color: #ffcdd2 !important;
}

.red-100 {
  background-color: #ffcdd2 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.red-100[disabled] {
  background-color: rgba(255, 205, 210, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.red-100-fg {
  color: #ffcdd2 !important;
}

.red-100-border {
  border-color: #ffcdd2 !important;
}

.red-100-border-top {
  border-top-color: #ffcdd2 !important;
}

.red-100-border-right {
  border-right-color: #ffcdd2 !important;
}

.red-100-border-bottom {
  border-bottom-color: #ffcdd2 !important;
}

.red-100-border-left {
  border-left-color: #ffcdd2 !important;
}

.red-200-bg {
  background-color: #ef9a9a !important;
}

.red-200 {
  background-color: #ef9a9a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.red-200[disabled] {
  background-color: rgba(239, 154, 154, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.red-200-fg {
  color: #ef9a9a !important;
}

.red-200-border {
  border-color: #ef9a9a !important;
}

.red-200-border-top {
  border-top-color: #ef9a9a !important;
}

.red-200-border-right {
  border-right-color: #ef9a9a !important;
}

.red-200-border-bottom {
  border-bottom-color: #ef9a9a !important;
}

.red-200-border-left {
  border-left-color: #ef9a9a !important;
}

.red-300-bg {
  background-color: #e57373 !important;
}

.red-300 {
  background-color: #e57373 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.red-300[disabled] {
  background-color: rgba(229, 115, 115, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.red-300-fg {
  color: #e57373 !important;
}

.red-300-border {
  border-color: #e57373 !important;
}

.red-300-border-top {
  border-top-color: #e57373 !important;
}

.red-300-border-right {
  border-right-color: #e57373 !important;
}

.red-300-border-bottom {
  border-bottom-color: #e57373 !important;
}

.red-300-border-left {
  border-left-color: #e57373 !important;
}

.red-400-bg {
  background-color: #ef5350 !important;
}

.red-400 {
  background-color: #ef5350 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.red-400[disabled] {
  background-color: rgba(239, 83, 80, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.red-400-fg {
  color: #ef5350 !important;
}

.red-400-border {
  border-color: #ef5350 !important;
}

.red-400-border-top {
  border-top-color: #ef5350 !important;
}

.red-400-border-right {
  border-right-color: #ef5350 !important;
}

.red-400-border-bottom {
  border-bottom-color: #ef5350 !important;
}

.red-400-border-left {
  border-left-color: #ef5350 !important;
}

.red-500-bg {
  background-color: #f44336 !important;
}

.red-500 {
  background-color: #f44336 !important;
  color: white !important;
}
.red-500[disabled] {
  background-color: rgba(244, 67, 54, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.red-500-fg {
  color: #f44336 !important;
}

.red-500-border {
  border-color: #f44336 !important;
}

.red-500-border-top {
  border-top-color: #f44336 !important;
}

.red-500-border-right {
  border-right-color: #f44336 !important;
}

.red-500-border-bottom {
  border-bottom-color: #f44336 !important;
}

.red-500-border-left {
  border-left-color: #f44336 !important;
}

.red-bg {
  background-color: #f44336 !important;
}

.red {
  background-color: #f44336 !important;
  color: white !important;
}
.red[disabled] {
  background-color: rgba(244, 67, 54, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.red-fg {
  color: #f44336 !important;
}

.red-border {
  border-color: #f44336 !important;
}

.red-border-top {
  border-top-color: #f44336 !important;
}

.red-border-right {
  border-right-color: #f44336 !important;
}

.red-border-bottom {
  border-bottom-color: #f44336 !important;
}

.red-border-left {
  border-left-color: #f44336 !important;
}

.red-600-bg {
  background-color: #e53935 !important;
}

.red-600 {
  background-color: #e53935 !important;
  color: white !important;
}
.red-600[disabled] {
  background-color: rgba(229, 57, 53, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.red-600-fg {
  color: #e53935 !important;
}

.red-600-border {
  border-color: #e53935 !important;
}

.red-600-border-top {
  border-top-color: #e53935 !important;
}

.red-600-border-right {
  border-right-color: #e53935 !important;
}

.red-600-border-bottom {
  border-bottom-color: #e53935 !important;
}

.red-600-border-left {
  border-left-color: #e53935 !important;
}

.red-700-bg {
  background-color: #d32f2f !important;
}

.red-700 {
  background-color: #d32f2f !important;
  color: white !important;
}
.red-700[disabled] {
  background-color: rgba(211, 47, 47, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.red-700-fg {
  color: #d32f2f !important;
}

.red-700-border {
  border-color: #d32f2f !important;
}

.red-700-border-top {
  border-top-color: #d32f2f !important;
}

.red-700-border-right {
  border-right-color: #d32f2f !important;
}

.red-700-border-bottom {
  border-bottom-color: #d32f2f !important;
}

.red-700-border-left {
  border-left-color: #d32f2f !important;
}

.red-800-bg {
  background-color: #c62828 !important;
}

.red-800 {
  background-color: #c62828 !important;
  color: white !important;
}
.red-800[disabled] {
  background-color: rgba(198, 40, 40, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.red-800-fg {
  color: #c62828 !important;
}

.red-800-border {
  border-color: #c62828 !important;
}

.red-800-border-top {
  border-top-color: #c62828 !important;
}

.red-800-border-right {
  border-right-color: #c62828 !important;
}

.red-800-border-bottom {
  border-bottom-color: #c62828 !important;
}

.red-800-border-left {
  border-left-color: #c62828 !important;
}

.red-900-bg {
  background-color: #b71c1c !important;
}

.red-900 {
  background-color: #b71c1c !important;
  color: white !important;
}
.red-900[disabled] {
  background-color: rgba(183, 28, 28, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.red-900-fg {
  color: #b71c1c !important;
}

.red-900-border {
  border-color: #b71c1c !important;
}

.red-900-border-top {
  border-top-color: #b71c1c !important;
}

.red-900-border-right {
  border-right-color: #b71c1c !important;
}

.red-900-border-bottom {
  border-bottom-color: #b71c1c !important;
}

.red-900-border-left {
  border-left-color: #b71c1c !important;
}

.red-A100-bg {
  background-color: #ff8a80 !important;
}

.red-A100 {
  background-color: #ff8a80 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.red-A100[disabled] {
  background-color: rgba(255, 138, 128, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.red-A100-fg {
  color: #ff8a80 !important;
}

.red-A100-border {
  border-color: #ff8a80 !important;
}

.red-A100-border-top {
  border-top-color: #ff8a80 !important;
}

.red-A100-border-right {
  border-right-color: #ff8a80 !important;
}

.red-A100-border-bottom {
  border-bottom-color: #ff8a80 !important;
}

.red-A100-border-left {
  border-left-color: #ff8a80 !important;
}

.red-A200-bg {
  background-color: #ff5252 !important;
}

.red-A200 {
  background-color: #ff5252 !important;
  color: white !important;
}
.red-A200[disabled] {
  background-color: rgba(255, 82, 82, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.red-A200-fg {
  color: #ff5252 !important;
}

.red-A200-border {
  border-color: #ff5252 !important;
}

.red-A200-border-top {
  border-top-color: #ff5252 !important;
}

.red-A200-border-right {
  border-right-color: #ff5252 !important;
}

.red-A200-border-bottom {
  border-bottom-color: #ff5252 !important;
}

.red-A200-border-left {
  border-left-color: #ff5252 !important;
}

.red-A400-bg {
  background-color: #ff1744 !important;
}

.red-A400 {
  background-color: #ff1744 !important;
  color: white !important;
}
.red-A400[disabled] {
  background-color: rgba(255, 23, 68, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.red-A400-fg {
  color: #ff1744 !important;
}

.red-A400-border {
  border-color: #ff1744 !important;
}

.red-A400-border-top {
  border-top-color: #ff1744 !important;
}

.red-A400-border-right {
  border-right-color: #ff1744 !important;
}

.red-A400-border-bottom {
  border-bottom-color: #ff1744 !important;
}

.red-A400-border-left {
  border-left-color: #ff1744 !important;
}

.red-A700-bg {
  background-color: #d50000 !important;
}

.red-A700 {
  background-color: #d50000 !important;
  color: white !important;
}
.red-A700[disabled] {
  background-color: rgba(213, 0, 0, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.red-A700-fg {
  color: #d50000 !important;
}

.red-A700-border {
  border-color: #d50000 !important;
}

.red-A700-border-top {
  border-top-color: #d50000 !important;
}

.red-A700-border-right {
  border-right-color: #d50000 !important;
}

.red-A700-border-bottom {
  border-bottom-color: #d50000 !important;
}

.red-A700-border-left {
  border-left-color: #d50000 !important;
}

.pink-50-bg {
  background-color: #fce4ec !important;
}

.pink-50 {
  background-color: #fce4ec !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-50[disabled] {
  background-color: rgba(252, 228, 236, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.pink-50-fg {
  color: #fce4ec !important;
}

.pink-50-border {
  border-color: #fce4ec !important;
}

.pink-50-border-top {
  border-top-color: #fce4ec !important;
}

.pink-50-border-right {
  border-right-color: #fce4ec !important;
}

.pink-50-border-bottom {
  border-bottom-color: #fce4ec !important;
}

.pink-50-border-left {
  border-left-color: #fce4ec !important;
}

.pink-100-bg {
  background-color: #f8bbd0 !important;
}

.pink-100 {
  background-color: #f8bbd0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-100[disabled] {
  background-color: rgba(248, 187, 208, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.pink-100-fg {
  color: #f8bbd0 !important;
}

.pink-100-border {
  border-color: #f8bbd0 !important;
}

.pink-100-border-top {
  border-top-color: #f8bbd0 !important;
}

.pink-100-border-right {
  border-right-color: #f8bbd0 !important;
}

.pink-100-border-bottom {
  border-bottom-color: #f8bbd0 !important;
}

.pink-100-border-left {
  border-left-color: #f8bbd0 !important;
}

.pink-200-bg {
  background-color: #f48fb1 !important;
}

.pink-200 {
  background-color: #f48fb1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-200[disabled] {
  background-color: rgba(244, 143, 177, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.pink-200-fg {
  color: #f48fb1 !important;
}

.pink-200-border {
  border-color: #f48fb1 !important;
}

.pink-200-border-top {
  border-top-color: #f48fb1 !important;
}

.pink-200-border-right {
  border-right-color: #f48fb1 !important;
}

.pink-200-border-bottom {
  border-bottom-color: #f48fb1 !important;
}

.pink-200-border-left {
  border-left-color: #f48fb1 !important;
}

.pink-300-bg {
  background-color: #f06292 !important;
}

.pink-300 {
  background-color: #f06292 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-300[disabled] {
  background-color: rgba(240, 98, 146, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.pink-300-fg {
  color: #f06292 !important;
}

.pink-300-border {
  border-color: #f06292 !important;
}

.pink-300-border-top {
  border-top-color: #f06292 !important;
}

.pink-300-border-right {
  border-right-color: #f06292 !important;
}

.pink-300-border-bottom {
  border-bottom-color: #f06292 !important;
}

.pink-300-border-left {
  border-left-color: #f06292 !important;
}

.pink-400-bg {
  background-color: #ec407a !important;
}

.pink-400 {
  background-color: #ec407a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-400[disabled] {
  background-color: rgba(236, 64, 122, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.pink-400-fg {
  color: #ec407a !important;
}

.pink-400-border {
  border-color: #ec407a !important;
}

.pink-400-border-top {
  border-top-color: #ec407a !important;
}

.pink-400-border-right {
  border-right-color: #ec407a !important;
}

.pink-400-border-bottom {
  border-bottom-color: #ec407a !important;
}

.pink-400-border-left {
  border-left-color: #ec407a !important;
}

.pink-500-bg {
  background-color: #e91e63 !important;
}

.pink-500 {
  background-color: #e91e63 !important;
  color: white !important;
}
.pink-500[disabled] {
  background-color: rgba(233, 30, 99, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.pink-500-fg {
  color: #e91e63 !important;
}

.pink-500-border {
  border-color: #e91e63 !important;
}

.pink-500-border-top {
  border-top-color: #e91e63 !important;
}

.pink-500-border-right {
  border-right-color: #e91e63 !important;
}

.pink-500-border-bottom {
  border-bottom-color: #e91e63 !important;
}

.pink-500-border-left {
  border-left-color: #e91e63 !important;
}

.pink-bg {
  background-color: #e91e63 !important;
}

.pink {
  background-color: #e91e63 !important;
  color: white !important;
}
.pink[disabled] {
  background-color: rgba(233, 30, 99, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.pink-fg {
  color: #e91e63 !important;
}

.pink-border {
  border-color: #e91e63 !important;
}

.pink-border-top {
  border-top-color: #e91e63 !important;
}

.pink-border-right {
  border-right-color: #e91e63 !important;
}

.pink-border-bottom {
  border-bottom-color: #e91e63 !important;
}

.pink-border-left {
  border-left-color: #e91e63 !important;
}

.pink-600-bg {
  background-color: #d81b60 !important;
}

.pink-600 {
  background-color: #d81b60 !important;
  color: white !important;
}
.pink-600[disabled] {
  background-color: rgba(216, 27, 96, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.pink-600-fg {
  color: #d81b60 !important;
}

.pink-600-border {
  border-color: #d81b60 !important;
}

.pink-600-border-top {
  border-top-color: #d81b60 !important;
}

.pink-600-border-right {
  border-right-color: #d81b60 !important;
}

.pink-600-border-bottom {
  border-bottom-color: #d81b60 !important;
}

.pink-600-border-left {
  border-left-color: #d81b60 !important;
}

.pink-700-bg {
  background-color: #c2185b !important;
}

.pink-700 {
  background-color: #c2185b !important;
  color: white !important;
}
.pink-700[disabled] {
  background-color: rgba(194, 24, 91, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.pink-700-fg {
  color: #c2185b !important;
}

.pink-700-border {
  border-color: #c2185b !important;
}

.pink-700-border-top {
  border-top-color: #c2185b !important;
}

.pink-700-border-right {
  border-right-color: #c2185b !important;
}

.pink-700-border-bottom {
  border-bottom-color: #c2185b !important;
}

.pink-700-border-left {
  border-left-color: #c2185b !important;
}

.pink-800-bg {
  background-color: #ad1457 !important;
}

.pink-800 {
  background-color: #ad1457 !important;
  color: white !important;
}
.pink-800[disabled] {
  background-color: rgba(173, 20, 87, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.pink-800-fg {
  color: #ad1457 !important;
}

.pink-800-border {
  border-color: #ad1457 !important;
}

.pink-800-border-top {
  border-top-color: #ad1457 !important;
}

.pink-800-border-right {
  border-right-color: #ad1457 !important;
}

.pink-800-border-bottom {
  border-bottom-color: #ad1457 !important;
}

.pink-800-border-left {
  border-left-color: #ad1457 !important;
}

.pink-900-bg {
  background-color: #880e4f !important;
}

.pink-900 {
  background-color: #880e4f !important;
  color: white !important;
}
.pink-900[disabled] {
  background-color: rgba(136, 14, 79, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.pink-900-fg {
  color: #880e4f !important;
}

.pink-900-border {
  border-color: #880e4f !important;
}

.pink-900-border-top {
  border-top-color: #880e4f !important;
}

.pink-900-border-right {
  border-right-color: #880e4f !important;
}

.pink-900-border-bottom {
  border-bottom-color: #880e4f !important;
}

.pink-900-border-left {
  border-left-color: #880e4f !important;
}

.pink-A100-bg {
  background-color: #ff80ab !important;
}

.pink-A100 {
  background-color: #ff80ab !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.pink-A100[disabled] {
  background-color: rgba(255, 128, 171, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.pink-A100-fg {
  color: #ff80ab !important;
}

.pink-A100-border {
  border-color: #ff80ab !important;
}

.pink-A100-border-top {
  border-top-color: #ff80ab !important;
}

.pink-A100-border-right {
  border-right-color: #ff80ab !important;
}

.pink-A100-border-bottom {
  border-bottom-color: #ff80ab !important;
}

.pink-A100-border-left {
  border-left-color: #ff80ab !important;
}

.pink-A200-bg {
  background-color: #ff4081 !important;
}

.pink-A200 {
  background-color: #ff4081 !important;
  color: white !important;
}
.pink-A200[disabled] {
  background-color: rgba(255, 64, 129, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.pink-A200-fg {
  color: #ff4081 !important;
}

.pink-A200-border {
  border-color: #ff4081 !important;
}

.pink-A200-border-top {
  border-top-color: #ff4081 !important;
}

.pink-A200-border-right {
  border-right-color: #ff4081 !important;
}

.pink-A200-border-bottom {
  border-bottom-color: #ff4081 !important;
}

.pink-A200-border-left {
  border-left-color: #ff4081 !important;
}

.pink-A400-bg {
  background-color: #f50057 !important;
}

.pink-A400 {
  background-color: #f50057 !important;
  color: white !important;
}
.pink-A400[disabled] {
  background-color: rgba(245, 0, 87, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.pink-A400-fg {
  color: #f50057 !important;
}

.pink-A400-border {
  border-color: #f50057 !important;
}

.pink-A400-border-top {
  border-top-color: #f50057 !important;
}

.pink-A400-border-right {
  border-right-color: #f50057 !important;
}

.pink-A400-border-bottom {
  border-bottom-color: #f50057 !important;
}

.pink-A400-border-left {
  border-left-color: #f50057 !important;
}

.pink-A700-bg {
  background-color: #c51162 !important;
}

.pink-A700 {
  background-color: #c51162 !important;
  color: white !important;
}
.pink-A700[disabled] {
  background-color: rgba(197, 17, 98, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.pink-A700-fg {
  color: #c51162 !important;
}

.pink-A700-border {
  border-color: #c51162 !important;
}

.pink-A700-border-top {
  border-top-color: #c51162 !important;
}

.pink-A700-border-right {
  border-right-color: #c51162 !important;
}

.pink-A700-border-bottom {
  border-bottom-color: #c51162 !important;
}

.pink-A700-border-left {
  border-left-color: #c51162 !important;
}

.purple-50-bg {
  background-color: #f3e5f5 !important;
}

.purple-50 {
  background-color: #f3e5f5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.purple-50[disabled] {
  background-color: rgba(243, 229, 245, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.purple-50-fg {
  color: #f3e5f5 !important;
}

.purple-50-border {
  border-color: #f3e5f5 !important;
}

.purple-50-border-top {
  border-top-color: #f3e5f5 !important;
}

.purple-50-border-right {
  border-right-color: #f3e5f5 !important;
}

.purple-50-border-bottom {
  border-bottom-color: #f3e5f5 !important;
}

.purple-50-border-left {
  border-left-color: #f3e5f5 !important;
}

.purple-100-bg {
  background-color: #e1bee7 !important;
}

.purple-100 {
  background-color: #e1bee7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.purple-100[disabled] {
  background-color: rgba(225, 190, 231, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.purple-100-fg {
  color: #e1bee7 !important;
}

.purple-100-border {
  border-color: #e1bee7 !important;
}

.purple-100-border-top {
  border-top-color: #e1bee7 !important;
}

.purple-100-border-right {
  border-right-color: #e1bee7 !important;
}

.purple-100-border-bottom {
  border-bottom-color: #e1bee7 !important;
}

.purple-100-border-left {
  border-left-color: #e1bee7 !important;
}

.purple-200-bg {
  background-color: #ce93d8 !important;
}

.purple-200 {
  background-color: #ce93d8 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.purple-200[disabled] {
  background-color: rgba(206, 147, 216, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.purple-200-fg {
  color: #ce93d8 !important;
}

.purple-200-border {
  border-color: #ce93d8 !important;
}

.purple-200-border-top {
  border-top-color: #ce93d8 !important;
}

.purple-200-border-right {
  border-right-color: #ce93d8 !important;
}

.purple-200-border-bottom {
  border-bottom-color: #ce93d8 !important;
}

.purple-200-border-left {
  border-left-color: #ce93d8 !important;
}

.purple-300-bg {
  background-color: #ba68c8 !important;
}

.purple-300 {
  background-color: #ba68c8 !important;
  color: white !important;
}
.purple-300[disabled] {
  background-color: rgba(186, 104, 200, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.purple-300-fg {
  color: #ba68c8 !important;
}

.purple-300-border {
  border-color: #ba68c8 !important;
}

.purple-300-border-top {
  border-top-color: #ba68c8 !important;
}

.purple-300-border-right {
  border-right-color: #ba68c8 !important;
}

.purple-300-border-bottom {
  border-bottom-color: #ba68c8 !important;
}

.purple-300-border-left {
  border-left-color: #ba68c8 !important;
}

.purple-400-bg {
  background-color: #ab47bc !important;
}

.purple-400 {
  background-color: #ab47bc !important;
  color: white !important;
}
.purple-400[disabled] {
  background-color: rgba(171, 71, 188, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.purple-400-fg {
  color: #ab47bc !important;
}

.purple-400-border {
  border-color: #ab47bc !important;
}

.purple-400-border-top {
  border-top-color: #ab47bc !important;
}

.purple-400-border-right {
  border-right-color: #ab47bc !important;
}

.purple-400-border-bottom {
  border-bottom-color: #ab47bc !important;
}

.purple-400-border-left {
  border-left-color: #ab47bc !important;
}

.purple-500-bg {
  background-color: #9c27b0 !important;
}

.purple-500 {
  background-color: #9c27b0 !important;
  color: white !important;
}
.purple-500[disabled] {
  background-color: rgba(156, 39, 176, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.purple-500-fg {
  color: #9c27b0 !important;
}

.purple-500-border {
  border-color: #9c27b0 !important;
}

.purple-500-border-top {
  border-top-color: #9c27b0 !important;
}

.purple-500-border-right {
  border-right-color: #9c27b0 !important;
}

.purple-500-border-bottom {
  border-bottom-color: #9c27b0 !important;
}

.purple-500-border-left {
  border-left-color: #9c27b0 !important;
}

.purple-bg {
  background-color: #9c27b0 !important;
}

.purple {
  background-color: #9c27b0 !important;
  color: white !important;
}
.purple[disabled] {
  background-color: rgba(156, 39, 176, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.purple-fg {
  color: #9c27b0 !important;
}

.purple-border {
  border-color: #9c27b0 !important;
}

.purple-border-top {
  border-top-color: #9c27b0 !important;
}

.purple-border-right {
  border-right-color: #9c27b0 !important;
}

.purple-border-bottom {
  border-bottom-color: #9c27b0 !important;
}

.purple-border-left {
  border-left-color: #9c27b0 !important;
}

.purple-600-bg {
  background-color: #8e24aa !important;
}

.purple-600 {
  background-color: #8e24aa !important;
  color: white !important;
}
.purple-600[disabled] {
  background-color: rgba(142, 36, 170, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.purple-600-fg {
  color: #8e24aa !important;
}

.purple-600-border {
  border-color: #8e24aa !important;
}

.purple-600-border-top {
  border-top-color: #8e24aa !important;
}

.purple-600-border-right {
  border-right-color: #8e24aa !important;
}

.purple-600-border-bottom {
  border-bottom-color: #8e24aa !important;
}

.purple-600-border-left {
  border-left-color: #8e24aa !important;
}

.purple-700-bg {
  background-color: #7b1fa2 !important;
}

.purple-700 {
  background-color: #7b1fa2 !important;
  color: white !important;
}
.purple-700[disabled] {
  background-color: rgba(123, 31, 162, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.purple-700-fg {
  color: #7b1fa2 !important;
}

.purple-700-border {
  border-color: #7b1fa2 !important;
}

.purple-700-border-top {
  border-top-color: #7b1fa2 !important;
}

.purple-700-border-right {
  border-right-color: #7b1fa2 !important;
}

.purple-700-border-bottom {
  border-bottom-color: #7b1fa2 !important;
}

.purple-700-border-left {
  border-left-color: #7b1fa2 !important;
}

.purple-800-bg {
  background-color: #6a1b9a !important;
}

.purple-800 {
  background-color: #6a1b9a !important;
  color: white !important;
}
.purple-800[disabled] {
  background-color: rgba(106, 27, 154, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.purple-800-fg {
  color: #6a1b9a !important;
}

.purple-800-border {
  border-color: #6a1b9a !important;
}

.purple-800-border-top {
  border-top-color: #6a1b9a !important;
}

.purple-800-border-right {
  border-right-color: #6a1b9a !important;
}

.purple-800-border-bottom {
  border-bottom-color: #6a1b9a !important;
}

.purple-800-border-left {
  border-left-color: #6a1b9a !important;
}

.purple-900-bg {
  background-color: #4a148c !important;
}

.purple-900 {
  background-color: #4a148c !important;
  color: white !important;
}
.purple-900[disabled] {
  background-color: rgba(74, 20, 140, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.purple-900-fg {
  color: #4a148c !important;
}

.purple-900-border {
  border-color: #4a148c !important;
}

.purple-900-border-top {
  border-top-color: #4a148c !important;
}

.purple-900-border-right {
  border-right-color: #4a148c !important;
}

.purple-900-border-bottom {
  border-bottom-color: #4a148c !important;
}

.purple-900-border-left {
  border-left-color: #4a148c !important;
}

.purple-A100-bg {
  background-color: #ea80fc !important;
}

.purple-A100 {
  background-color: #ea80fc !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.purple-A100[disabled] {
  background-color: rgba(234, 128, 252, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.purple-A100-fg {
  color: #ea80fc !important;
}

.purple-A100-border {
  border-color: #ea80fc !important;
}

.purple-A100-border-top {
  border-top-color: #ea80fc !important;
}

.purple-A100-border-right {
  border-right-color: #ea80fc !important;
}

.purple-A100-border-bottom {
  border-bottom-color: #ea80fc !important;
}

.purple-A100-border-left {
  border-left-color: #ea80fc !important;
}

.purple-A200-bg {
  background-color: #e040fb !important;
}

.purple-A200 {
  background-color: #e040fb !important;
  color: white !important;
}
.purple-A200[disabled] {
  background-color: rgba(224, 64, 251, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.purple-A200-fg {
  color: #e040fb !important;
}

.purple-A200-border {
  border-color: #e040fb !important;
}

.purple-A200-border-top {
  border-top-color: #e040fb !important;
}

.purple-A200-border-right {
  border-right-color: #e040fb !important;
}

.purple-A200-border-bottom {
  border-bottom-color: #e040fb !important;
}

.purple-A200-border-left {
  border-left-color: #e040fb !important;
}

.purple-A400-bg {
  background-color: #d500f9 !important;
}

.purple-A400 {
  background-color: #d500f9 !important;
  color: white !important;
}
.purple-A400[disabled] {
  background-color: rgba(213, 0, 249, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.purple-A400-fg {
  color: #d500f9 !important;
}

.purple-A400-border {
  border-color: #d500f9 !important;
}

.purple-A400-border-top {
  border-top-color: #d500f9 !important;
}

.purple-A400-border-right {
  border-right-color: #d500f9 !important;
}

.purple-A400-border-bottom {
  border-bottom-color: #d500f9 !important;
}

.purple-A400-border-left {
  border-left-color: #d500f9 !important;
}

.purple-A700-bg {
  background-color: #aa00ff !important;
}

.purple-A700 {
  background-color: #aa00ff !important;
  color: white !important;
}
.purple-A700[disabled] {
  background-color: rgba(170, 0, 255, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.purple-A700-fg {
  color: #aa00ff !important;
}

.purple-A700-border {
  border-color: #aa00ff !important;
}

.purple-A700-border-top {
  border-top-color: #aa00ff !important;
}

.purple-A700-border-right {
  border-right-color: #aa00ff !important;
}

.purple-A700-border-bottom {
  border-bottom-color: #aa00ff !important;
}

.purple-A700-border-left {
  border-left-color: #aa00ff !important;
}

.deep-purple-50-bg {
  background-color: #ede7f6 !important;
}

.deep-purple-50 {
  background-color: #ede7f6 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.deep-purple-50[disabled] {
  background-color: rgba(237, 231, 246, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.deep-purple-50-fg {
  color: #ede7f6 !important;
}

.deep-purple-50-border {
  border-color: #ede7f6 !important;
}

.deep-purple-50-border-top {
  border-top-color: #ede7f6 !important;
}

.deep-purple-50-border-right {
  border-right-color: #ede7f6 !important;
}

.deep-purple-50-border-bottom {
  border-bottom-color: #ede7f6 !important;
}

.deep-purple-50-border-left {
  border-left-color: #ede7f6 !important;
}

.deep-purple-100-bg {
  background-color: #d1c4e9 !important;
}

.deep-purple-100 {
  background-color: #d1c4e9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.deep-purple-100[disabled] {
  background-color: rgba(209, 196, 233, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.deep-purple-100-fg {
  color: #d1c4e9 !important;
}

.deep-purple-100-border {
  border-color: #d1c4e9 !important;
}

.deep-purple-100-border-top {
  border-top-color: #d1c4e9 !important;
}

.deep-purple-100-border-right {
  border-right-color: #d1c4e9 !important;
}

.deep-purple-100-border-bottom {
  border-bottom-color: #d1c4e9 !important;
}

.deep-purple-100-border-left {
  border-left-color: #d1c4e9 !important;
}

.deep-purple-200-bg {
  background-color: #b39ddb !important;
}

.deep-purple-200 {
  background-color: #b39ddb !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.deep-purple-200[disabled] {
  background-color: rgba(179, 157, 219, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.deep-purple-200-fg {
  color: #b39ddb !important;
}

.deep-purple-200-border {
  border-color: #b39ddb !important;
}

.deep-purple-200-border-top {
  border-top-color: #b39ddb !important;
}

.deep-purple-200-border-right {
  border-right-color: #b39ddb !important;
}

.deep-purple-200-border-bottom {
  border-bottom-color: #b39ddb !important;
}

.deep-purple-200-border-left {
  border-left-color: #b39ddb !important;
}

.deep-purple-300-bg {
  background-color: #9575cd !important;
}

.deep-purple-300 {
  background-color: #9575cd !important;
  color: white !important;
}
.deep-purple-300[disabled] {
  background-color: rgba(149, 117, 205, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.deep-purple-300-fg {
  color: #9575cd !important;
}

.deep-purple-300-border {
  border-color: #9575cd !important;
}

.deep-purple-300-border-top {
  border-top-color: #9575cd !important;
}

.deep-purple-300-border-right {
  border-right-color: #9575cd !important;
}

.deep-purple-300-border-bottom {
  border-bottom-color: #9575cd !important;
}

.deep-purple-300-border-left {
  border-left-color: #9575cd !important;
}

.deep-purple-400-bg {
  background-color: #7e57c2 !important;
}

.deep-purple-400 {
  background-color: #7e57c2 !important;
  color: white !important;
}
.deep-purple-400[disabled] {
  background-color: rgba(126, 87, 194, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.deep-purple-400-fg {
  color: #7e57c2 !important;
}

.deep-purple-400-border {
  border-color: #7e57c2 !important;
}

.deep-purple-400-border-top {
  border-top-color: #7e57c2 !important;
}

.deep-purple-400-border-right {
  border-right-color: #7e57c2 !important;
}

.deep-purple-400-border-bottom {
  border-bottom-color: #7e57c2 !important;
}

.deep-purple-400-border-left {
  border-left-color: #7e57c2 !important;
}

.deep-purple-500-bg {
  background-color: #673ab7 !important;
}

.deep-purple-500 {
  background-color: #673ab7 !important;
  color: white !important;
}
.deep-purple-500[disabled] {
  background-color: rgba(103, 58, 183, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.deep-purple-500-fg {
  color: #673ab7 !important;
}

.deep-purple-500-border {
  border-color: #673ab7 !important;
}

.deep-purple-500-border-top {
  border-top-color: #673ab7 !important;
}

.deep-purple-500-border-right {
  border-right-color: #673ab7 !important;
}

.deep-purple-500-border-bottom {
  border-bottom-color: #673ab7 !important;
}

.deep-purple-500-border-left {
  border-left-color: #673ab7 !important;
}

.deep-purple-bg {
  background-color: #673ab7 !important;
}

.deep-purple {
  background-color: #673ab7 !important;
  color: white !important;
}
.deep-purple[disabled] {
  background-color: rgba(103, 58, 183, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.deep-purple-fg {
  color: #673ab7 !important;
}

.deep-purple-border {
  border-color: #673ab7 !important;
}

.deep-purple-border-top {
  border-top-color: #673ab7 !important;
}

.deep-purple-border-right {
  border-right-color: #673ab7 !important;
}

.deep-purple-border-bottom {
  border-bottom-color: #673ab7 !important;
}

.deep-purple-border-left {
  border-left-color: #673ab7 !important;
}

.deep-purple-600-bg {
  background-color: #5e35b1 !important;
}

.deep-purple-600 {
  background-color: #5e35b1 !important;
  color: white !important;
}
.deep-purple-600[disabled] {
  background-color: rgba(94, 53, 177, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.deep-purple-600-fg {
  color: #5e35b1 !important;
}

.deep-purple-600-border {
  border-color: #5e35b1 !important;
}

.deep-purple-600-border-top {
  border-top-color: #5e35b1 !important;
}

.deep-purple-600-border-right {
  border-right-color: #5e35b1 !important;
}

.deep-purple-600-border-bottom {
  border-bottom-color: #5e35b1 !important;
}

.deep-purple-600-border-left {
  border-left-color: #5e35b1 !important;
}

.deep-purple-700-bg {
  background-color: #512da8 !important;
}

.deep-purple-700 {
  background-color: #512da8 !important;
  color: white !important;
}
.deep-purple-700[disabled] {
  background-color: rgba(81, 45, 168, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.deep-purple-700-fg {
  color: #512da8 !important;
}

.deep-purple-700-border {
  border-color: #512da8 !important;
}

.deep-purple-700-border-top {
  border-top-color: #512da8 !important;
}

.deep-purple-700-border-right {
  border-right-color: #512da8 !important;
}

.deep-purple-700-border-bottom {
  border-bottom-color: #512da8 !important;
}

.deep-purple-700-border-left {
  border-left-color: #512da8 !important;
}

.deep-purple-800-bg {
  background-color: #4527a0 !important;
}

.deep-purple-800 {
  background-color: #4527a0 !important;
  color: white !important;
}
.deep-purple-800[disabled] {
  background-color: rgba(69, 39, 160, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.deep-purple-800-fg {
  color: #4527a0 !important;
}

.deep-purple-800-border {
  border-color: #4527a0 !important;
}

.deep-purple-800-border-top {
  border-top-color: #4527a0 !important;
}

.deep-purple-800-border-right {
  border-right-color: #4527a0 !important;
}

.deep-purple-800-border-bottom {
  border-bottom-color: #4527a0 !important;
}

.deep-purple-800-border-left {
  border-left-color: #4527a0 !important;
}

.deep-purple-900-bg {
  background-color: #311b92 !important;
}

.deep-purple-900 {
  background-color: #311b92 !important;
  color: white !important;
}
.deep-purple-900[disabled] {
  background-color: rgba(49, 27, 146, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.deep-purple-900-fg {
  color: #311b92 !important;
}

.deep-purple-900-border {
  border-color: #311b92 !important;
}

.deep-purple-900-border-top {
  border-top-color: #311b92 !important;
}

.deep-purple-900-border-right {
  border-right-color: #311b92 !important;
}

.deep-purple-900-border-bottom {
  border-bottom-color: #311b92 !important;
}

.deep-purple-900-border-left {
  border-left-color: #311b92 !important;
}

.deep-purple-A100-bg {
  background-color: #b388ff !important;
}

.deep-purple-A100 {
  background-color: #b388ff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.deep-purple-A100[disabled] {
  background-color: rgba(179, 136, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.deep-purple-A100-fg {
  color: #b388ff !important;
}

.deep-purple-A100-border {
  border-color: #b388ff !important;
}

.deep-purple-A100-border-top {
  border-top-color: #b388ff !important;
}

.deep-purple-A100-border-right {
  border-right-color: #b388ff !important;
}

.deep-purple-A100-border-bottom {
  border-bottom-color: #b388ff !important;
}

.deep-purple-A100-border-left {
  border-left-color: #b388ff !important;
}

.deep-purple-A200-bg {
  background-color: #7c4dff !important;
}

.deep-purple-A200 {
  background-color: #7c4dff !important;
  color: white !important;
}
.deep-purple-A200[disabled] {
  background-color: rgba(124, 77, 255, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.deep-purple-A200-fg {
  color: #7c4dff !important;
}

.deep-purple-A200-border {
  border-color: #7c4dff !important;
}

.deep-purple-A200-border-top {
  border-top-color: #7c4dff !important;
}

.deep-purple-A200-border-right {
  border-right-color: #7c4dff !important;
}

.deep-purple-A200-border-bottom {
  border-bottom-color: #7c4dff !important;
}

.deep-purple-A200-border-left {
  border-left-color: #7c4dff !important;
}

.deep-purple-A400-bg {
  background-color: #651fff !important;
}

.deep-purple-A400 {
  background-color: #651fff !important;
  color: white !important;
}
.deep-purple-A400[disabled] {
  background-color: rgba(101, 31, 255, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.deep-purple-A400-fg {
  color: #651fff !important;
}

.deep-purple-A400-border {
  border-color: #651fff !important;
}

.deep-purple-A400-border-top {
  border-top-color: #651fff !important;
}

.deep-purple-A400-border-right {
  border-right-color: #651fff !important;
}

.deep-purple-A400-border-bottom {
  border-bottom-color: #651fff !important;
}

.deep-purple-A400-border-left {
  border-left-color: #651fff !important;
}

.deep-purple-A700-bg {
  background-color: #6200ea !important;
}

.deep-purple-A700 {
  background-color: #6200ea !important;
  color: white !important;
}
.deep-purple-A700[disabled] {
  background-color: rgba(98, 0, 234, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.deep-purple-A700-fg {
  color: #6200ea !important;
}

.deep-purple-A700-border {
  border-color: #6200ea !important;
}

.deep-purple-A700-border-top {
  border-top-color: #6200ea !important;
}

.deep-purple-A700-border-right {
  border-right-color: #6200ea !important;
}

.deep-purple-A700-border-bottom {
  border-bottom-color: #6200ea !important;
}

.deep-purple-A700-border-left {
  border-left-color: #6200ea !important;
}

.indigo-50-bg {
  background-color: #e8eaf6 !important;
}

.indigo-50 {
  background-color: #e8eaf6 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-50[disabled] {
  background-color: rgba(232, 234, 246, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.indigo-50-fg {
  color: #e8eaf6 !important;
}

.indigo-50-border {
  border-color: #e8eaf6 !important;
}

.indigo-50-border-top {
  border-top-color: #e8eaf6 !important;
}

.indigo-50-border-right {
  border-right-color: #e8eaf6 !important;
}

.indigo-50-border-bottom {
  border-bottom-color: #e8eaf6 !important;
}

.indigo-50-border-left {
  border-left-color: #e8eaf6 !important;
}

.indigo-100-bg {
  background-color: #c5cae9 !important;
}

.indigo-100 {
  background-color: #c5cae9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-100[disabled] {
  background-color: rgba(197, 202, 233, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.indigo-100-fg {
  color: #c5cae9 !important;
}

.indigo-100-border {
  border-color: #c5cae9 !important;
}

.indigo-100-border-top {
  border-top-color: #c5cae9 !important;
}

.indigo-100-border-right {
  border-right-color: #c5cae9 !important;
}

.indigo-100-border-bottom {
  border-bottom-color: #c5cae9 !important;
}

.indigo-100-border-left {
  border-left-color: #c5cae9 !important;
}

.indigo-200-bg {
  background-color: #9fa8da !important;
}

.indigo-200 {
  background-color: #9fa8da !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-200[disabled] {
  background-color: rgba(159, 168, 218, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.indigo-200-fg {
  color: #9fa8da !important;
}

.indigo-200-border {
  border-color: #9fa8da !important;
}

.indigo-200-border-top {
  border-top-color: #9fa8da !important;
}

.indigo-200-border-right {
  border-right-color: #9fa8da !important;
}

.indigo-200-border-bottom {
  border-bottom-color: #9fa8da !important;
}

.indigo-200-border-left {
  border-left-color: #9fa8da !important;
}

.indigo-300-bg {
  background-color: #7986cb !important;
}

.indigo-300 {
  background-color: #7986cb !important;
  color: white !important;
}
.indigo-300[disabled] {
  background-color: rgba(121, 134, 203, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.indigo-300-fg {
  color: #7986cb !important;
}

.indigo-300-border {
  border-color: #7986cb !important;
}

.indigo-300-border-top {
  border-top-color: #7986cb !important;
}

.indigo-300-border-right {
  border-right-color: #7986cb !important;
}

.indigo-300-border-bottom {
  border-bottom-color: #7986cb !important;
}

.indigo-300-border-left {
  border-left-color: #7986cb !important;
}

.indigo-400-bg {
  background-color: #5c6bc0 !important;
}

.indigo-400 {
  background-color: #5c6bc0 !important;
  color: white !important;
}
.indigo-400[disabled] {
  background-color: rgba(92, 107, 192, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.indigo-400-fg {
  color: #5c6bc0 !important;
}

.indigo-400-border {
  border-color: #5c6bc0 !important;
}

.indigo-400-border-top {
  border-top-color: #5c6bc0 !important;
}

.indigo-400-border-right {
  border-right-color: #5c6bc0 !important;
}

.indigo-400-border-bottom {
  border-bottom-color: #5c6bc0 !important;
}

.indigo-400-border-left {
  border-left-color: #5c6bc0 !important;
}

.indigo-500-bg {
  background-color: #3f51b5 !important;
}

.indigo-500 {
  background-color: #3f51b5 !important;
  color: white !important;
}
.indigo-500[disabled] {
  background-color: rgba(63, 81, 181, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.indigo-500-fg {
  color: #3f51b5 !important;
}

.indigo-500-border {
  border-color: #3f51b5 !important;
}

.indigo-500-border-top {
  border-top-color: #3f51b5 !important;
}

.indigo-500-border-right {
  border-right-color: #3f51b5 !important;
}

.indigo-500-border-bottom {
  border-bottom-color: #3f51b5 !important;
}

.indigo-500-border-left {
  border-left-color: #3f51b5 !important;
}

.indigo-bg {
  background-color: #3f51b5 !important;
}

.indigo {
  background-color: #3f51b5 !important;
  color: white !important;
}
.indigo[disabled] {
  background-color: rgba(63, 81, 181, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.indigo-fg {
  color: #3f51b5 !important;
}

.indigo-border {
  border-color: #3f51b5 !important;
}

.indigo-border-top {
  border-top-color: #3f51b5 !important;
}

.indigo-border-right {
  border-right-color: #3f51b5 !important;
}

.indigo-border-bottom {
  border-bottom-color: #3f51b5 !important;
}

.indigo-border-left {
  border-left-color: #3f51b5 !important;
}

.indigo-600-bg {
  background-color: #3949ab !important;
}

.indigo-600 {
  background-color: #3949ab !important;
  color: white !important;
}
.indigo-600[disabled] {
  background-color: rgba(57, 73, 171, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.indigo-600-fg {
  color: #3949ab !important;
}

.indigo-600-border {
  border-color: #3949ab !important;
}

.indigo-600-border-top {
  border-top-color: #3949ab !important;
}

.indigo-600-border-right {
  border-right-color: #3949ab !important;
}

.indigo-600-border-bottom {
  border-bottom-color: #3949ab !important;
}

.indigo-600-border-left {
  border-left-color: #3949ab !important;
}

.indigo-700-bg {
  background-color: #303f9f !important;
}

.indigo-700 {
  background-color: #303f9f !important;
  color: white !important;
}
.indigo-700[disabled] {
  background-color: rgba(48, 63, 159, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.indigo-700-fg {
  color: #303f9f !important;
}

.indigo-700-border {
  border-color: #303f9f !important;
}

.indigo-700-border-top {
  border-top-color: #303f9f !important;
}

.indigo-700-border-right {
  border-right-color: #303f9f !important;
}

.indigo-700-border-bottom {
  border-bottom-color: #303f9f !important;
}

.indigo-700-border-left {
  border-left-color: #303f9f !important;
}

.indigo-800-bg {
  background-color: #283593 !important;
}

.indigo-800 {
  background-color: #283593 !important;
  color: white !important;
}
.indigo-800[disabled] {
  background-color: rgba(40, 53, 147, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.indigo-800-fg {
  color: #283593 !important;
}

.indigo-800-border {
  border-color: #283593 !important;
}

.indigo-800-border-top {
  border-top-color: #283593 !important;
}

.indigo-800-border-right {
  border-right-color: #283593 !important;
}

.indigo-800-border-bottom {
  border-bottom-color: #283593 !important;
}

.indigo-800-border-left {
  border-left-color: #283593 !important;
}

.indigo-900-bg {
  background-color: #1a237e !important;
}

.indigo-900 {
  background-color: #1a237e !important;
  color: white !important;
}
.indigo-900[disabled] {
  background-color: rgba(26, 35, 126, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.indigo-900-fg {
  color: #1a237e !important;
}

.indigo-900-border {
  border-color: #1a237e !important;
}

.indigo-900-border-top {
  border-top-color: #1a237e !important;
}

.indigo-900-border-right {
  border-right-color: #1a237e !important;
}

.indigo-900-border-bottom {
  border-bottom-color: #1a237e !important;
}

.indigo-900-border-left {
  border-left-color: #1a237e !important;
}

.indigo-A100-bg {
  background-color: #8c9eff !important;
}

.indigo-A100 {
  background-color: #8c9eff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.indigo-A100[disabled] {
  background-color: rgba(140, 158, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.indigo-A100-fg {
  color: #8c9eff !important;
}

.indigo-A100-border {
  border-color: #8c9eff !important;
}

.indigo-A100-border-top {
  border-top-color: #8c9eff !important;
}

.indigo-A100-border-right {
  border-right-color: #8c9eff !important;
}

.indigo-A100-border-bottom {
  border-bottom-color: #8c9eff !important;
}

.indigo-A100-border-left {
  border-left-color: #8c9eff !important;
}

.indigo-A200-bg {
  background-color: #536dfe !important;
}

.indigo-A200 {
  background-color: #536dfe !important;
  color: white !important;
}
.indigo-A200[disabled] {
  background-color: rgba(83, 109, 254, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.indigo-A200-fg {
  color: #536dfe !important;
}

.indigo-A200-border {
  border-color: #536dfe !important;
}

.indigo-A200-border-top {
  border-top-color: #536dfe !important;
}

.indigo-A200-border-right {
  border-right-color: #536dfe !important;
}

.indigo-A200-border-bottom {
  border-bottom-color: #536dfe !important;
}

.indigo-A200-border-left {
  border-left-color: #536dfe !important;
}

.indigo-A400-bg {
  background-color: #3d5afe !important;
}

.indigo-A400 {
  background-color: #3d5afe !important;
  color: white !important;
}
.indigo-A400[disabled] {
  background-color: rgba(61, 90, 254, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.indigo-A400-fg {
  color: #3d5afe !important;
}

.indigo-A400-border {
  border-color: #3d5afe !important;
}

.indigo-A400-border-top {
  border-top-color: #3d5afe !important;
}

.indigo-A400-border-right {
  border-right-color: #3d5afe !important;
}

.indigo-A400-border-bottom {
  border-bottom-color: #3d5afe !important;
}

.indigo-A400-border-left {
  border-left-color: #3d5afe !important;
}

.indigo-A700-bg {
  background-color: #304ffe !important;
}

.indigo-A700 {
  background-color: #304ffe !important;
  color: white !important;
}
.indigo-A700[disabled] {
  background-color: rgba(48, 79, 254, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.indigo-A700-fg {
  color: #304ffe !important;
}

.indigo-A700-border {
  border-color: #304ffe !important;
}

.indigo-A700-border-top {
  border-top-color: #304ffe !important;
}

.indigo-A700-border-right {
  border-right-color: #304ffe !important;
}

.indigo-A700-border-bottom {
  border-bottom-color: #304ffe !important;
}

.indigo-A700-border-left {
  border-left-color: #304ffe !important;
}

.blue-50-bg {
  background-color: #e3f2fd !important;
}

.blue-50 {
  background-color: #e3f2fd !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.blue-50[disabled] {
  background-color: rgba(227, 242, 253, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.blue-50-fg {
  color: #e3f2fd !important;
}

.blue-50-border {
  border-color: #e3f2fd !important;
}

.blue-50-border-top {
  border-top-color: #e3f2fd !important;
}

.blue-50-border-right {
  border-right-color: #e3f2fd !important;
}

.blue-50-border-bottom {
  border-bottom-color: #e3f2fd !important;
}

.blue-50-border-left {
  border-left-color: #e3f2fd !important;
}

.blue-100-bg {
  background-color: #bbdefb !important;
}

.blue-100 {
  background-color: #bbdefb !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.blue-100[disabled] {
  background-color: rgba(187, 222, 251, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.blue-100-fg {
  color: #bbdefb !important;
}

.blue-100-border {
  border-color: #bbdefb !important;
}

.blue-100-border-top {
  border-top-color: #bbdefb !important;
}

.blue-100-border-right {
  border-right-color: #bbdefb !important;
}

.blue-100-border-bottom {
  border-bottom-color: #bbdefb !important;
}

.blue-100-border-left {
  border-left-color: #bbdefb !important;
}

.blue-200-bg {
  background-color: #90caf9 !important;
}

.blue-200 {
  background-color: #90caf9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.blue-200[disabled] {
  background-color: rgba(144, 202, 249, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.blue-200-fg {
  color: #90caf9 !important;
}

.blue-200-border {
  border-color: #90caf9 !important;
}

.blue-200-border-top {
  border-top-color: #90caf9 !important;
}

.blue-200-border-right {
  border-right-color: #90caf9 !important;
}

.blue-200-border-bottom {
  border-bottom-color: #90caf9 !important;
}

.blue-200-border-left {
  border-left-color: #90caf9 !important;
}

.blue-300-bg {
  background-color: #64b5f6 !important;
}

.blue-300 {
  background-color: #64b5f6 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.blue-300[disabled] {
  background-color: rgba(100, 181, 246, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.blue-300-fg {
  color: #64b5f6 !important;
}

.blue-300-border {
  border-color: #64b5f6 !important;
}

.blue-300-border-top {
  border-top-color: #64b5f6 !important;
}

.blue-300-border-right {
  border-right-color: #64b5f6 !important;
}

.blue-300-border-bottom {
  border-bottom-color: #64b5f6 !important;
}

.blue-300-border-left {
  border-left-color: #64b5f6 !important;
}

.blue-400-bg {
  background-color: #42a5f5 !important;
}

.blue-400 {
  background-color: #42a5f5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.blue-400[disabled] {
  background-color: rgba(66, 165, 245, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.blue-400-fg {
  color: #42a5f5 !important;
}

.blue-400-border {
  border-color: #42a5f5 !important;
}

.blue-400-border-top {
  border-top-color: #42a5f5 !important;
}

.blue-400-border-right {
  border-right-color: #42a5f5 !important;
}

.blue-400-border-bottom {
  border-bottom-color: #42a5f5 !important;
}

.blue-400-border-left {
  border-left-color: #42a5f5 !important;
}

.blue-500-bg {
  background-color: #2196f3 !important;
}

.blue-500 {
  background-color: #2196f3 !important;
  color: white !important;
}
.blue-500[disabled] {
  background-color: rgba(33, 150, 243, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.blue-500-fg {
  color: #2196f3 !important;
}

.blue-500-border {
  border-color: #2196f3 !important;
}

.blue-500-border-top {
  border-top-color: #2196f3 !important;
}

.blue-500-border-right {
  border-right-color: #2196f3 !important;
}

.blue-500-border-bottom {
  border-bottom-color: #2196f3 !important;
}

.blue-500-border-left {
  border-left-color: #2196f3 !important;
}

.blue-bg {
  background-color: #2196f3 !important;
}

.blue {
  background-color: #2196f3 !important;
  color: white !important;
}
.blue[disabled] {
  background-color: rgba(33, 150, 243, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.blue-fg {
  color: #2196f3 !important;
}

.blue-border {
  border-color: #2196f3 !important;
}

.blue-border-top {
  border-top-color: #2196f3 !important;
}

.blue-border-right {
  border-right-color: #2196f3 !important;
}

.blue-border-bottom {
  border-bottom-color: #2196f3 !important;
}

.blue-border-left {
  border-left-color: #2196f3 !important;
}

.blue-600-bg {
  background-color: #1e88e5 !important;
}

.blue-600 {
  background-color: #1e88e5 !important;
  color: white !important;
}
.blue-600[disabled] {
  background-color: rgba(30, 136, 229, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.blue-600-fg {
  color: #1e88e5 !important;
}

.blue-600-border {
  border-color: #1e88e5 !important;
}

.blue-600-border-top {
  border-top-color: #1e88e5 !important;
}

.blue-600-border-right {
  border-right-color: #1e88e5 !important;
}

.blue-600-border-bottom {
  border-bottom-color: #1e88e5 !important;
}

.blue-600-border-left {
  border-left-color: #1e88e5 !important;
}

.blue-700-bg {
  background-color: #1976d2 !important;
}

.blue-700 {
  background-color: #1976d2 !important;
  color: white !important;
}
.blue-700[disabled] {
  background-color: rgba(25, 118, 210, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.blue-700-fg {
  color: #1976d2 !important;
}

.blue-700-border {
  border-color: #1976d2 !important;
}

.blue-700-border-top {
  border-top-color: #1976d2 !important;
}

.blue-700-border-right {
  border-right-color: #1976d2 !important;
}

.blue-700-border-bottom {
  border-bottom-color: #1976d2 !important;
}

.blue-700-border-left {
  border-left-color: #1976d2 !important;
}

.blue-800-bg {
  background-color: #1565c0 !important;
}

.blue-800 {
  background-color: #1565c0 !important;
  color: white !important;
}
.blue-800[disabled] {
  background-color: rgba(21, 101, 192, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.blue-800-fg {
  color: #1565c0 !important;
}

.blue-800-border {
  border-color: #1565c0 !important;
}

.blue-800-border-top {
  border-top-color: #1565c0 !important;
}

.blue-800-border-right {
  border-right-color: #1565c0 !important;
}

.blue-800-border-bottom {
  border-bottom-color: #1565c0 !important;
}

.blue-800-border-left {
  border-left-color: #1565c0 !important;
}

.blue-900-bg {
  background-color: #0d47a1 !important;
}

.blue-900 {
  background-color: #0d47a1 !important;
  color: white !important;
}
.blue-900[disabled] {
  background-color: rgba(13, 71, 161, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.blue-900-fg {
  color: #0d47a1 !important;
}

.blue-900-border {
  border-color: #0d47a1 !important;
}

.blue-900-border-top {
  border-top-color: #0d47a1 !important;
}

.blue-900-border-right {
  border-right-color: #0d47a1 !important;
}

.blue-900-border-bottom {
  border-bottom-color: #0d47a1 !important;
}

.blue-900-border-left {
  border-left-color: #0d47a1 !important;
}

.blue-A100-bg {
  background-color: #82b1ff !important;
}

.blue-A100 {
  background-color: #82b1ff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.blue-A100[disabled] {
  background-color: rgba(130, 177, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.blue-A100-fg {
  color: #82b1ff !important;
}

.blue-A100-border {
  border-color: #82b1ff !important;
}

.blue-A100-border-top {
  border-top-color: #82b1ff !important;
}

.blue-A100-border-right {
  border-right-color: #82b1ff !important;
}

.blue-A100-border-bottom {
  border-bottom-color: #82b1ff !important;
}

.blue-A100-border-left {
  border-left-color: #82b1ff !important;
}

.blue-A200-bg {
  background-color: #448aff !important;
}

.blue-A200 {
  background-color: #448aff !important;
  color: white !important;
}
.blue-A200[disabled] {
  background-color: rgba(68, 138, 255, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.blue-A200-fg {
  color: #448aff !important;
}

.blue-A200-border {
  border-color: #448aff !important;
}

.blue-A200-border-top {
  border-top-color: #448aff !important;
}

.blue-A200-border-right {
  border-right-color: #448aff !important;
}

.blue-A200-border-bottom {
  border-bottom-color: #448aff !important;
}

.blue-A200-border-left {
  border-left-color: #448aff !important;
}

.blue-A400-bg {
  background-color: #2979ff !important;
}

.blue-A400 {
  background-color: #2979ff !important;
  color: white !important;
}
.blue-A400[disabled] {
  background-color: rgba(41, 121, 255, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.blue-A400-fg {
  color: #2979ff !important;
}

.blue-A400-border {
  border-color: #2979ff !important;
}

.blue-A400-border-top {
  border-top-color: #2979ff !important;
}

.blue-A400-border-right {
  border-right-color: #2979ff !important;
}

.blue-A400-border-bottom {
  border-bottom-color: #2979ff !important;
}

.blue-A400-border-left {
  border-left-color: #2979ff !important;
}

.blue-A700-bg {
  background-color: #2962ff !important;
}

.blue-A700 {
  background-color: #2962ff !important;
  color: white !important;
}
.blue-A700[disabled] {
  background-color: rgba(41, 98, 255, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.blue-A700-fg {
  color: #2962ff !important;
}

.blue-A700-border {
  border-color: #2962ff !important;
}

.blue-A700-border-top {
  border-top-color: #2962ff !important;
}

.blue-A700-border-right {
  border-right-color: #2962ff !important;
}

.blue-A700-border-bottom {
  border-bottom-color: #2962ff !important;
}

.blue-A700-border-left {
  border-left-color: #2962ff !important;
}

.light-blue-50-bg {
  background-color: #e1f5fe !important;
}

.light-blue-50 {
  background-color: #e1f5fe !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-blue-50[disabled] {
  background-color: rgba(225, 245, 254, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.light-blue-50-fg {
  color: #e1f5fe !important;
}

.light-blue-50-border {
  border-color: #e1f5fe !important;
}

.light-blue-50-border-top {
  border-top-color: #e1f5fe !important;
}

.light-blue-50-border-right {
  border-right-color: #e1f5fe !important;
}

.light-blue-50-border-bottom {
  border-bottom-color: #e1f5fe !important;
}

.light-blue-50-border-left {
  border-left-color: #e1f5fe !important;
}

.light-blue-100-bg {
  background-color: #b3e5fc !important;
}

.light-blue-100 {
  background-color: #b3e5fc !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-blue-100[disabled] {
  background-color: rgba(179, 229, 252, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.light-blue-100-fg {
  color: #b3e5fc !important;
}

.light-blue-100-border {
  border-color: #b3e5fc !important;
}

.light-blue-100-border-top {
  border-top-color: #b3e5fc !important;
}

.light-blue-100-border-right {
  border-right-color: #b3e5fc !important;
}

.light-blue-100-border-bottom {
  border-bottom-color: #b3e5fc !important;
}

.light-blue-100-border-left {
  border-left-color: #b3e5fc !important;
}

.light-blue-200-bg {
  background-color: #81d4fa !important;
}

.light-blue-200 {
  background-color: #81d4fa !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-blue-200[disabled] {
  background-color: rgba(129, 212, 250, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.light-blue-200-fg {
  color: #81d4fa !important;
}

.light-blue-200-border {
  border-color: #81d4fa !important;
}

.light-blue-200-border-top {
  border-top-color: #81d4fa !important;
}

.light-blue-200-border-right {
  border-right-color: #81d4fa !important;
}

.light-blue-200-border-bottom {
  border-bottom-color: #81d4fa !important;
}

.light-blue-200-border-left {
  border-left-color: #81d4fa !important;
}

.light-blue-300-bg {
  background-color: #4fc3f7 !important;
}

.light-blue-300 {
  background-color: #4fc3f7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-blue-300[disabled] {
  background-color: rgba(79, 195, 247, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.light-blue-300-fg {
  color: #4fc3f7 !important;
}

.light-blue-300-border {
  border-color: #4fc3f7 !important;
}

.light-blue-300-border-top {
  border-top-color: #4fc3f7 !important;
}

.light-blue-300-border-right {
  border-right-color: #4fc3f7 !important;
}

.light-blue-300-border-bottom {
  border-bottom-color: #4fc3f7 !important;
}

.light-blue-300-border-left {
  border-left-color: #4fc3f7 !important;
}

.light-blue-400-bg {
  background-color: #29b6f6 !important;
}

.light-blue-400 {
  background-color: #29b6f6 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-blue-400[disabled] {
  background-color: rgba(41, 182, 246, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.light-blue-400-fg {
  color: #29b6f6 !important;
}

.light-blue-400-border {
  border-color: #29b6f6 !important;
}

.light-blue-400-border-top {
  border-top-color: #29b6f6 !important;
}

.light-blue-400-border-right {
  border-right-color: #29b6f6 !important;
}

.light-blue-400-border-bottom {
  border-bottom-color: #29b6f6 !important;
}

.light-blue-400-border-left {
  border-left-color: #29b6f6 !important;
}

.light-blue-500-bg {
  background-color: #03a9f4 !important;
}

.light-blue-500 {
  background-color: #03a9f4 !important;
  color: white !important;
}
.light-blue-500[disabled] {
  background-color: rgba(3, 169, 244, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.light-blue-500-fg {
  color: #03a9f4 !important;
}

.light-blue-500-border {
  border-color: #03a9f4 !important;
}

.light-blue-500-border-top {
  border-top-color: #03a9f4 !important;
}

.light-blue-500-border-right {
  border-right-color: #03a9f4 !important;
}

.light-blue-500-border-bottom {
  border-bottom-color: #03a9f4 !important;
}

.light-blue-500-border-left {
  border-left-color: #03a9f4 !important;
}

.light-blue-bg {
  background-color: #03a9f4 !important;
}

.light-blue {
  background-color: #03a9f4 !important;
  color: white !important;
}
.light-blue[disabled] {
  background-color: rgba(3, 169, 244, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.light-blue-fg {
  color: #03a9f4 !important;
}

.light-blue-border {
  border-color: #03a9f4 !important;
}

.light-blue-border-top {
  border-top-color: #03a9f4 !important;
}

.light-blue-border-right {
  border-right-color: #03a9f4 !important;
}

.light-blue-border-bottom {
  border-bottom-color: #03a9f4 !important;
}

.light-blue-border-left {
  border-left-color: #03a9f4 !important;
}

.light-blue-600-bg {
  background-color: #039be5 !important;
}

.light-blue-600 {
  background-color: #039be5 !important;
  color: white !important;
}
.light-blue-600[disabled] {
  background-color: rgba(3, 155, 229, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.light-blue-600-fg {
  color: #039be5 !important;
}

.light-blue-600-border {
  border-color: #039be5 !important;
}

.light-blue-600-border-top {
  border-top-color: #039be5 !important;
}

.light-blue-600-border-right {
  border-right-color: #039be5 !important;
}

.light-blue-600-border-bottom {
  border-bottom-color: #039be5 !important;
}

.light-blue-600-border-left {
  border-left-color: #039be5 !important;
}

.light-blue-700-bg {
  background-color: #0288d1 !important;
}

.light-blue-700 {
  background-color: #0288d1 !important;
  color: white !important;
}
.light-blue-700[disabled] {
  background-color: rgba(2, 136, 209, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.light-blue-700-fg {
  color: #0288d1 !important;
}

.light-blue-700-border {
  border-color: #0288d1 !important;
}

.light-blue-700-border-top {
  border-top-color: #0288d1 !important;
}

.light-blue-700-border-right {
  border-right-color: #0288d1 !important;
}

.light-blue-700-border-bottom {
  border-bottom-color: #0288d1 !important;
}

.light-blue-700-border-left {
  border-left-color: #0288d1 !important;
}

.light-blue-800-bg {
  background-color: #0277bd !important;
}

.light-blue-800 {
  background-color: #0277bd !important;
  color: white !important;
}
.light-blue-800[disabled] {
  background-color: rgba(2, 119, 189, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.light-blue-800-fg {
  color: #0277bd !important;
}

.light-blue-800-border {
  border-color: #0277bd !important;
}

.light-blue-800-border-top {
  border-top-color: #0277bd !important;
}

.light-blue-800-border-right {
  border-right-color: #0277bd !important;
}

.light-blue-800-border-bottom {
  border-bottom-color: #0277bd !important;
}

.light-blue-800-border-left {
  border-left-color: #0277bd !important;
}

.light-blue-900-bg {
  background-color: #01579b !important;
}

.light-blue-900 {
  background-color: #01579b !important;
  color: white !important;
}
.light-blue-900[disabled] {
  background-color: rgba(1, 87, 155, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.light-blue-900-fg {
  color: #01579b !important;
}

.light-blue-900-border {
  border-color: #01579b !important;
}

.light-blue-900-border-top {
  border-top-color: #01579b !important;
}

.light-blue-900-border-right {
  border-right-color: #01579b !important;
}

.light-blue-900-border-bottom {
  border-bottom-color: #01579b !important;
}

.light-blue-900-border-left {
  border-left-color: #01579b !important;
}

.light-blue-A100-bg {
  background-color: #80d8ff !important;
}

.light-blue-A100 {
  background-color: #80d8ff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-blue-A100[disabled] {
  background-color: rgba(128, 216, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.light-blue-A100-fg {
  color: #80d8ff !important;
}

.light-blue-A100-border {
  border-color: #80d8ff !important;
}

.light-blue-A100-border-top {
  border-top-color: #80d8ff !important;
}

.light-blue-A100-border-right {
  border-right-color: #80d8ff !important;
}

.light-blue-A100-border-bottom {
  border-bottom-color: #80d8ff !important;
}

.light-blue-A100-border-left {
  border-left-color: #80d8ff !important;
}

.light-blue-A200-bg {
  background-color: #40c4ff !important;
}

.light-blue-A200 {
  background-color: #40c4ff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-blue-A200[disabled] {
  background-color: rgba(64, 196, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.light-blue-A200-fg {
  color: #40c4ff !important;
}

.light-blue-A200-border {
  border-color: #40c4ff !important;
}

.light-blue-A200-border-top {
  border-top-color: #40c4ff !important;
}

.light-blue-A200-border-right {
  border-right-color: #40c4ff !important;
}

.light-blue-A200-border-bottom {
  border-bottom-color: #40c4ff !important;
}

.light-blue-A200-border-left {
  border-left-color: #40c4ff !important;
}

.light-blue-A400-bg {
  background-color: #00b0ff !important;
}

.light-blue-A400 {
  background-color: #00b0ff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-blue-A400[disabled] {
  background-color: rgba(0, 176, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.light-blue-A400-fg {
  color: #00b0ff !important;
}

.light-blue-A400-border {
  border-color: #00b0ff !important;
}

.light-blue-A400-border-top {
  border-top-color: #00b0ff !important;
}

.light-blue-A400-border-right {
  border-right-color: #00b0ff !important;
}

.light-blue-A400-border-bottom {
  border-bottom-color: #00b0ff !important;
}

.light-blue-A400-border-left {
  border-left-color: #00b0ff !important;
}

.light-blue-A700-bg {
  background-color: #0091ea !important;
}

.light-blue-A700 {
  background-color: #0091ea !important;
  color: white !important;
}
.light-blue-A700[disabled] {
  background-color: rgba(0, 145, 234, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.light-blue-A700-fg {
  color: #0091ea !important;
}

.light-blue-A700-border {
  border-color: #0091ea !important;
}

.light-blue-A700-border-top {
  border-top-color: #0091ea !important;
}

.light-blue-A700-border-right {
  border-right-color: #0091ea !important;
}

.light-blue-A700-border-bottom {
  border-bottom-color: #0091ea !important;
}

.light-blue-A700-border-left {
  border-left-color: #0091ea !important;
}

.cyan-50-bg {
  background-color: #e0f7fa !important;
}

.cyan-50 {
  background-color: #e0f7fa !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.cyan-50[disabled] {
  background-color: rgba(224, 247, 250, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.cyan-50-fg {
  color: #e0f7fa !important;
}

.cyan-50-border {
  border-color: #e0f7fa !important;
}

.cyan-50-border-top {
  border-top-color: #e0f7fa !important;
}

.cyan-50-border-right {
  border-right-color: #e0f7fa !important;
}

.cyan-50-border-bottom {
  border-bottom-color: #e0f7fa !important;
}

.cyan-50-border-left {
  border-left-color: #e0f7fa !important;
}

.cyan-100-bg {
  background-color: #b2ebf2 !important;
}

.cyan-100 {
  background-color: #b2ebf2 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.cyan-100[disabled] {
  background-color: rgba(178, 235, 242, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.cyan-100-fg {
  color: #b2ebf2 !important;
}

.cyan-100-border {
  border-color: #b2ebf2 !important;
}

.cyan-100-border-top {
  border-top-color: #b2ebf2 !important;
}

.cyan-100-border-right {
  border-right-color: #b2ebf2 !important;
}

.cyan-100-border-bottom {
  border-bottom-color: #b2ebf2 !important;
}

.cyan-100-border-left {
  border-left-color: #b2ebf2 !important;
}

.cyan-200-bg {
  background-color: #80deea !important;
}

.cyan-200 {
  background-color: #80deea !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.cyan-200[disabled] {
  background-color: rgba(128, 222, 234, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.cyan-200-fg {
  color: #80deea !important;
}

.cyan-200-border {
  border-color: #80deea !important;
}

.cyan-200-border-top {
  border-top-color: #80deea !important;
}

.cyan-200-border-right {
  border-right-color: #80deea !important;
}

.cyan-200-border-bottom {
  border-bottom-color: #80deea !important;
}

.cyan-200-border-left {
  border-left-color: #80deea !important;
}

.cyan-300-bg {
  background-color: #4dd0e1 !important;
}

.cyan-300 {
  background-color: #4dd0e1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.cyan-300[disabled] {
  background-color: rgba(77, 208, 225, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.cyan-300-fg {
  color: #4dd0e1 !important;
}

.cyan-300-border {
  border-color: #4dd0e1 !important;
}

.cyan-300-border-top {
  border-top-color: #4dd0e1 !important;
}

.cyan-300-border-right {
  border-right-color: #4dd0e1 !important;
}

.cyan-300-border-bottom {
  border-bottom-color: #4dd0e1 !important;
}

.cyan-300-border-left {
  border-left-color: #4dd0e1 !important;
}

.cyan-400-bg {
  background-color: #26c6da !important;
}

.cyan-400 {
  background-color: #26c6da !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.cyan-400[disabled] {
  background-color: rgba(38, 198, 218, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.cyan-400-fg {
  color: #26c6da !important;
}

.cyan-400-border {
  border-color: #26c6da !important;
}

.cyan-400-border-top {
  border-top-color: #26c6da !important;
}

.cyan-400-border-right {
  border-right-color: #26c6da !important;
}

.cyan-400-border-bottom {
  border-bottom-color: #26c6da !important;
}

.cyan-400-border-left {
  border-left-color: #26c6da !important;
}

.cyan-500-bg {
  background-color: #00bcd4 !important;
}

.cyan-500 {
  background-color: #00bcd4 !important;
  color: white !important;
}
.cyan-500[disabled] {
  background-color: rgba(0, 188, 212, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.cyan-500-fg {
  color: #00bcd4 !important;
}

.cyan-500-border {
  border-color: #00bcd4 !important;
}

.cyan-500-border-top {
  border-top-color: #00bcd4 !important;
}

.cyan-500-border-right {
  border-right-color: #00bcd4 !important;
}

.cyan-500-border-bottom {
  border-bottom-color: #00bcd4 !important;
}

.cyan-500-border-left {
  border-left-color: #00bcd4 !important;
}

.cyan-bg {
  background-color: #00bcd4 !important;
}

.cyan {
  background-color: #00bcd4 !important;
  color: white !important;
}
.cyan[disabled] {
  background-color: rgba(0, 188, 212, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.cyan-fg {
  color: #00bcd4 !important;
}

.cyan-border {
  border-color: #00bcd4 !important;
}

.cyan-border-top {
  border-top-color: #00bcd4 !important;
}

.cyan-border-right {
  border-right-color: #00bcd4 !important;
}

.cyan-border-bottom {
  border-bottom-color: #00bcd4 !important;
}

.cyan-border-left {
  border-left-color: #00bcd4 !important;
}

.cyan-600-bg {
  background-color: #00acc1 !important;
}

.cyan-600 {
  background-color: #00acc1 !important;
  color: white !important;
}
.cyan-600[disabled] {
  background-color: rgba(0, 172, 193, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.cyan-600-fg {
  color: #00acc1 !important;
}

.cyan-600-border {
  border-color: #00acc1 !important;
}

.cyan-600-border-top {
  border-top-color: #00acc1 !important;
}

.cyan-600-border-right {
  border-right-color: #00acc1 !important;
}

.cyan-600-border-bottom {
  border-bottom-color: #00acc1 !important;
}

.cyan-600-border-left {
  border-left-color: #00acc1 !important;
}

.cyan-700-bg {
  background-color: #0097a7 !important;
}

.cyan-700 {
  background-color: #0097a7 !important;
  color: white !important;
}
.cyan-700[disabled] {
  background-color: rgba(0, 151, 167, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.cyan-700-fg {
  color: #0097a7 !important;
}

.cyan-700-border {
  border-color: #0097a7 !important;
}

.cyan-700-border-top {
  border-top-color: #0097a7 !important;
}

.cyan-700-border-right {
  border-right-color: #0097a7 !important;
}

.cyan-700-border-bottom {
  border-bottom-color: #0097a7 !important;
}

.cyan-700-border-left {
  border-left-color: #0097a7 !important;
}

.cyan-800-bg {
  background-color: #00838f !important;
}

.cyan-800 {
  background-color: #00838f !important;
  color: white !important;
}
.cyan-800[disabled] {
  background-color: rgba(0, 131, 143, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.cyan-800-fg {
  color: #00838f !important;
}

.cyan-800-border {
  border-color: #00838f !important;
}

.cyan-800-border-top {
  border-top-color: #00838f !important;
}

.cyan-800-border-right {
  border-right-color: #00838f !important;
}

.cyan-800-border-bottom {
  border-bottom-color: #00838f !important;
}

.cyan-800-border-left {
  border-left-color: #00838f !important;
}

.cyan-900-bg {
  background-color: #006064 !important;
}

.cyan-900 {
  background-color: #006064 !important;
  color: white !important;
}
.cyan-900[disabled] {
  background-color: rgba(0, 96, 100, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.cyan-900-fg {
  color: #006064 !important;
}

.cyan-900-border {
  border-color: #006064 !important;
}

.cyan-900-border-top {
  border-top-color: #006064 !important;
}

.cyan-900-border-right {
  border-right-color: #006064 !important;
}

.cyan-900-border-bottom {
  border-bottom-color: #006064 !important;
}

.cyan-900-border-left {
  border-left-color: #006064 !important;
}

.cyan-A100-bg {
  background-color: #84ffff !important;
}

.cyan-A100 {
  background-color: #84ffff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.cyan-A100[disabled] {
  background-color: rgba(132, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.cyan-A100-fg {
  color: #84ffff !important;
}

.cyan-A100-border {
  border-color: #84ffff !important;
}

.cyan-A100-border-top {
  border-top-color: #84ffff !important;
}

.cyan-A100-border-right {
  border-right-color: #84ffff !important;
}

.cyan-A100-border-bottom {
  border-bottom-color: #84ffff !important;
}

.cyan-A100-border-left {
  border-left-color: #84ffff !important;
}

.cyan-A200-bg {
  background-color: #18ffff !important;
}

.cyan-A200 {
  background-color: #18ffff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.cyan-A200[disabled] {
  background-color: rgba(24, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.cyan-A200-fg {
  color: #18ffff !important;
}

.cyan-A200-border {
  border-color: #18ffff !important;
}

.cyan-A200-border-top {
  border-top-color: #18ffff !important;
}

.cyan-A200-border-right {
  border-right-color: #18ffff !important;
}

.cyan-A200-border-bottom {
  border-bottom-color: #18ffff !important;
}

.cyan-A200-border-left {
  border-left-color: #18ffff !important;
}

.cyan-A400-bg {
  background-color: #00e5ff !important;
}

.cyan-A400 {
  background-color: #00e5ff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.cyan-A400[disabled] {
  background-color: rgba(0, 229, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.cyan-A400-fg {
  color: #00e5ff !important;
}

.cyan-A400-border {
  border-color: #00e5ff !important;
}

.cyan-A400-border-top {
  border-top-color: #00e5ff !important;
}

.cyan-A400-border-right {
  border-right-color: #00e5ff !important;
}

.cyan-A400-border-bottom {
  border-bottom-color: #00e5ff !important;
}

.cyan-A400-border-left {
  border-left-color: #00e5ff !important;
}

.cyan-A700-bg {
  background-color: #00b8d4 !important;
}

.cyan-A700 {
  background-color: #00b8d4 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.cyan-A700[disabled] {
  background-color: rgba(0, 184, 212, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.cyan-A700-fg {
  color: #00b8d4 !important;
}

.cyan-A700-border {
  border-color: #00b8d4 !important;
}

.cyan-A700-border-top {
  border-top-color: #00b8d4 !important;
}

.cyan-A700-border-right {
  border-right-color: #00b8d4 !important;
}

.cyan-A700-border-bottom {
  border-bottom-color: #00b8d4 !important;
}

.cyan-A700-border-left {
  border-left-color: #00b8d4 !important;
}

.teal-50-bg {
  background-color: #e0f2f1 !important;
}

.teal-50 {
  background-color: #e0f2f1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.teal-50[disabled] {
  background-color: rgba(224, 242, 241, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.teal-50-fg {
  color: #e0f2f1 !important;
}

.teal-50-border {
  border-color: #e0f2f1 !important;
}

.teal-50-border-top {
  border-top-color: #e0f2f1 !important;
}

.teal-50-border-right {
  border-right-color: #e0f2f1 !important;
}

.teal-50-border-bottom {
  border-bottom-color: #e0f2f1 !important;
}

.teal-50-border-left {
  border-left-color: #e0f2f1 !important;
}

.teal-100-bg {
  background-color: #b2dfdb !important;
}

.teal-100 {
  background-color: #b2dfdb !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.teal-100[disabled] {
  background-color: rgba(178, 223, 219, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.teal-100-fg {
  color: #b2dfdb !important;
}

.teal-100-border {
  border-color: #b2dfdb !important;
}

.teal-100-border-top {
  border-top-color: #b2dfdb !important;
}

.teal-100-border-right {
  border-right-color: #b2dfdb !important;
}

.teal-100-border-bottom {
  border-bottom-color: #b2dfdb !important;
}

.teal-100-border-left {
  border-left-color: #b2dfdb !important;
}

.teal-200-bg {
  background-color: #80cbc4 !important;
}

.teal-200 {
  background-color: #80cbc4 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.teal-200[disabled] {
  background-color: rgba(128, 203, 196, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.teal-200-fg {
  color: #80cbc4 !important;
}

.teal-200-border {
  border-color: #80cbc4 !important;
}

.teal-200-border-top {
  border-top-color: #80cbc4 !important;
}

.teal-200-border-right {
  border-right-color: #80cbc4 !important;
}

.teal-200-border-bottom {
  border-bottom-color: #80cbc4 !important;
}

.teal-200-border-left {
  border-left-color: #80cbc4 !important;
}

.teal-300-bg {
  background-color: #4db6ac !important;
}

.teal-300 {
  background-color: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.teal-300[disabled] {
  background-color: rgba(77, 182, 172, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.teal-300-fg {
  color: #4db6ac !important;
}

.teal-300-border {
  border-color: #4db6ac !important;
}

.teal-300-border-top {
  border-top-color: #4db6ac !important;
}

.teal-300-border-right {
  border-right-color: #4db6ac !important;
}

.teal-300-border-bottom {
  border-bottom-color: #4db6ac !important;
}

.teal-300-border-left {
  border-left-color: #4db6ac !important;
}

.teal-400-bg {
  background-color: #26a69a !important;
}

.teal-400 {
  background-color: #26a69a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.teal-400[disabled] {
  background-color: rgba(38, 166, 154, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.teal-400-fg {
  color: #26a69a !important;
}

.teal-400-border {
  border-color: #26a69a !important;
}

.teal-400-border-top {
  border-top-color: #26a69a !important;
}

.teal-400-border-right {
  border-right-color: #26a69a !important;
}

.teal-400-border-bottom {
  border-bottom-color: #26a69a !important;
}

.teal-400-border-left {
  border-left-color: #26a69a !important;
}

.teal-500-bg {
  background-color: #009688 !important;
}

.teal-500 {
  background-color: #009688 !important;
  color: white !important;
}
.teal-500[disabled] {
  background-color: rgba(0, 150, 136, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.teal-500-fg {
  color: #009688 !important;
}

.teal-500-border {
  border-color: #009688 !important;
}

.teal-500-border-top {
  border-top-color: #009688 !important;
}

.teal-500-border-right {
  border-right-color: #009688 !important;
}

.teal-500-border-bottom {
  border-bottom-color: #009688 !important;
}

.teal-500-border-left {
  border-left-color: #009688 !important;
}

.teal-bg {
  background-color: #009688 !important;
}

.teal {
  background-color: #009688 !important;
  color: white !important;
}
.teal[disabled] {
  background-color: rgba(0, 150, 136, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.teal-fg {
  color: #009688 !important;
}

.teal-border {
  border-color: #009688 !important;
}

.teal-border-top {
  border-top-color: #009688 !important;
}

.teal-border-right {
  border-right-color: #009688 !important;
}

.teal-border-bottom {
  border-bottom-color: #009688 !important;
}

.teal-border-left {
  border-left-color: #009688 !important;
}

.teal-600-bg {
  background-color: #00897b !important;
}

.teal-600 {
  background-color: #00897b !important;
  color: white !important;
}
.teal-600[disabled] {
  background-color: rgba(0, 137, 123, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.teal-600-fg {
  color: #00897b !important;
}

.teal-600-border {
  border-color: #00897b !important;
}

.teal-600-border-top {
  border-top-color: #00897b !important;
}

.teal-600-border-right {
  border-right-color: #00897b !important;
}

.teal-600-border-bottom {
  border-bottom-color: #00897b !important;
}

.teal-600-border-left {
  border-left-color: #00897b !important;
}

.teal-700-bg {
  background-color: #00796b !important;
}

.teal-700 {
  background-color: #00796b !important;
  color: white !important;
}
.teal-700[disabled] {
  background-color: rgba(0, 121, 107, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.teal-700-fg {
  color: #00796b !important;
}

.teal-700-border {
  border-color: #00796b !important;
}

.teal-700-border-top {
  border-top-color: #00796b !important;
}

.teal-700-border-right {
  border-right-color: #00796b !important;
}

.teal-700-border-bottom {
  border-bottom-color: #00796b !important;
}

.teal-700-border-left {
  border-left-color: #00796b !important;
}

.teal-800-bg {
  background-color: #00695c !important;
}

.teal-800 {
  background-color: #00695c !important;
  color: white !important;
}
.teal-800[disabled] {
  background-color: rgba(0, 105, 92, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.teal-800-fg {
  color: #00695c !important;
}

.teal-800-border {
  border-color: #00695c !important;
}

.teal-800-border-top {
  border-top-color: #00695c !important;
}

.teal-800-border-right {
  border-right-color: #00695c !important;
}

.teal-800-border-bottom {
  border-bottom-color: #00695c !important;
}

.teal-800-border-left {
  border-left-color: #00695c !important;
}

.teal-900-bg {
  background-color: #004d40 !important;
}

.teal-900 {
  background-color: #004d40 !important;
  color: white !important;
}
.teal-900[disabled] {
  background-color: rgba(0, 77, 64, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.teal-900-fg {
  color: #004d40 !important;
}

.teal-900-border {
  border-color: #004d40 !important;
}

.teal-900-border-top {
  border-top-color: #004d40 !important;
}

.teal-900-border-right {
  border-right-color: #004d40 !important;
}

.teal-900-border-bottom {
  border-bottom-color: #004d40 !important;
}

.teal-900-border-left {
  border-left-color: #004d40 !important;
}

.teal-A100-bg {
  background-color: #a7ffeb !important;
}

.teal-A100 {
  background-color: #a7ffeb !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.teal-A100[disabled] {
  background-color: rgba(167, 255, 235, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.teal-A100-fg {
  color: #a7ffeb !important;
}

.teal-A100-border {
  border-color: #a7ffeb !important;
}

.teal-A100-border-top {
  border-top-color: #a7ffeb !important;
}

.teal-A100-border-right {
  border-right-color: #a7ffeb !important;
}

.teal-A100-border-bottom {
  border-bottom-color: #a7ffeb !important;
}

.teal-A100-border-left {
  border-left-color: #a7ffeb !important;
}

.teal-A200-bg {
  background-color: #64ffda !important;
}

.teal-A200 {
  background-color: #64ffda !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.teal-A200[disabled] {
  background-color: rgba(100, 255, 218, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.teal-A200-fg {
  color: #64ffda !important;
}

.teal-A200-border {
  border-color: #64ffda !important;
}

.teal-A200-border-top {
  border-top-color: #64ffda !important;
}

.teal-A200-border-right {
  border-right-color: #64ffda !important;
}

.teal-A200-border-bottom {
  border-bottom-color: #64ffda !important;
}

.teal-A200-border-left {
  border-left-color: #64ffda !important;
}

.teal-A400-bg {
  background-color: #1de9b6 !important;
}

.teal-A400 {
  background-color: #1de9b6 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.teal-A400[disabled] {
  background-color: rgba(29, 233, 182, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.teal-A400-fg {
  color: #1de9b6 !important;
}

.teal-A400-border {
  border-color: #1de9b6 !important;
}

.teal-A400-border-top {
  border-top-color: #1de9b6 !important;
}

.teal-A400-border-right {
  border-right-color: #1de9b6 !important;
}

.teal-A400-border-bottom {
  border-bottom-color: #1de9b6 !important;
}

.teal-A400-border-left {
  border-left-color: #1de9b6 !important;
}

.teal-A700-bg {
  background-color: #00bfa5 !important;
}

.teal-A700 {
  background-color: #00bfa5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.teal-A700[disabled] {
  background-color: rgba(0, 191, 165, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.teal-A700-fg {
  color: #00bfa5 !important;
}

.teal-A700-border {
  border-color: #00bfa5 !important;
}

.teal-A700-border-top {
  border-top-color: #00bfa5 !important;
}

.teal-A700-border-right {
  border-right-color: #00bfa5 !important;
}

.teal-A700-border-bottom {
  border-bottom-color: #00bfa5 !important;
}

.teal-A700-border-left {
  border-left-color: #00bfa5 !important;
}

.green-50-bg {
  background-color: #e8f5e9 !important;
}

.green-50 {
  background-color: #e8f5e9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.green-50[disabled] {
  background-color: rgba(232, 245, 233, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.green-50-fg {
  color: #e8f5e9 !important;
}

.green-50-border {
  border-color: #e8f5e9 !important;
}

.green-50-border-top {
  border-top-color: #e8f5e9 !important;
}

.green-50-border-right {
  border-right-color: #e8f5e9 !important;
}

.green-50-border-bottom {
  border-bottom-color: #e8f5e9 !important;
}

.green-50-border-left {
  border-left-color: #e8f5e9 !important;
}

.green-100-bg {
  background-color: #c8e6c9 !important;
}

.green-100 {
  background-color: #c8e6c9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.green-100[disabled] {
  background-color: rgba(200, 230, 201, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.green-100-fg {
  color: #c8e6c9 !important;
}

.green-100-border {
  border-color: #c8e6c9 !important;
}

.green-100-border-top {
  border-top-color: #c8e6c9 !important;
}

.green-100-border-right {
  border-right-color: #c8e6c9 !important;
}

.green-100-border-bottom {
  border-bottom-color: #c8e6c9 !important;
}

.green-100-border-left {
  border-left-color: #c8e6c9 !important;
}

.green-200-bg {
  background-color: #a5d6a7 !important;
}

.green-200 {
  background-color: #a5d6a7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.green-200[disabled] {
  background-color: rgba(165, 214, 167, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.green-200-fg {
  color: #a5d6a7 !important;
}

.green-200-border {
  border-color: #a5d6a7 !important;
}

.green-200-border-top {
  border-top-color: #a5d6a7 !important;
}

.green-200-border-right {
  border-right-color: #a5d6a7 !important;
}

.green-200-border-bottom {
  border-bottom-color: #a5d6a7 !important;
}

.green-200-border-left {
  border-left-color: #a5d6a7 !important;
}

.green-300-bg {
  background-color: #81c784 !important;
}

.green-300 {
  background-color: #81c784 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.green-300[disabled] {
  background-color: rgba(129, 199, 132, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.green-300-fg {
  color: #81c784 !important;
}

.green-300-border {
  border-color: #81c784 !important;
}

.green-300-border-top {
  border-top-color: #81c784 !important;
}

.green-300-border-right {
  border-right-color: #81c784 !important;
}

.green-300-border-bottom {
  border-bottom-color: #81c784 !important;
}

.green-300-border-left {
  border-left-color: #81c784 !important;
}

.green-400-bg {
  background-color: #66bb6a !important;
}

.green-400 {
  background-color: #66bb6a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.green-400[disabled] {
  background-color: rgba(102, 187, 106, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.green-400-fg {
  color: #66bb6a !important;
}

.green-400-border {
  border-color: #66bb6a !important;
}

.green-400-border-top {
  border-top-color: #66bb6a !important;
}

.green-400-border-right {
  border-right-color: #66bb6a !important;
}

.green-400-border-bottom {
  border-bottom-color: #66bb6a !important;
}

.green-400-border-left {
  border-left-color: #66bb6a !important;
}

.green-500-bg {
  background-color: #4caf50 !important;
}

.green-500 {
  background-color: #4caf50 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.green-500[disabled] {
  background-color: rgba(76, 175, 80, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.green-500-fg {
  color: #4caf50 !important;
}

.green-500-border {
  border-color: #4caf50 !important;
}

.green-500-border-top {
  border-top-color: #4caf50 !important;
}

.green-500-border-right {
  border-right-color: #4caf50 !important;
}

.green-500-border-bottom {
  border-bottom-color: #4caf50 !important;
}

.green-500-border-left {
  border-left-color: #4caf50 !important;
}

.green-bg {
  background-color: #4caf50 !important;
}

.green {
  background-color: #4caf50 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.green[disabled] {
  background-color: rgba(76, 175, 80, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.green-fg {
  color: #4caf50 !important;
}

.green-border {
  border-color: #4caf50 !important;
}

.green-border-top {
  border-top-color: #4caf50 !important;
}

.green-border-right {
  border-right-color: #4caf50 !important;
}

.green-border-bottom {
  border-bottom-color: #4caf50 !important;
}

.green-border-left {
  border-left-color: #4caf50 !important;
}

.green-600-bg {
  background-color: #43a047 !important;
}

.green-600 {
  background-color: #43a047 !important;
  color: white !important;
}
.green-600[disabled] {
  background-color: rgba(67, 160, 71, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.green-600-fg {
  color: #43a047 !important;
}

.green-600-border {
  border-color: #43a047 !important;
}

.green-600-border-top {
  border-top-color: #43a047 !important;
}

.green-600-border-right {
  border-right-color: #43a047 !important;
}

.green-600-border-bottom {
  border-bottom-color: #43a047 !important;
}

.green-600-border-left {
  border-left-color: #43a047 !important;
}

.green-700-bg {
  background-color: #388e3c !important;
}

.green-700 {
  background-color: #388e3c !important;
  color: white !important;
}
.green-700[disabled] {
  background-color: rgba(56, 142, 60, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.green-700-fg {
  color: #388e3c !important;
}

.green-700-border {
  border-color: #388e3c !important;
}

.green-700-border-top {
  border-top-color: #388e3c !important;
}

.green-700-border-right {
  border-right-color: #388e3c !important;
}

.green-700-border-bottom {
  border-bottom-color: #388e3c !important;
}

.green-700-border-left {
  border-left-color: #388e3c !important;
}

.green-800-bg {
  background-color: #2e7d32 !important;
}

.green-800 {
  background-color: #2e7d32 !important;
  color: white !important;
}
.green-800[disabled] {
  background-color: rgba(46, 125, 50, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.green-800-fg {
  color: #2e7d32 !important;
}

.green-800-border {
  border-color: #2e7d32 !important;
}

.green-800-border-top {
  border-top-color: #2e7d32 !important;
}

.green-800-border-right {
  border-right-color: #2e7d32 !important;
}

.green-800-border-bottom {
  border-bottom-color: #2e7d32 !important;
}

.green-800-border-left {
  border-left-color: #2e7d32 !important;
}

.green-900-bg {
  background-color: #1b5e20 !important;
}

.green-900 {
  background-color: #1b5e20 !important;
  color: white !important;
}
.green-900[disabled] {
  background-color: rgba(27, 94, 32, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.green-900-fg {
  color: #1b5e20 !important;
}

.green-900-border {
  border-color: #1b5e20 !important;
}

.green-900-border-top {
  border-top-color: #1b5e20 !important;
}

.green-900-border-right {
  border-right-color: #1b5e20 !important;
}

.green-900-border-bottom {
  border-bottom-color: #1b5e20 !important;
}

.green-900-border-left {
  border-left-color: #1b5e20 !important;
}

.green-A100-bg {
  background-color: #b9f6ca !important;
}

.green-A100 {
  background-color: #b9f6ca !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.green-A100[disabled] {
  background-color: rgba(185, 246, 202, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.green-A100-fg {
  color: #b9f6ca !important;
}

.green-A100-border {
  border-color: #b9f6ca !important;
}

.green-A100-border-top {
  border-top-color: #b9f6ca !important;
}

.green-A100-border-right {
  border-right-color: #b9f6ca !important;
}

.green-A100-border-bottom {
  border-bottom-color: #b9f6ca !important;
}

.green-A100-border-left {
  border-left-color: #b9f6ca !important;
}

.green-A200-bg {
  background-color: #69f0ae !important;
}

.green-A200 {
  background-color: #69f0ae !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.green-A200[disabled] {
  background-color: rgba(105, 240, 174, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.green-A200-fg {
  color: #69f0ae !important;
}

.green-A200-border {
  border-color: #69f0ae !important;
}

.green-A200-border-top {
  border-top-color: #69f0ae !important;
}

.green-A200-border-right {
  border-right-color: #69f0ae !important;
}

.green-A200-border-bottom {
  border-bottom-color: #69f0ae !important;
}

.green-A200-border-left {
  border-left-color: #69f0ae !important;
}

.green-A400-bg {
  background-color: #00e676 !important;
}

.green-A400 {
  background-color: #00e676 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.green-A400[disabled] {
  background-color: rgba(0, 230, 118, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.green-A400-fg {
  color: #00e676 !important;
}

.green-A400-border {
  border-color: #00e676 !important;
}

.green-A400-border-top {
  border-top-color: #00e676 !important;
}

.green-A400-border-right {
  border-right-color: #00e676 !important;
}

.green-A400-border-bottom {
  border-bottom-color: #00e676 !important;
}

.green-A400-border-left {
  border-left-color: #00e676 !important;
}

.green-A700-bg {
  background-color: #00c853 !important;
}

.green-A700 {
  background-color: #00c853 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.green-A700[disabled] {
  background-color: rgba(0, 200, 83, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.green-A700-fg {
  color: #00c853 !important;
}

.green-A700-border {
  border-color: #00c853 !important;
}

.green-A700-border-top {
  border-top-color: #00c853 !important;
}

.green-A700-border-right {
  border-right-color: #00c853 !important;
}

.green-A700-border-bottom {
  border-bottom-color: #00c853 !important;
}

.green-A700-border-left {
  border-left-color: #00c853 !important;
}

.light-green-50-bg {
  background-color: #f1f8e9 !important;
}

.light-green-50 {
  background-color: #f1f8e9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-green-50[disabled] {
  background-color: rgba(241, 248, 233, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.light-green-50-fg {
  color: #f1f8e9 !important;
}

.light-green-50-border {
  border-color: #f1f8e9 !important;
}

.light-green-50-border-top {
  border-top-color: #f1f8e9 !important;
}

.light-green-50-border-right {
  border-right-color: #f1f8e9 !important;
}

.light-green-50-border-bottom {
  border-bottom-color: #f1f8e9 !important;
}

.light-green-50-border-left {
  border-left-color: #f1f8e9 !important;
}

.light-green-100-bg {
  background-color: #dcedc8 !important;
}

.light-green-100 {
  background-color: #dcedc8 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-green-100[disabled] {
  background-color: rgba(220, 237, 200, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.light-green-100-fg {
  color: #dcedc8 !important;
}

.light-green-100-border {
  border-color: #dcedc8 !important;
}

.light-green-100-border-top {
  border-top-color: #dcedc8 !important;
}

.light-green-100-border-right {
  border-right-color: #dcedc8 !important;
}

.light-green-100-border-bottom {
  border-bottom-color: #dcedc8 !important;
}

.light-green-100-border-left {
  border-left-color: #dcedc8 !important;
}

.light-green-200-bg {
  background-color: #c5e1a5 !important;
}

.light-green-200 {
  background-color: #c5e1a5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-green-200[disabled] {
  background-color: rgba(197, 225, 165, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.light-green-200-fg {
  color: #c5e1a5 !important;
}

.light-green-200-border {
  border-color: #c5e1a5 !important;
}

.light-green-200-border-top {
  border-top-color: #c5e1a5 !important;
}

.light-green-200-border-right {
  border-right-color: #c5e1a5 !important;
}

.light-green-200-border-bottom {
  border-bottom-color: #c5e1a5 !important;
}

.light-green-200-border-left {
  border-left-color: #c5e1a5 !important;
}

.light-green-300-bg {
  background-color: #aed581 !important;
}

.light-green-300 {
  background-color: #aed581 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-green-300[disabled] {
  background-color: rgba(174, 213, 129, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.light-green-300-fg {
  color: #aed581 !important;
}

.light-green-300-border {
  border-color: #aed581 !important;
}

.light-green-300-border-top {
  border-top-color: #aed581 !important;
}

.light-green-300-border-right {
  border-right-color: #aed581 !important;
}

.light-green-300-border-bottom {
  border-bottom-color: #aed581 !important;
}

.light-green-300-border-left {
  border-left-color: #aed581 !important;
}

.light-green-400-bg {
  background-color: #9ccc65 !important;
}

.light-green-400 {
  background-color: #9ccc65 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-green-400[disabled] {
  background-color: rgba(156, 204, 101, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.light-green-400-fg {
  color: #9ccc65 !important;
}

.light-green-400-border {
  border-color: #9ccc65 !important;
}

.light-green-400-border-top {
  border-top-color: #9ccc65 !important;
}

.light-green-400-border-right {
  border-right-color: #9ccc65 !important;
}

.light-green-400-border-bottom {
  border-bottom-color: #9ccc65 !important;
}

.light-green-400-border-left {
  border-left-color: #9ccc65 !important;
}

.light-green-500-bg {
  background-color: #8bc34a !important;
}

.light-green-500 {
  background-color: #8bc34a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-green-500[disabled] {
  background-color: rgba(139, 195, 74, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.light-green-500-fg {
  color: #8bc34a !important;
}

.light-green-500-border {
  border-color: #8bc34a !important;
}

.light-green-500-border-top {
  border-top-color: #8bc34a !important;
}

.light-green-500-border-right {
  border-right-color: #8bc34a !important;
}

.light-green-500-border-bottom {
  border-bottom-color: #8bc34a !important;
}

.light-green-500-border-left {
  border-left-color: #8bc34a !important;
}

.light-green-bg {
  background-color: #8bc34a !important;
}

.light-green {
  background-color: #8bc34a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-green[disabled] {
  background-color: rgba(139, 195, 74, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.light-green-fg {
  color: #8bc34a !important;
}

.light-green-border {
  border-color: #8bc34a !important;
}

.light-green-border-top {
  border-top-color: #8bc34a !important;
}

.light-green-border-right {
  border-right-color: #8bc34a !important;
}

.light-green-border-bottom {
  border-bottom-color: #8bc34a !important;
}

.light-green-border-left {
  border-left-color: #8bc34a !important;
}

.light-green-600-bg {
  background-color: #7cb342 !important;
}

.light-green-600 {
  background-color: #7cb342 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-green-600[disabled] {
  background-color: rgba(124, 179, 66, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.light-green-600-fg {
  color: #7cb342 !important;
}

.light-green-600-border {
  border-color: #7cb342 !important;
}

.light-green-600-border-top {
  border-top-color: #7cb342 !important;
}

.light-green-600-border-right {
  border-right-color: #7cb342 !important;
}

.light-green-600-border-bottom {
  border-bottom-color: #7cb342 !important;
}

.light-green-600-border-left {
  border-left-color: #7cb342 !important;
}

.light-green-700-bg {
  background-color: #689f38 !important;
}

.light-green-700 {
  background-color: #689f38 !important;
  color: white !important;
}
.light-green-700[disabled] {
  background-color: rgba(104, 159, 56, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.light-green-700-fg {
  color: #689f38 !important;
}

.light-green-700-border {
  border-color: #689f38 !important;
}

.light-green-700-border-top {
  border-top-color: #689f38 !important;
}

.light-green-700-border-right {
  border-right-color: #689f38 !important;
}

.light-green-700-border-bottom {
  border-bottom-color: #689f38 !important;
}

.light-green-700-border-left {
  border-left-color: #689f38 !important;
}

.light-green-800-bg {
  background-color: #558b2f !important;
}

.light-green-800 {
  background-color: #558b2f !important;
  color: white !important;
}
.light-green-800[disabled] {
  background-color: rgba(85, 139, 47, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.light-green-800-fg {
  color: #558b2f !important;
}

.light-green-800-border {
  border-color: #558b2f !important;
}

.light-green-800-border-top {
  border-top-color: #558b2f !important;
}

.light-green-800-border-right {
  border-right-color: #558b2f !important;
}

.light-green-800-border-bottom {
  border-bottom-color: #558b2f !important;
}

.light-green-800-border-left {
  border-left-color: #558b2f !important;
}

.light-green-900-bg {
  background-color: #33691e !important;
}

.light-green-900 {
  background-color: #33691e !important;
  color: white !important;
}
.light-green-900[disabled] {
  background-color: rgba(51, 105, 30, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.light-green-900-fg {
  color: #33691e !important;
}

.light-green-900-border {
  border-color: #33691e !important;
}

.light-green-900-border-top {
  border-top-color: #33691e !important;
}

.light-green-900-border-right {
  border-right-color: #33691e !important;
}

.light-green-900-border-bottom {
  border-bottom-color: #33691e !important;
}

.light-green-900-border-left {
  border-left-color: #33691e !important;
}

.light-green-A100-bg {
  background-color: #ccff90 !important;
}

.light-green-A100 {
  background-color: #ccff90 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-green-A100[disabled] {
  background-color: rgba(204, 255, 144, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.light-green-A100-fg {
  color: #ccff90 !important;
}

.light-green-A100-border {
  border-color: #ccff90 !important;
}

.light-green-A100-border-top {
  border-top-color: #ccff90 !important;
}

.light-green-A100-border-right {
  border-right-color: #ccff90 !important;
}

.light-green-A100-border-bottom {
  border-bottom-color: #ccff90 !important;
}

.light-green-A100-border-left {
  border-left-color: #ccff90 !important;
}

.light-green-A200-bg {
  background-color: #b2ff59 !important;
}

.light-green-A200 {
  background-color: #b2ff59 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-green-A200[disabled] {
  background-color: rgba(178, 255, 89, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.light-green-A200-fg {
  color: #b2ff59 !important;
}

.light-green-A200-border {
  border-color: #b2ff59 !important;
}

.light-green-A200-border-top {
  border-top-color: #b2ff59 !important;
}

.light-green-A200-border-right {
  border-right-color: #b2ff59 !important;
}

.light-green-A200-border-bottom {
  border-bottom-color: #b2ff59 !important;
}

.light-green-A200-border-left {
  border-left-color: #b2ff59 !important;
}

.light-green-A400-bg {
  background-color: #76ff03 !important;
}

.light-green-A400 {
  background-color: #76ff03 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-green-A400[disabled] {
  background-color: rgba(118, 255, 3, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.light-green-A400-fg {
  color: #76ff03 !important;
}

.light-green-A400-border {
  border-color: #76ff03 !important;
}

.light-green-A400-border-top {
  border-top-color: #76ff03 !important;
}

.light-green-A400-border-right {
  border-right-color: #76ff03 !important;
}

.light-green-A400-border-bottom {
  border-bottom-color: #76ff03 !important;
}

.light-green-A400-border-left {
  border-left-color: #76ff03 !important;
}

.light-green-A700-bg {
  background-color: #64dd17 !important;
}

.light-green-A700 {
  background-color: #64dd17 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-green-A700[disabled] {
  background-color: rgba(100, 221, 23, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.light-green-A700-fg {
  color: #64dd17 !important;
}

.light-green-A700-border {
  border-color: #64dd17 !important;
}

.light-green-A700-border-top {
  border-top-color: #64dd17 !important;
}

.light-green-A700-border-right {
  border-right-color: #64dd17 !important;
}

.light-green-A700-border-bottom {
  border-bottom-color: #64dd17 !important;
}

.light-green-A700-border-left {
  border-left-color: #64dd17 !important;
}

.lime-50-bg {
  background-color: #f9fbe7 !important;
}

.lime-50 {
  background-color: #f9fbe7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.lime-50[disabled] {
  background-color: rgba(249, 251, 231, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.lime-50-fg {
  color: #f9fbe7 !important;
}

.lime-50-border {
  border-color: #f9fbe7 !important;
}

.lime-50-border-top {
  border-top-color: #f9fbe7 !important;
}

.lime-50-border-right {
  border-right-color: #f9fbe7 !important;
}

.lime-50-border-bottom {
  border-bottom-color: #f9fbe7 !important;
}

.lime-50-border-left {
  border-left-color: #f9fbe7 !important;
}

.lime-100-bg {
  background-color: #f0f4c3 !important;
}

.lime-100 {
  background-color: #f0f4c3 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.lime-100[disabled] {
  background-color: rgba(240, 244, 195, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.lime-100-fg {
  color: #f0f4c3 !important;
}

.lime-100-border {
  border-color: #f0f4c3 !important;
}

.lime-100-border-top {
  border-top-color: #f0f4c3 !important;
}

.lime-100-border-right {
  border-right-color: #f0f4c3 !important;
}

.lime-100-border-bottom {
  border-bottom-color: #f0f4c3 !important;
}

.lime-100-border-left {
  border-left-color: #f0f4c3 !important;
}

.lime-200-bg {
  background-color: #e6ee9c !important;
}

.lime-200 {
  background-color: #e6ee9c !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.lime-200[disabled] {
  background-color: rgba(230, 238, 156, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.lime-200-fg {
  color: #e6ee9c !important;
}

.lime-200-border {
  border-color: #e6ee9c !important;
}

.lime-200-border-top {
  border-top-color: #e6ee9c !important;
}

.lime-200-border-right {
  border-right-color: #e6ee9c !important;
}

.lime-200-border-bottom {
  border-bottom-color: #e6ee9c !important;
}

.lime-200-border-left {
  border-left-color: #e6ee9c !important;
}

.lime-300-bg {
  background-color: #dce775 !important;
}

.lime-300 {
  background-color: #dce775 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.lime-300[disabled] {
  background-color: rgba(220, 231, 117, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.lime-300-fg {
  color: #dce775 !important;
}

.lime-300-border {
  border-color: #dce775 !important;
}

.lime-300-border-top {
  border-top-color: #dce775 !important;
}

.lime-300-border-right {
  border-right-color: #dce775 !important;
}

.lime-300-border-bottom {
  border-bottom-color: #dce775 !important;
}

.lime-300-border-left {
  border-left-color: #dce775 !important;
}

.lime-400-bg {
  background-color: #d4e157 !important;
}

.lime-400 {
  background-color: #d4e157 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.lime-400[disabled] {
  background-color: rgba(212, 225, 87, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.lime-400-fg {
  color: #d4e157 !important;
}

.lime-400-border {
  border-color: #d4e157 !important;
}

.lime-400-border-top {
  border-top-color: #d4e157 !important;
}

.lime-400-border-right {
  border-right-color: #d4e157 !important;
}

.lime-400-border-bottom {
  border-bottom-color: #d4e157 !important;
}

.lime-400-border-left {
  border-left-color: #d4e157 !important;
}

.lime-500-bg {
  background-color: #cddc39 !important;
}

.lime-500 {
  background-color: #cddc39 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.lime-500[disabled] {
  background-color: rgba(205, 220, 57, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.lime-500-fg {
  color: #cddc39 !important;
}

.lime-500-border {
  border-color: #cddc39 !important;
}

.lime-500-border-top {
  border-top-color: #cddc39 !important;
}

.lime-500-border-right {
  border-right-color: #cddc39 !important;
}

.lime-500-border-bottom {
  border-bottom-color: #cddc39 !important;
}

.lime-500-border-left {
  border-left-color: #cddc39 !important;
}

.lime-bg {
  background-color: #cddc39 !important;
}

.lime {
  background-color: #cddc39 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.lime[disabled] {
  background-color: rgba(205, 220, 57, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.lime-fg {
  color: #cddc39 !important;
}

.lime-border {
  border-color: #cddc39 !important;
}

.lime-border-top {
  border-top-color: #cddc39 !important;
}

.lime-border-right {
  border-right-color: #cddc39 !important;
}

.lime-border-bottom {
  border-bottom-color: #cddc39 !important;
}

.lime-border-left {
  border-left-color: #cddc39 !important;
}

.lime-600-bg {
  background-color: #c0ca33 !important;
}

.lime-600 {
  background-color: #c0ca33 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.lime-600[disabled] {
  background-color: rgba(192, 202, 51, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.lime-600-fg {
  color: #c0ca33 !important;
}

.lime-600-border {
  border-color: #c0ca33 !important;
}

.lime-600-border-top {
  border-top-color: #c0ca33 !important;
}

.lime-600-border-right {
  border-right-color: #c0ca33 !important;
}

.lime-600-border-bottom {
  border-bottom-color: #c0ca33 !important;
}

.lime-600-border-left {
  border-left-color: #c0ca33 !important;
}

.lime-700-bg {
  background-color: #afb42b !important;
}

.lime-700 {
  background-color: #afb42b !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.lime-700[disabled] {
  background-color: rgba(175, 180, 43, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.lime-700-fg {
  color: #afb42b !important;
}

.lime-700-border {
  border-color: #afb42b !important;
}

.lime-700-border-top {
  border-top-color: #afb42b !important;
}

.lime-700-border-right {
  border-right-color: #afb42b !important;
}

.lime-700-border-bottom {
  border-bottom-color: #afb42b !important;
}

.lime-700-border-left {
  border-left-color: #afb42b !important;
}

.lime-800-bg {
  background-color: #9e9d24 !important;
}

.lime-800 {
  background-color: #9e9d24 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.lime-800[disabled] {
  background-color: rgba(158, 157, 36, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.lime-800-fg {
  color: #9e9d24 !important;
}

.lime-800-border {
  border-color: #9e9d24 !important;
}

.lime-800-border-top {
  border-top-color: #9e9d24 !important;
}

.lime-800-border-right {
  border-right-color: #9e9d24 !important;
}

.lime-800-border-bottom {
  border-bottom-color: #9e9d24 !important;
}

.lime-800-border-left {
  border-left-color: #9e9d24 !important;
}

.lime-900-bg {
  background-color: #827717 !important;
}

.lime-900 {
  background-color: #827717 !important;
  color: white !important;
}
.lime-900[disabled] {
  background-color: rgba(130, 119, 23, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.lime-900-fg {
  color: #827717 !important;
}

.lime-900-border {
  border-color: #827717 !important;
}

.lime-900-border-top {
  border-top-color: #827717 !important;
}

.lime-900-border-right {
  border-right-color: #827717 !important;
}

.lime-900-border-bottom {
  border-bottom-color: #827717 !important;
}

.lime-900-border-left {
  border-left-color: #827717 !important;
}

.lime-A100-bg {
  background-color: #f4ff81 !important;
}

.lime-A100 {
  background-color: #f4ff81 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.lime-A100[disabled] {
  background-color: rgba(244, 255, 129, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.lime-A100-fg {
  color: #f4ff81 !important;
}

.lime-A100-border {
  border-color: #f4ff81 !important;
}

.lime-A100-border-top {
  border-top-color: #f4ff81 !important;
}

.lime-A100-border-right {
  border-right-color: #f4ff81 !important;
}

.lime-A100-border-bottom {
  border-bottom-color: #f4ff81 !important;
}

.lime-A100-border-left {
  border-left-color: #f4ff81 !important;
}

.lime-A200-bg {
  background-color: #eeff41 !important;
}

.lime-A200 {
  background-color: #eeff41 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.lime-A200[disabled] {
  background-color: rgba(238, 255, 65, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.lime-A200-fg {
  color: #eeff41 !important;
}

.lime-A200-border {
  border-color: #eeff41 !important;
}

.lime-A200-border-top {
  border-top-color: #eeff41 !important;
}

.lime-A200-border-right {
  border-right-color: #eeff41 !important;
}

.lime-A200-border-bottom {
  border-bottom-color: #eeff41 !important;
}

.lime-A200-border-left {
  border-left-color: #eeff41 !important;
}

.lime-A400-bg {
  background-color: #c6ff00 !important;
}

.lime-A400 {
  background-color: #c6ff00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.lime-A400[disabled] {
  background-color: rgba(198, 255, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.lime-A400-fg {
  color: #c6ff00 !important;
}

.lime-A400-border {
  border-color: #c6ff00 !important;
}

.lime-A400-border-top {
  border-top-color: #c6ff00 !important;
}

.lime-A400-border-right {
  border-right-color: #c6ff00 !important;
}

.lime-A400-border-bottom {
  border-bottom-color: #c6ff00 !important;
}

.lime-A400-border-left {
  border-left-color: #c6ff00 !important;
}

.lime-A700-bg {
  background-color: #aeea00 !important;
}

.lime-A700 {
  background-color: #aeea00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.lime-A700[disabled] {
  background-color: rgba(174, 234, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.lime-A700-fg {
  color: #aeea00 !important;
}

.lime-A700-border {
  border-color: #aeea00 !important;
}

.lime-A700-border-top {
  border-top-color: #aeea00 !important;
}

.lime-A700-border-right {
  border-right-color: #aeea00 !important;
}

.lime-A700-border-bottom {
  border-bottom-color: #aeea00 !important;
}

.lime-A700-border-left {
  border-left-color: #aeea00 !important;
}

.yellow-50-bg {
  background-color: #fffde7 !important;
}

.yellow-50 {
  background-color: #fffde7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.yellow-50[disabled] {
  background-color: rgba(255, 253, 231, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.yellow-50-fg {
  color: #fffde7 !important;
}

.yellow-50-border {
  border-color: #fffde7 !important;
}

.yellow-50-border-top {
  border-top-color: #fffde7 !important;
}

.yellow-50-border-right {
  border-right-color: #fffde7 !important;
}

.yellow-50-border-bottom {
  border-bottom-color: #fffde7 !important;
}

.yellow-50-border-left {
  border-left-color: #fffde7 !important;
}

.yellow-100-bg {
  background-color: #fff9c4 !important;
}

.yellow-100 {
  background-color: #fff9c4 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.yellow-100[disabled] {
  background-color: rgba(255, 249, 196, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.yellow-100-fg {
  color: #fff9c4 !important;
}

.yellow-100-border {
  border-color: #fff9c4 !important;
}

.yellow-100-border-top {
  border-top-color: #fff9c4 !important;
}

.yellow-100-border-right {
  border-right-color: #fff9c4 !important;
}

.yellow-100-border-bottom {
  border-bottom-color: #fff9c4 !important;
}

.yellow-100-border-left {
  border-left-color: #fff9c4 !important;
}

.yellow-200-bg {
  background-color: #fff59d !important;
}

.yellow-200 {
  background-color: #fff59d !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.yellow-200[disabled] {
  background-color: rgba(255, 245, 157, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.yellow-200-fg {
  color: #fff59d !important;
}

.yellow-200-border {
  border-color: #fff59d !important;
}

.yellow-200-border-top {
  border-top-color: #fff59d !important;
}

.yellow-200-border-right {
  border-right-color: #fff59d !important;
}

.yellow-200-border-bottom {
  border-bottom-color: #fff59d !important;
}

.yellow-200-border-left {
  border-left-color: #fff59d !important;
}

.yellow-300-bg {
  background-color: #fff176 !important;
}

.yellow-300 {
  background-color: #fff176 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.yellow-300[disabled] {
  background-color: rgba(255, 241, 118, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.yellow-300-fg {
  color: #fff176 !important;
}

.yellow-300-border {
  border-color: #fff176 !important;
}

.yellow-300-border-top {
  border-top-color: #fff176 !important;
}

.yellow-300-border-right {
  border-right-color: #fff176 !important;
}

.yellow-300-border-bottom {
  border-bottom-color: #fff176 !important;
}

.yellow-300-border-left {
  border-left-color: #fff176 !important;
}

.yellow-400-bg {
  background-color: #ffee58 !important;
}

.yellow-400 {
  background-color: #ffee58 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.yellow-400[disabled] {
  background-color: rgba(255, 238, 88, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.yellow-400-fg {
  color: #ffee58 !important;
}

.yellow-400-border {
  border-color: #ffee58 !important;
}

.yellow-400-border-top {
  border-top-color: #ffee58 !important;
}

.yellow-400-border-right {
  border-right-color: #ffee58 !important;
}

.yellow-400-border-bottom {
  border-bottom-color: #ffee58 !important;
}

.yellow-400-border-left {
  border-left-color: #ffee58 !important;
}

.yellow-500-bg {
  background-color: #ffeb3b !important;
}

.yellow-500 {
  background-color: #ffeb3b !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.yellow-500[disabled] {
  background-color: rgba(255, 235, 59, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.yellow-500-fg {
  color: #ffeb3b !important;
}

.yellow-500-border {
  border-color: #ffeb3b !important;
}

.yellow-500-border-top {
  border-top-color: #ffeb3b !important;
}

.yellow-500-border-right {
  border-right-color: #ffeb3b !important;
}

.yellow-500-border-bottom {
  border-bottom-color: #ffeb3b !important;
}

.yellow-500-border-left {
  border-left-color: #ffeb3b !important;
}

.yellow-bg {
  background-color: #ffeb3b !important;
}

.yellow {
  background-color: #ffeb3b !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.yellow[disabled] {
  background-color: rgba(255, 235, 59, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.yellow-fg {
  color: #ffeb3b !important;
}

.yellow-border {
  border-color: #ffeb3b !important;
}

.yellow-border-top {
  border-top-color: #ffeb3b !important;
}

.yellow-border-right {
  border-right-color: #ffeb3b !important;
}

.yellow-border-bottom {
  border-bottom-color: #ffeb3b !important;
}

.yellow-border-left {
  border-left-color: #ffeb3b !important;
}

.yellow-600-bg {
  background-color: #fdd835 !important;
}

.yellow-600 {
  background-color: #fdd835 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.yellow-600[disabled] {
  background-color: rgba(253, 216, 53, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.yellow-600-fg {
  color: #fdd835 !important;
}

.yellow-600-border {
  border-color: #fdd835 !important;
}

.yellow-600-border-top {
  border-top-color: #fdd835 !important;
}

.yellow-600-border-right {
  border-right-color: #fdd835 !important;
}

.yellow-600-border-bottom {
  border-bottom-color: #fdd835 !important;
}

.yellow-600-border-left {
  border-left-color: #fdd835 !important;
}

.yellow-700-bg {
  background-color: #fbc02d !important;
}

.yellow-700 {
  background-color: #fbc02d !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.yellow-700[disabled] {
  background-color: rgba(251, 192, 45, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.yellow-700-fg {
  color: #fbc02d !important;
}

.yellow-700-border {
  border-color: #fbc02d !important;
}

.yellow-700-border-top {
  border-top-color: #fbc02d !important;
}

.yellow-700-border-right {
  border-right-color: #fbc02d !important;
}

.yellow-700-border-bottom {
  border-bottom-color: #fbc02d !important;
}

.yellow-700-border-left {
  border-left-color: #fbc02d !important;
}

.yellow-800-bg {
  background-color: #f9a825 !important;
}

.yellow-800 {
  background-color: #f9a825 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.yellow-800[disabled] {
  background-color: rgba(249, 168, 37, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.yellow-800-fg {
  color: #f9a825 !important;
}

.yellow-800-border {
  border-color: #f9a825 !important;
}

.yellow-800-border-top {
  border-top-color: #f9a825 !important;
}

.yellow-800-border-right {
  border-right-color: #f9a825 !important;
}

.yellow-800-border-bottom {
  border-bottom-color: #f9a825 !important;
}

.yellow-800-border-left {
  border-left-color: #f9a825 !important;
}

.yellow-900-bg {
  background-color: #f57f17 !important;
}

.yellow-900 {
  background-color: #f57f17 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.yellow-900[disabled] {
  background-color: rgba(245, 127, 23, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.yellow-900-fg {
  color: #f57f17 !important;
}

.yellow-900-border {
  border-color: #f57f17 !important;
}

.yellow-900-border-top {
  border-top-color: #f57f17 !important;
}

.yellow-900-border-right {
  border-right-color: #f57f17 !important;
}

.yellow-900-border-bottom {
  border-bottom-color: #f57f17 !important;
}

.yellow-900-border-left {
  border-left-color: #f57f17 !important;
}

.yellow-A100-bg {
  background-color: #ffff8d !important;
}

.yellow-A100 {
  background-color: #ffff8d !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.yellow-A100[disabled] {
  background-color: rgba(255, 255, 141, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.yellow-A100-fg {
  color: #ffff8d !important;
}

.yellow-A100-border {
  border-color: #ffff8d !important;
}

.yellow-A100-border-top {
  border-top-color: #ffff8d !important;
}

.yellow-A100-border-right {
  border-right-color: #ffff8d !important;
}

.yellow-A100-border-bottom {
  border-bottom-color: #ffff8d !important;
}

.yellow-A100-border-left {
  border-left-color: #ffff8d !important;
}

.yellow-A200-bg {
  background-color: #ffff00 !important;
}

.yellow-A200 {
  background-color: #ffff00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.yellow-A200[disabled] {
  background-color: rgba(255, 255, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.yellow-A200-fg {
  color: #ffff00 !important;
}

.yellow-A200-border {
  border-color: #ffff00 !important;
}

.yellow-A200-border-top {
  border-top-color: #ffff00 !important;
}

.yellow-A200-border-right {
  border-right-color: #ffff00 !important;
}

.yellow-A200-border-bottom {
  border-bottom-color: #ffff00 !important;
}

.yellow-A200-border-left {
  border-left-color: #ffff00 !important;
}

.yellow-A400-bg {
  background-color: #ffea00 !important;
}

.yellow-A400 {
  background-color: #ffea00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.yellow-A400[disabled] {
  background-color: rgba(255, 234, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.yellow-A400-fg {
  color: #ffea00 !important;
}

.yellow-A400-border {
  border-color: #ffea00 !important;
}

.yellow-A400-border-top {
  border-top-color: #ffea00 !important;
}

.yellow-A400-border-right {
  border-right-color: #ffea00 !important;
}

.yellow-A400-border-bottom {
  border-bottom-color: #ffea00 !important;
}

.yellow-A400-border-left {
  border-left-color: #ffea00 !important;
}

.yellow-A700-bg {
  background-color: #ffd600 !important;
}

.yellow-A700 {
  background-color: #ffd600 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.yellow-A700[disabled] {
  background-color: rgba(255, 214, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.yellow-A700-fg {
  color: #ffd600 !important;
}

.yellow-A700-border {
  border-color: #ffd600 !important;
}

.yellow-A700-border-top {
  border-top-color: #ffd600 !important;
}

.yellow-A700-border-right {
  border-right-color: #ffd600 !important;
}

.yellow-A700-border-bottom {
  border-bottom-color: #ffd600 !important;
}

.yellow-A700-border-left {
  border-left-color: #ffd600 !important;
}

.amber-50-bg {
  background-color: #fff8e1 !important;
}

.amber-50 {
  background-color: #fff8e1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.amber-50[disabled] {
  background-color: rgba(255, 248, 225, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.amber-50-fg {
  color: #fff8e1 !important;
}

.amber-50-border {
  border-color: #fff8e1 !important;
}

.amber-50-border-top {
  border-top-color: #fff8e1 !important;
}

.amber-50-border-right {
  border-right-color: #fff8e1 !important;
}

.amber-50-border-bottom {
  border-bottom-color: #fff8e1 !important;
}

.amber-50-border-left {
  border-left-color: #fff8e1 !important;
}

.amber-100-bg {
  background-color: #ffecb3 !important;
}

.amber-100 {
  background-color: #ffecb3 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.amber-100[disabled] {
  background-color: rgba(255, 236, 179, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.amber-100-fg {
  color: #ffecb3 !important;
}

.amber-100-border {
  border-color: #ffecb3 !important;
}

.amber-100-border-top {
  border-top-color: #ffecb3 !important;
}

.amber-100-border-right {
  border-right-color: #ffecb3 !important;
}

.amber-100-border-bottom {
  border-bottom-color: #ffecb3 !important;
}

.amber-100-border-left {
  border-left-color: #ffecb3 !important;
}

.amber-200-bg {
  background-color: #ffe082 !important;
}

.amber-200 {
  background-color: #ffe082 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.amber-200[disabled] {
  background-color: rgba(255, 224, 130, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.amber-200-fg {
  color: #ffe082 !important;
}

.amber-200-border {
  border-color: #ffe082 !important;
}

.amber-200-border-top {
  border-top-color: #ffe082 !important;
}

.amber-200-border-right {
  border-right-color: #ffe082 !important;
}

.amber-200-border-bottom {
  border-bottom-color: #ffe082 !important;
}

.amber-200-border-left {
  border-left-color: #ffe082 !important;
}

.amber-300-bg {
  background-color: #ffd54f !important;
}

.amber-300 {
  background-color: #ffd54f !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.amber-300[disabled] {
  background-color: rgba(255, 213, 79, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.amber-300-fg {
  color: #ffd54f !important;
}

.amber-300-border {
  border-color: #ffd54f !important;
}

.amber-300-border-top {
  border-top-color: #ffd54f !important;
}

.amber-300-border-right {
  border-right-color: #ffd54f !important;
}

.amber-300-border-bottom {
  border-bottom-color: #ffd54f !important;
}

.amber-300-border-left {
  border-left-color: #ffd54f !important;
}

.amber-400-bg {
  background-color: #ffca28 !important;
}

.amber-400 {
  background-color: #ffca28 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.amber-400[disabled] {
  background-color: rgba(255, 202, 40, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.amber-400-fg {
  color: #ffca28 !important;
}

.amber-400-border {
  border-color: #ffca28 !important;
}

.amber-400-border-top {
  border-top-color: #ffca28 !important;
}

.amber-400-border-right {
  border-right-color: #ffca28 !important;
}

.amber-400-border-bottom {
  border-bottom-color: #ffca28 !important;
}

.amber-400-border-left {
  border-left-color: #ffca28 !important;
}

.amber-500-bg {
  background-color: #ffc107 !important;
}

.amber-500 {
  background-color: #ffc107 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.amber-500[disabled] {
  background-color: rgba(255, 193, 7, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.amber-500-fg {
  color: #ffc107 !important;
}

.amber-500-border {
  border-color: #ffc107 !important;
}

.amber-500-border-top {
  border-top-color: #ffc107 !important;
}

.amber-500-border-right {
  border-right-color: #ffc107 !important;
}

.amber-500-border-bottom {
  border-bottom-color: #ffc107 !important;
}

.amber-500-border-left {
  border-left-color: #ffc107 !important;
}

.amber-bg {
  background-color: #ffc107 !important;
}

.amber {
  background-color: #ffc107 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.amber[disabled] {
  background-color: rgba(255, 193, 7, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.amber-fg {
  color: #ffc107 !important;
}

.amber-border {
  border-color: #ffc107 !important;
}

.amber-border-top {
  border-top-color: #ffc107 !important;
}

.amber-border-right {
  border-right-color: #ffc107 !important;
}

.amber-border-bottom {
  border-bottom-color: #ffc107 !important;
}

.amber-border-left {
  border-left-color: #ffc107 !important;
}

.amber-600-bg {
  background-color: #ffb300 !important;
}

.amber-600 {
  background-color: #ffb300 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.amber-600[disabled] {
  background-color: rgba(255, 179, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.amber-600-fg {
  color: #ffb300 !important;
}

.amber-600-border {
  border-color: #ffb300 !important;
}

.amber-600-border-top {
  border-top-color: #ffb300 !important;
}

.amber-600-border-right {
  border-right-color: #ffb300 !important;
}

.amber-600-border-bottom {
  border-bottom-color: #ffb300 !important;
}

.amber-600-border-left {
  border-left-color: #ffb300 !important;
}

.amber-700-bg {
  background-color: #ffa000 !important;
}

.amber-700 {
  background-color: #ffa000 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.amber-700[disabled] {
  background-color: rgba(255, 160, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.amber-700-fg {
  color: #ffa000 !important;
}

.amber-700-border {
  border-color: #ffa000 !important;
}

.amber-700-border-top {
  border-top-color: #ffa000 !important;
}

.amber-700-border-right {
  border-right-color: #ffa000 !important;
}

.amber-700-border-bottom {
  border-bottom-color: #ffa000 !important;
}

.amber-700-border-left {
  border-left-color: #ffa000 !important;
}

.amber-800-bg {
  background-color: #ff8f00 !important;
}

.amber-800 {
  background-color: #ff8f00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.amber-800[disabled] {
  background-color: rgba(255, 143, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.amber-800-fg {
  color: #ff8f00 !important;
}

.amber-800-border {
  border-color: #ff8f00 !important;
}

.amber-800-border-top {
  border-top-color: #ff8f00 !important;
}

.amber-800-border-right {
  border-right-color: #ff8f00 !important;
}

.amber-800-border-bottom {
  border-bottom-color: #ff8f00 !important;
}

.amber-800-border-left {
  border-left-color: #ff8f00 !important;
}

.amber-900-bg {
  background-color: #ff6f00 !important;
}

.amber-900 {
  background-color: #ff6f00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.amber-900[disabled] {
  background-color: rgba(255, 111, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.amber-900-fg {
  color: #ff6f00 !important;
}

.amber-900-border {
  border-color: #ff6f00 !important;
}

.amber-900-border-top {
  border-top-color: #ff6f00 !important;
}

.amber-900-border-right {
  border-right-color: #ff6f00 !important;
}

.amber-900-border-bottom {
  border-bottom-color: #ff6f00 !important;
}

.amber-900-border-left {
  border-left-color: #ff6f00 !important;
}

.amber-A100-bg {
  background-color: #ffe57f !important;
}

.amber-A100 {
  background-color: #ffe57f !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.amber-A100[disabled] {
  background-color: rgba(255, 229, 127, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.amber-A100-fg {
  color: #ffe57f !important;
}

.amber-A100-border {
  border-color: #ffe57f !important;
}

.amber-A100-border-top {
  border-top-color: #ffe57f !important;
}

.amber-A100-border-right {
  border-right-color: #ffe57f !important;
}

.amber-A100-border-bottom {
  border-bottom-color: #ffe57f !important;
}

.amber-A100-border-left {
  border-left-color: #ffe57f !important;
}

.amber-A200-bg {
  background-color: #ffd740 !important;
}

.amber-A200 {
  background-color: #ffd740 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.amber-A200[disabled] {
  background-color: rgba(255, 215, 64, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.amber-A200-fg {
  color: #ffd740 !important;
}

.amber-A200-border {
  border-color: #ffd740 !important;
}

.amber-A200-border-top {
  border-top-color: #ffd740 !important;
}

.amber-A200-border-right {
  border-right-color: #ffd740 !important;
}

.amber-A200-border-bottom {
  border-bottom-color: #ffd740 !important;
}

.amber-A200-border-left {
  border-left-color: #ffd740 !important;
}

.amber-A400-bg {
  background-color: #ffc400 !important;
}

.amber-A400 {
  background-color: #ffc400 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.amber-A400[disabled] {
  background-color: rgba(255, 196, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.amber-A400-fg {
  color: #ffc400 !important;
}

.amber-A400-border {
  border-color: #ffc400 !important;
}

.amber-A400-border-top {
  border-top-color: #ffc400 !important;
}

.amber-A400-border-right {
  border-right-color: #ffc400 !important;
}

.amber-A400-border-bottom {
  border-bottom-color: #ffc400 !important;
}

.amber-A400-border-left {
  border-left-color: #ffc400 !important;
}

.amber-A700-bg {
  background-color: #ffab00 !important;
}

.amber-A700 {
  background-color: #ffab00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.amber-A700[disabled] {
  background-color: rgba(255, 171, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.amber-A700-fg {
  color: #ffab00 !important;
}

.amber-A700-border {
  border-color: #ffab00 !important;
}

.amber-A700-border-top {
  border-top-color: #ffab00 !important;
}

.amber-A700-border-right {
  border-right-color: #ffab00 !important;
}

.amber-A700-border-bottom {
  border-bottom-color: #ffab00 !important;
}

.amber-A700-border-left {
  border-left-color: #ffab00 !important;
}

.orange-50-bg {
  background-color: #fff3e0 !important;
}

.orange-50 {
  background-color: #fff3e0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.orange-50[disabled] {
  background-color: rgba(255, 243, 224, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.orange-50-fg {
  color: #fff3e0 !important;
}

.orange-50-border {
  border-color: #fff3e0 !important;
}

.orange-50-border-top {
  border-top-color: #fff3e0 !important;
}

.orange-50-border-right {
  border-right-color: #fff3e0 !important;
}

.orange-50-border-bottom {
  border-bottom-color: #fff3e0 !important;
}

.orange-50-border-left {
  border-left-color: #fff3e0 !important;
}

.orange-100-bg {
  background-color: #ffe0b2 !important;
}

.orange-100 {
  background-color: #ffe0b2 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.orange-100[disabled] {
  background-color: rgba(255, 224, 178, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.orange-100-fg {
  color: #ffe0b2 !important;
}

.orange-100-border {
  border-color: #ffe0b2 !important;
}

.orange-100-border-top {
  border-top-color: #ffe0b2 !important;
}

.orange-100-border-right {
  border-right-color: #ffe0b2 !important;
}

.orange-100-border-bottom {
  border-bottom-color: #ffe0b2 !important;
}

.orange-100-border-left {
  border-left-color: #ffe0b2 !important;
}

.orange-200-bg {
  background-color: #ffcc80 !important;
}

.orange-200 {
  background-color: #ffcc80 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.orange-200[disabled] {
  background-color: rgba(255, 204, 128, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.orange-200-fg {
  color: #ffcc80 !important;
}

.orange-200-border {
  border-color: #ffcc80 !important;
}

.orange-200-border-top {
  border-top-color: #ffcc80 !important;
}

.orange-200-border-right {
  border-right-color: #ffcc80 !important;
}

.orange-200-border-bottom {
  border-bottom-color: #ffcc80 !important;
}

.orange-200-border-left {
  border-left-color: #ffcc80 !important;
}

.orange-300-bg {
  background-color: #ffb74d !important;
}

.orange-300 {
  background-color: #ffb74d !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.orange-300[disabled] {
  background-color: rgba(255, 183, 77, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.orange-300-fg {
  color: #ffb74d !important;
}

.orange-300-border {
  border-color: #ffb74d !important;
}

.orange-300-border-top {
  border-top-color: #ffb74d !important;
}

.orange-300-border-right {
  border-right-color: #ffb74d !important;
}

.orange-300-border-bottom {
  border-bottom-color: #ffb74d !important;
}

.orange-300-border-left {
  border-left-color: #ffb74d !important;
}

.orange-400-bg {
  background-color: #ffa726 !important;
}

.orange-400 {
  background-color: #ffa726 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.orange-400[disabled] {
  background-color: rgba(255, 167, 38, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.orange-400-fg {
  color: #ffa726 !important;
}

.orange-400-border {
  border-color: #ffa726 !important;
}

.orange-400-border-top {
  border-top-color: #ffa726 !important;
}

.orange-400-border-right {
  border-right-color: #ffa726 !important;
}

.orange-400-border-bottom {
  border-bottom-color: #ffa726 !important;
}

.orange-400-border-left {
  border-left-color: #ffa726 !important;
}

.orange-500-bg {
  background-color: #ff9800 !important;
}

.orange-500 {
  background-color: #ff9800 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.orange-500[disabled] {
  background-color: rgba(255, 152, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.orange-500-fg {
  color: #ff9800 !important;
}

.orange-500-border {
  border-color: #ff9800 !important;
}

.orange-500-border-top {
  border-top-color: #ff9800 !important;
}

.orange-500-border-right {
  border-right-color: #ff9800 !important;
}

.orange-500-border-bottom {
  border-bottom-color: #ff9800 !important;
}

.orange-500-border-left {
  border-left-color: #ff9800 !important;
}

.orange-bg {
  background-color: #ff9800 !important;
}

.orange {
  background-color: #ff9800 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.orange[disabled] {
  background-color: rgba(255, 152, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.orange-fg {
  color: #ff9800 !important;
}

.orange-border {
  border-color: #ff9800 !important;
}

.orange-border-top {
  border-top-color: #ff9800 !important;
}

.orange-border-right {
  border-right-color: #ff9800 !important;
}

.orange-border-bottom {
  border-bottom-color: #ff9800 !important;
}

.orange-border-left {
  border-left-color: #ff9800 !important;
}

.orange-600-bg {
  background-color: #fb8c00 !important;
}

.orange-600 {
  background-color: #fb8c00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.orange-600[disabled] {
  background-color: rgba(251, 140, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.orange-600-fg {
  color: #fb8c00 !important;
}

.orange-600-border {
  border-color: #fb8c00 !important;
}

.orange-600-border-top {
  border-top-color: #fb8c00 !important;
}

.orange-600-border-right {
  border-right-color: #fb8c00 !important;
}

.orange-600-border-bottom {
  border-bottom-color: #fb8c00 !important;
}

.orange-600-border-left {
  border-left-color: #fb8c00 !important;
}

.orange-700-bg {
  background-color: #f57c00 !important;
}

.orange-700 {
  background-color: #f57c00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.orange-700[disabled] {
  background-color: rgba(245, 124, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.orange-700-fg {
  color: #f57c00 !important;
}

.orange-700-border {
  border-color: #f57c00 !important;
}

.orange-700-border-top {
  border-top-color: #f57c00 !important;
}

.orange-700-border-right {
  border-right-color: #f57c00 !important;
}

.orange-700-border-bottom {
  border-bottom-color: #f57c00 !important;
}

.orange-700-border-left {
  border-left-color: #f57c00 !important;
}

.orange-800-bg {
  background-color: #ef6c00 !important;
}

.orange-800 {
  background-color: #ef6c00 !important;
  color: white !important;
}
.orange-800[disabled] {
  background-color: rgba(239, 108, 0, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.orange-800-fg {
  color: #ef6c00 !important;
}

.orange-800-border {
  border-color: #ef6c00 !important;
}

.orange-800-border-top {
  border-top-color: #ef6c00 !important;
}

.orange-800-border-right {
  border-right-color: #ef6c00 !important;
}

.orange-800-border-bottom {
  border-bottom-color: #ef6c00 !important;
}

.orange-800-border-left {
  border-left-color: #ef6c00 !important;
}

.orange-900-bg {
  background-color: #e65100 !important;
}

.orange-900 {
  background-color: #e65100 !important;
  color: white !important;
}
.orange-900[disabled] {
  background-color: rgba(230, 81, 0, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.orange-900-fg {
  color: #e65100 !important;
}

.orange-900-border {
  border-color: #e65100 !important;
}

.orange-900-border-top {
  border-top-color: #e65100 !important;
}

.orange-900-border-right {
  border-right-color: #e65100 !important;
}

.orange-900-border-bottom {
  border-bottom-color: #e65100 !important;
}

.orange-900-border-left {
  border-left-color: #e65100 !important;
}

.orange-A100-bg {
  background-color: #ffd180 !important;
}

.orange-A100 {
  background-color: #ffd180 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.orange-A100[disabled] {
  background-color: rgba(255, 209, 128, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.orange-A100-fg {
  color: #ffd180 !important;
}

.orange-A100-border {
  border-color: #ffd180 !important;
}

.orange-A100-border-top {
  border-top-color: #ffd180 !important;
}

.orange-A100-border-right {
  border-right-color: #ffd180 !important;
}

.orange-A100-border-bottom {
  border-bottom-color: #ffd180 !important;
}

.orange-A100-border-left {
  border-left-color: #ffd180 !important;
}

.orange-A200-bg {
  background-color: #ffab40 !important;
}

.orange-A200 {
  background-color: #ffab40 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.orange-A200[disabled] {
  background-color: rgba(255, 171, 64, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.orange-A200-fg {
  color: #ffab40 !important;
}

.orange-A200-border {
  border-color: #ffab40 !important;
}

.orange-A200-border-top {
  border-top-color: #ffab40 !important;
}

.orange-A200-border-right {
  border-right-color: #ffab40 !important;
}

.orange-A200-border-bottom {
  border-bottom-color: #ffab40 !important;
}

.orange-A200-border-left {
  border-left-color: #ffab40 !important;
}

.orange-A400-bg {
  background-color: #ff9100 !important;
}

.orange-A400 {
  background-color: #ff9100 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.orange-A400[disabled] {
  background-color: rgba(255, 145, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.orange-A400-fg {
  color: #ff9100 !important;
}

.orange-A400-border {
  border-color: #ff9100 !important;
}

.orange-A400-border-top {
  border-top-color: #ff9100 !important;
}

.orange-A400-border-right {
  border-right-color: #ff9100 !important;
}

.orange-A400-border-bottom {
  border-bottom-color: #ff9100 !important;
}

.orange-A400-border-left {
  border-left-color: #ff9100 !important;
}

.orange-A700-bg {
  background-color: #ff6d00 !important;
}

.orange-A700 {
  background-color: #ff6d00 !important;
  color: black !important;
}
.orange-A700[disabled] {
  background-color: rgba(255, 109, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.orange-A700-fg {
  color: #ff6d00 !important;
}

.orange-A700-border {
  border-color: #ff6d00 !important;
}

.orange-A700-border-top {
  border-top-color: #ff6d00 !important;
}

.orange-A700-border-right {
  border-right-color: #ff6d00 !important;
}

.orange-A700-border-bottom {
  border-bottom-color: #ff6d00 !important;
}

.orange-A700-border-left {
  border-left-color: #ff6d00 !important;
}

.deep-orange-50-bg {
  background-color: #fbe9e7 !important;
}

.deep-orange-50 {
  background-color: #fbe9e7 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.deep-orange-50[disabled] {
  background-color: rgba(251, 233, 231, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.deep-orange-50-fg {
  color: #fbe9e7 !important;
}

.deep-orange-50-border {
  border-color: #fbe9e7 !important;
}

.deep-orange-50-border-top {
  border-top-color: #fbe9e7 !important;
}

.deep-orange-50-border-right {
  border-right-color: #fbe9e7 !important;
}

.deep-orange-50-border-bottom {
  border-bottom-color: #fbe9e7 !important;
}

.deep-orange-50-border-left {
  border-left-color: #fbe9e7 !important;
}

.deep-orange-100-bg {
  background-color: #ffccbc !important;
}

.deep-orange-100 {
  background-color: #ffccbc !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.deep-orange-100[disabled] {
  background-color: rgba(255, 204, 188, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.deep-orange-100-fg {
  color: #ffccbc !important;
}

.deep-orange-100-border {
  border-color: #ffccbc !important;
}

.deep-orange-100-border-top {
  border-top-color: #ffccbc !important;
}

.deep-orange-100-border-right {
  border-right-color: #ffccbc !important;
}

.deep-orange-100-border-bottom {
  border-bottom-color: #ffccbc !important;
}

.deep-orange-100-border-left {
  border-left-color: #ffccbc !important;
}

.deep-orange-200-bg {
  background-color: #ffab91 !important;
}

.deep-orange-200 {
  background-color: #ffab91 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.deep-orange-200[disabled] {
  background-color: rgba(255, 171, 145, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.deep-orange-200-fg {
  color: #ffab91 !important;
}

.deep-orange-200-border {
  border-color: #ffab91 !important;
}

.deep-orange-200-border-top {
  border-top-color: #ffab91 !important;
}

.deep-orange-200-border-right {
  border-right-color: #ffab91 !important;
}

.deep-orange-200-border-bottom {
  border-bottom-color: #ffab91 !important;
}

.deep-orange-200-border-left {
  border-left-color: #ffab91 !important;
}

.deep-orange-300-bg {
  background-color: #ff8a65 !important;
}

.deep-orange-300 {
  background-color: #ff8a65 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.deep-orange-300[disabled] {
  background-color: rgba(255, 138, 101, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.deep-orange-300-fg {
  color: #ff8a65 !important;
}

.deep-orange-300-border {
  border-color: #ff8a65 !important;
}

.deep-orange-300-border-top {
  border-top-color: #ff8a65 !important;
}

.deep-orange-300-border-right {
  border-right-color: #ff8a65 !important;
}

.deep-orange-300-border-bottom {
  border-bottom-color: #ff8a65 !important;
}

.deep-orange-300-border-left {
  border-left-color: #ff8a65 !important;
}

.deep-orange-400-bg {
  background-color: #ff7043 !important;
}

.deep-orange-400 {
  background-color: #ff7043 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.deep-orange-400[disabled] {
  background-color: rgba(255, 112, 67, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.deep-orange-400-fg {
  color: #ff7043 !important;
}

.deep-orange-400-border {
  border-color: #ff7043 !important;
}

.deep-orange-400-border-top {
  border-top-color: #ff7043 !important;
}

.deep-orange-400-border-right {
  border-right-color: #ff7043 !important;
}

.deep-orange-400-border-bottom {
  border-bottom-color: #ff7043 !important;
}

.deep-orange-400-border-left {
  border-left-color: #ff7043 !important;
}

.deep-orange-500-bg {
  background-color: #ff5722 !important;
}

.deep-orange-500 {
  background-color: #ff5722 !important;
  color: white !important;
}
.deep-orange-500[disabled] {
  background-color: rgba(255, 87, 34, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.deep-orange-500-fg {
  color: #ff5722 !important;
}

.deep-orange-500-border {
  border-color: #ff5722 !important;
}

.deep-orange-500-border-top {
  border-top-color: #ff5722 !important;
}

.deep-orange-500-border-right {
  border-right-color: #ff5722 !important;
}

.deep-orange-500-border-bottom {
  border-bottom-color: #ff5722 !important;
}

.deep-orange-500-border-left {
  border-left-color: #ff5722 !important;
}

.deep-orange-bg {
  background-color: #ff5722 !important;
}

.deep-orange {
  background-color: #ff5722 !important;
  color: white !important;
}
.deep-orange[disabled] {
  background-color: rgba(255, 87, 34, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.deep-orange-fg {
  color: #ff5722 !important;
}

.deep-orange-border {
  border-color: #ff5722 !important;
}

.deep-orange-border-top {
  border-top-color: #ff5722 !important;
}

.deep-orange-border-right {
  border-right-color: #ff5722 !important;
}

.deep-orange-border-bottom {
  border-bottom-color: #ff5722 !important;
}

.deep-orange-border-left {
  border-left-color: #ff5722 !important;
}

.deep-orange-600-bg {
  background-color: #f4511e !important;
}

.deep-orange-600 {
  background-color: #f4511e !important;
  color: white !important;
}
.deep-orange-600[disabled] {
  background-color: rgba(244, 81, 30, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.deep-orange-600-fg {
  color: #f4511e !important;
}

.deep-orange-600-border {
  border-color: #f4511e !important;
}

.deep-orange-600-border-top {
  border-top-color: #f4511e !important;
}

.deep-orange-600-border-right {
  border-right-color: #f4511e !important;
}

.deep-orange-600-border-bottom {
  border-bottom-color: #f4511e !important;
}

.deep-orange-600-border-left {
  border-left-color: #f4511e !important;
}

.deep-orange-700-bg {
  background-color: #e64a19 !important;
}

.deep-orange-700 {
  background-color: #e64a19 !important;
  color: white !important;
}
.deep-orange-700[disabled] {
  background-color: rgba(230, 74, 25, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.deep-orange-700-fg {
  color: #e64a19 !important;
}

.deep-orange-700-border {
  border-color: #e64a19 !important;
}

.deep-orange-700-border-top {
  border-top-color: #e64a19 !important;
}

.deep-orange-700-border-right {
  border-right-color: #e64a19 !important;
}

.deep-orange-700-border-bottom {
  border-bottom-color: #e64a19 !important;
}

.deep-orange-700-border-left {
  border-left-color: #e64a19 !important;
}

.deep-orange-800-bg {
  background-color: #d84315 !important;
}

.deep-orange-800 {
  background-color: #d84315 !important;
  color: white !important;
}
.deep-orange-800[disabled] {
  background-color: rgba(216, 67, 21, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.deep-orange-800-fg {
  color: #d84315 !important;
}

.deep-orange-800-border {
  border-color: #d84315 !important;
}

.deep-orange-800-border-top {
  border-top-color: #d84315 !important;
}

.deep-orange-800-border-right {
  border-right-color: #d84315 !important;
}

.deep-orange-800-border-bottom {
  border-bottom-color: #d84315 !important;
}

.deep-orange-800-border-left {
  border-left-color: #d84315 !important;
}

.deep-orange-900-bg {
  background-color: #bf360c !important;
}

.deep-orange-900 {
  background-color: #bf360c !important;
  color: white !important;
}
.deep-orange-900[disabled] {
  background-color: rgba(191, 54, 12, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.deep-orange-900-fg {
  color: #bf360c !important;
}

.deep-orange-900-border {
  border-color: #bf360c !important;
}

.deep-orange-900-border-top {
  border-top-color: #bf360c !important;
}

.deep-orange-900-border-right {
  border-right-color: #bf360c !important;
}

.deep-orange-900-border-bottom {
  border-bottom-color: #bf360c !important;
}

.deep-orange-900-border-left {
  border-left-color: #bf360c !important;
}

.deep-orange-A100-bg {
  background-color: #ff9e80 !important;
}

.deep-orange-A100 {
  background-color: #ff9e80 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.deep-orange-A100[disabled] {
  background-color: rgba(255, 158, 128, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.deep-orange-A100-fg {
  color: #ff9e80 !important;
}

.deep-orange-A100-border {
  border-color: #ff9e80 !important;
}

.deep-orange-A100-border-top {
  border-top-color: #ff9e80 !important;
}

.deep-orange-A100-border-right {
  border-right-color: #ff9e80 !important;
}

.deep-orange-A100-border-bottom {
  border-bottom-color: #ff9e80 !important;
}

.deep-orange-A100-border-left {
  border-left-color: #ff9e80 !important;
}

.deep-orange-A200-bg {
  background-color: #ff6e40 !important;
}

.deep-orange-A200 {
  background-color: #ff6e40 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.deep-orange-A200[disabled] {
  background-color: rgba(255, 110, 64, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.deep-orange-A200-fg {
  color: #ff6e40 !important;
}

.deep-orange-A200-border {
  border-color: #ff6e40 !important;
}

.deep-orange-A200-border-top {
  border-top-color: #ff6e40 !important;
}

.deep-orange-A200-border-right {
  border-right-color: #ff6e40 !important;
}

.deep-orange-A200-border-bottom {
  border-bottom-color: #ff6e40 !important;
}

.deep-orange-A200-border-left {
  border-left-color: #ff6e40 !important;
}

.deep-orange-A400-bg {
  background-color: #ff3d00 !important;
}

.deep-orange-A400 {
  background-color: #ff3d00 !important;
  color: white !important;
}
.deep-orange-A400[disabled] {
  background-color: rgba(255, 61, 0, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.deep-orange-A400-fg {
  color: #ff3d00 !important;
}

.deep-orange-A400-border {
  border-color: #ff3d00 !important;
}

.deep-orange-A400-border-top {
  border-top-color: #ff3d00 !important;
}

.deep-orange-A400-border-right {
  border-right-color: #ff3d00 !important;
}

.deep-orange-A400-border-bottom {
  border-bottom-color: #ff3d00 !important;
}

.deep-orange-A400-border-left {
  border-left-color: #ff3d00 !important;
}

.deep-orange-A700-bg {
  background-color: #dd2c00 !important;
}

.deep-orange-A700 {
  background-color: #dd2c00 !important;
  color: white !important;
}
.deep-orange-A700[disabled] {
  background-color: rgba(221, 44, 0, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.deep-orange-A700-fg {
  color: #dd2c00 !important;
}

.deep-orange-A700-border {
  border-color: #dd2c00 !important;
}

.deep-orange-A700-border-top {
  border-top-color: #dd2c00 !important;
}

.deep-orange-A700-border-right {
  border-right-color: #dd2c00 !important;
}

.deep-orange-A700-border-bottom {
  border-bottom-color: #dd2c00 !important;
}

.deep-orange-A700-border-left {
  border-left-color: #dd2c00 !important;
}

.brown-50-bg {
  background-color: #efebe9 !important;
}

.brown-50 {
  background-color: #efebe9 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.brown-50[disabled] {
  background-color: rgba(239, 235, 233, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.brown-50-fg {
  color: #efebe9 !important;
}

.brown-50-border {
  border-color: #efebe9 !important;
}

.brown-50-border-top {
  border-top-color: #efebe9 !important;
}

.brown-50-border-right {
  border-right-color: #efebe9 !important;
}

.brown-50-border-bottom {
  border-bottom-color: #efebe9 !important;
}

.brown-50-border-left {
  border-left-color: #efebe9 !important;
}

.brown-100-bg {
  background-color: #d7ccc8 !important;
}

.brown-100 {
  background-color: #d7ccc8 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.brown-100[disabled] {
  background-color: rgba(215, 204, 200, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.brown-100-fg {
  color: #d7ccc8 !important;
}

.brown-100-border {
  border-color: #d7ccc8 !important;
}

.brown-100-border-top {
  border-top-color: #d7ccc8 !important;
}

.brown-100-border-right {
  border-right-color: #d7ccc8 !important;
}

.brown-100-border-bottom {
  border-bottom-color: #d7ccc8 !important;
}

.brown-100-border-left {
  border-left-color: #d7ccc8 !important;
}

.brown-200-bg {
  background-color: #bcaaa4 !important;
}

.brown-200 {
  background-color: #bcaaa4 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.brown-200[disabled] {
  background-color: rgba(188, 170, 164, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.brown-200-fg {
  color: #bcaaa4 !important;
}

.brown-200-border {
  border-color: #bcaaa4 !important;
}

.brown-200-border-top {
  border-top-color: #bcaaa4 !important;
}

.brown-200-border-right {
  border-right-color: #bcaaa4 !important;
}

.brown-200-border-bottom {
  border-bottom-color: #bcaaa4 !important;
}

.brown-200-border-left {
  border-left-color: #bcaaa4 !important;
}

.brown-300-bg {
  background-color: #a1887f !important;
}

.brown-300 {
  background-color: #a1887f !important;
  color: white !important;
}
.brown-300[disabled] {
  background-color: rgba(161, 136, 127, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.brown-300-fg {
  color: #a1887f !important;
}

.brown-300-border {
  border-color: #a1887f !important;
}

.brown-300-border-top {
  border-top-color: #a1887f !important;
}

.brown-300-border-right {
  border-right-color: #a1887f !important;
}

.brown-300-border-bottom {
  border-bottom-color: #a1887f !important;
}

.brown-300-border-left {
  border-left-color: #a1887f !important;
}

.brown-400-bg {
  background-color: #8d6e63 !important;
}

.brown-400 {
  background-color: #8d6e63 !important;
  color: white !important;
}
.brown-400[disabled] {
  background-color: rgba(141, 110, 99, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.brown-400-fg {
  color: #8d6e63 !important;
}

.brown-400-border {
  border-color: #8d6e63 !important;
}

.brown-400-border-top {
  border-top-color: #8d6e63 !important;
}

.brown-400-border-right {
  border-right-color: #8d6e63 !important;
}

.brown-400-border-bottom {
  border-bottom-color: #8d6e63 !important;
}

.brown-400-border-left {
  border-left-color: #8d6e63 !important;
}

.brown-500-bg {
  background-color: #795548 !important;
}

.brown-500 {
  background-color: #795548 !important;
  color: white !important;
}
.brown-500[disabled] {
  background-color: rgba(121, 85, 72, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.brown-500-fg {
  color: #795548 !important;
}

.brown-500-border {
  border-color: #795548 !important;
}

.brown-500-border-top {
  border-top-color: #795548 !important;
}

.brown-500-border-right {
  border-right-color: #795548 !important;
}

.brown-500-border-bottom {
  border-bottom-color: #795548 !important;
}

.brown-500-border-left {
  border-left-color: #795548 !important;
}

.brown-bg {
  background-color: #795548 !important;
}

.brown {
  background-color: #795548 !important;
  color: white !important;
}
.brown[disabled] {
  background-color: rgba(121, 85, 72, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.brown-fg {
  color: #795548 !important;
}

.brown-border {
  border-color: #795548 !important;
}

.brown-border-top {
  border-top-color: #795548 !important;
}

.brown-border-right {
  border-right-color: #795548 !important;
}

.brown-border-bottom {
  border-bottom-color: #795548 !important;
}

.brown-border-left {
  border-left-color: #795548 !important;
}

.brown-600-bg {
  background-color: #6d4c41 !important;
}

.brown-600 {
  background-color: #6d4c41 !important;
  color: white !important;
}
.brown-600[disabled] {
  background-color: rgba(109, 76, 65, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.brown-600-fg {
  color: #6d4c41 !important;
}

.brown-600-border {
  border-color: #6d4c41 !important;
}

.brown-600-border-top {
  border-top-color: #6d4c41 !important;
}

.brown-600-border-right {
  border-right-color: #6d4c41 !important;
}

.brown-600-border-bottom {
  border-bottom-color: #6d4c41 !important;
}

.brown-600-border-left {
  border-left-color: #6d4c41 !important;
}

.brown-700-bg {
  background-color: #5d4037 !important;
}

.brown-700 {
  background-color: #5d4037 !important;
  color: white !important;
}
.brown-700[disabled] {
  background-color: rgba(93, 64, 55, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.brown-700-fg {
  color: #5d4037 !important;
}

.brown-700-border {
  border-color: #5d4037 !important;
}

.brown-700-border-top {
  border-top-color: #5d4037 !important;
}

.brown-700-border-right {
  border-right-color: #5d4037 !important;
}

.brown-700-border-bottom {
  border-bottom-color: #5d4037 !important;
}

.brown-700-border-left {
  border-left-color: #5d4037 !important;
}

.brown-800-bg {
  background-color: #4e342e !important;
}

.brown-800 {
  background-color: #4e342e !important;
  color: white !important;
}
.brown-800[disabled] {
  background-color: rgba(78, 52, 46, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.brown-800-fg {
  color: #4e342e !important;
}

.brown-800-border {
  border-color: #4e342e !important;
}

.brown-800-border-top {
  border-top-color: #4e342e !important;
}

.brown-800-border-right {
  border-right-color: #4e342e !important;
}

.brown-800-border-bottom {
  border-bottom-color: #4e342e !important;
}

.brown-800-border-left {
  border-left-color: #4e342e !important;
}

.brown-900-bg {
  background-color: #3e2723 !important;
}

.brown-900 {
  background-color: #3e2723 !important;
  color: white !important;
}
.brown-900[disabled] {
  background-color: rgba(62, 39, 35, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.brown-900-fg {
  color: #3e2723 !important;
}

.brown-900-border {
  border-color: #3e2723 !important;
}

.brown-900-border-top {
  border-top-color: #3e2723 !important;
}

.brown-900-border-right {
  border-right-color: #3e2723 !important;
}

.brown-900-border-bottom {
  border-bottom-color: #3e2723 !important;
}

.brown-900-border-left {
  border-left-color: #3e2723 !important;
}

.brown-A100-bg {
  background-color: #d7ccc8 !important;
}

.brown-A100 {
  background-color: #d7ccc8 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.brown-A100[disabled] {
  background-color: rgba(215, 204, 200, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.brown-A100-fg {
  color: #d7ccc8 !important;
}

.brown-A100-border {
  border-color: #d7ccc8 !important;
}

.brown-A100-border-top {
  border-top-color: #d7ccc8 !important;
}

.brown-A100-border-right {
  border-right-color: #d7ccc8 !important;
}

.brown-A100-border-bottom {
  border-bottom-color: #d7ccc8 !important;
}

.brown-A100-border-left {
  border-left-color: #d7ccc8 !important;
}

.brown-A200-bg {
  background-color: #bcaaa4 !important;
}

.brown-A200 {
  background-color: #bcaaa4 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.brown-A200[disabled] {
  background-color: rgba(188, 170, 164, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.brown-A200-fg {
  color: #bcaaa4 !important;
}

.brown-A200-border {
  border-color: #bcaaa4 !important;
}

.brown-A200-border-top {
  border-top-color: #bcaaa4 !important;
}

.brown-A200-border-right {
  border-right-color: #bcaaa4 !important;
}

.brown-A200-border-bottom {
  border-bottom-color: #bcaaa4 !important;
}

.brown-A200-border-left {
  border-left-color: #bcaaa4 !important;
}

.brown-A400-bg {
  background-color: #8d6e63 !important;
}

.brown-A400 {
  background-color: #8d6e63 !important;
  color: white !important;
}
.brown-A400[disabled] {
  background-color: rgba(141, 110, 99, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.brown-A400-fg {
  color: #8d6e63 !important;
}

.brown-A400-border {
  border-color: #8d6e63 !important;
}

.brown-A400-border-top {
  border-top-color: #8d6e63 !important;
}

.brown-A400-border-right {
  border-right-color: #8d6e63 !important;
}

.brown-A400-border-bottom {
  border-bottom-color: #8d6e63 !important;
}

.brown-A400-border-left {
  border-left-color: #8d6e63 !important;
}

.brown-A700-bg {
  background-color: #5d4037 !important;
}

.brown-A700 {
  background-color: #5d4037 !important;
  color: white !important;
}
.brown-A700[disabled] {
  background-color: rgba(93, 64, 55, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.brown-A700-fg {
  color: #5d4037 !important;
}

.brown-A700-border {
  border-color: #5d4037 !important;
}

.brown-A700-border-top {
  border-top-color: #5d4037 !important;
}

.brown-A700-border-right {
  border-right-color: #5d4037 !important;
}

.brown-A700-border-bottom {
  border-bottom-color: #5d4037 !important;
}

.brown-A700-border-left {
  border-left-color: #5d4037 !important;
}

.grey-50-bg {
  background-color: #fafafa !important;
}

.grey-50 {
  background-color: #fafafa !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.grey-50[disabled] {
  background-color: rgba(250, 250, 250, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.grey-50-fg {
  color: #fafafa !important;
}

.grey-50-border {
  border-color: #fafafa !important;
}

.grey-50-border-top {
  border-top-color: #fafafa !important;
}

.grey-50-border-right {
  border-right-color: #fafafa !important;
}

.grey-50-border-bottom {
  border-bottom-color: #fafafa !important;
}

.grey-50-border-left {
  border-left-color: #fafafa !important;
}

.grey-100-bg {
  background-color: #f5f5f5 !important;
}

.grey-100 {
  background-color: #f5f5f5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.grey-100[disabled] {
  background-color: rgba(245, 245, 245, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.grey-100-fg {
  color: #f5f5f5 !important;
}

.grey-100-border {
  border-color: #f5f5f5 !important;
}

.grey-100-border-top {
  border-top-color: #f5f5f5 !important;
}

.grey-100-border-right {
  border-right-color: #f5f5f5 !important;
}

.grey-100-border-bottom {
  border-bottom-color: #f5f5f5 !important;
}

.grey-100-border-left {
  border-left-color: #f5f5f5 !important;
}

.grey-200-bg {
  background-color: #eeeeee !important;
}

.grey-200 {
  background-color: #eeeeee !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.grey-200[disabled] {
  background-color: rgba(238, 238, 238, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.grey-200-fg {
  color: #eeeeee !important;
}

.grey-200-border {
  border-color: #eeeeee !important;
}

.grey-200-border-top {
  border-top-color: #eeeeee !important;
}

.grey-200-border-right {
  border-right-color: #eeeeee !important;
}

.grey-200-border-bottom {
  border-bottom-color: #eeeeee !important;
}

.grey-200-border-left {
  border-left-color: #eeeeee !important;
}

.grey-300-bg {
  background-color: #e0e0e0 !important;
}

.grey-300 {
  background-color: #e0e0e0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.grey-300[disabled] {
  background-color: rgba(224, 224, 224, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.grey-300-fg {
  color: #e0e0e0 !important;
}

.grey-300-border {
  border-color: #e0e0e0 !important;
}

.grey-300-border-top {
  border-top-color: #e0e0e0 !important;
}

.grey-300-border-right {
  border-right-color: #e0e0e0 !important;
}

.grey-300-border-bottom {
  border-bottom-color: #e0e0e0 !important;
}

.grey-300-border-left {
  border-left-color: #e0e0e0 !important;
}

.grey-400-bg {
  background-color: #bdbdbd !important;
}

.grey-400 {
  background-color: #bdbdbd !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.grey-400[disabled] {
  background-color: rgba(189, 189, 189, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.grey-400-fg {
  color: #bdbdbd !important;
}

.grey-400-border {
  border-color: #bdbdbd !important;
}

.grey-400-border-top {
  border-top-color: #bdbdbd !important;
}

.grey-400-border-right {
  border-right-color: #bdbdbd !important;
}

.grey-400-border-bottom {
  border-bottom-color: #bdbdbd !important;
}

.grey-400-border-left {
  border-left-color: #bdbdbd !important;
}

.grey-500-bg {
  background-color: #9e9e9e !important;
}

.grey-500 {
  background-color: #9e9e9e !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.grey-500[disabled] {
  background-color: rgba(158, 158, 158, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.grey-500-fg {
  color: #9e9e9e !important;
}

.grey-500-border {
  border-color: #9e9e9e !important;
}

.grey-500-border-top {
  border-top-color: #9e9e9e !important;
}

.grey-500-border-right {
  border-right-color: #9e9e9e !important;
}

.grey-500-border-bottom {
  border-bottom-color: #9e9e9e !important;
}

.grey-500-border-left {
  border-left-color: #9e9e9e !important;
}

.grey-bg {
  background-color: #9e9e9e !important;
}

.grey {
  background-color: #9e9e9e !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.grey[disabled] {
  background-color: rgba(158, 158, 158, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.grey-fg {
  color: #9e9e9e !important;
}

.grey-border {
  border-color: #9e9e9e !important;
}

.grey-border-top {
  border-top-color: #9e9e9e !important;
}

.grey-border-right {
  border-right-color: #9e9e9e !important;
}

.grey-border-bottom {
  border-bottom-color: #9e9e9e !important;
}

.grey-border-left {
  border-left-color: #9e9e9e !important;
}

.grey-600-bg {
  background-color: #757575 !important;
}

.grey-600 {
  background-color: #757575 !important;
  color: white !important;
}
.grey-600[disabled] {
  background-color: rgba(117, 117, 117, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.grey-600-fg {
  color: #757575 !important;
}

.grey-600-border {
  border-color: #757575 !important;
}

.grey-600-border-top {
  border-top-color: #757575 !important;
}

.grey-600-border-right {
  border-right-color: #757575 !important;
}

.grey-600-border-bottom {
  border-bottom-color: #757575 !important;
}

.grey-600-border-left {
  border-left-color: #757575 !important;
}

.grey-700-bg {
  background-color: #616161 !important;
}

.grey-700 {
  background-color: #616161 !important;
  color: white !important;
}
.grey-700[disabled] {
  background-color: rgba(97, 97, 97, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.grey-700-fg {
  color: #616161 !important;
}

.grey-700-border {
  border-color: #616161 !important;
}

.grey-700-border-top {
  border-top-color: #616161 !important;
}

.grey-700-border-right {
  border-right-color: #616161 !important;
}

.grey-700-border-bottom {
  border-bottom-color: #616161 !important;
}

.grey-700-border-left {
  border-left-color: #616161 !important;
}

.grey-800-bg {
  background-color: #424242 !important;
}

.grey-800 {
  background-color: #424242 !important;
  color: white !important;
}
.grey-800[disabled] {
  background-color: rgba(66, 66, 66, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.grey-800-fg {
  color: #424242 !important;
}

.grey-800-border {
  border-color: #424242 !important;
}

.grey-800-border-top {
  border-top-color: #424242 !important;
}

.grey-800-border-right {
  border-right-color: #424242 !important;
}

.grey-800-border-bottom {
  border-bottom-color: #424242 !important;
}

.grey-800-border-left {
  border-left-color: #424242 !important;
}

.grey-900-bg {
  background-color: #212121 !important;
}

.grey-900 {
  background-color: #212121 !important;
  color: white !important;
}
.grey-900[disabled] {
  background-color: rgba(33, 33, 33, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.grey-900-fg {
  color: #212121 !important;
}

.grey-900-border {
  border-color: #212121 !important;
}

.grey-900-border-top {
  border-top-color: #212121 !important;
}

.grey-900-border-right {
  border-right-color: #212121 !important;
}

.grey-900-border-bottom {
  border-bottom-color: #212121 !important;
}

.grey-900-border-left {
  border-left-color: #212121 !important;
}

.grey-A100-bg {
  background-color: #ffffff !important;
}

.grey-A100 {
  background-color: #ffffff !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.grey-A100[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.grey-A100-fg {
  color: #ffffff !important;
}

.grey-A100-border {
  border-color: #ffffff !important;
}

.grey-A100-border-top {
  border-top-color: #ffffff !important;
}

.grey-A100-border-right {
  border-right-color: #ffffff !important;
}

.grey-A100-border-bottom {
  border-bottom-color: #ffffff !important;
}

.grey-A100-border-left {
  border-left-color: #ffffff !important;
}

.grey-A200-bg {
  background-color: #eeeeee !important;
}

.grey-A200 {
  background-color: #eeeeee !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.grey-A200[disabled] {
  background-color: rgba(238, 238, 238, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.grey-A200-fg {
  color: #eeeeee !important;
}

.grey-A200-border {
  border-color: #eeeeee !important;
}

.grey-A200-border-top {
  border-top-color: #eeeeee !important;
}

.grey-A200-border-right {
  border-right-color: #eeeeee !important;
}

.grey-A200-border-bottom {
  border-bottom-color: #eeeeee !important;
}

.grey-A200-border-left {
  border-left-color: #eeeeee !important;
}

.grey-A400-bg {
  background-color: #bdbdbd !important;
}

.grey-A400 {
  background-color: #bdbdbd !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.grey-A400[disabled] {
  background-color: rgba(189, 189, 189, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.grey-A400-fg {
  color: #bdbdbd !important;
}

.grey-A400-border {
  border-color: #bdbdbd !important;
}

.grey-A400-border-top {
  border-top-color: #bdbdbd !important;
}

.grey-A400-border-right {
  border-right-color: #bdbdbd !important;
}

.grey-A400-border-bottom {
  border-bottom-color: #bdbdbd !important;
}

.grey-A400-border-left {
  border-left-color: #bdbdbd !important;
}

.grey-A700-bg {
  background-color: #616161 !important;
}

.grey-A700 {
  background-color: #616161 !important;
  color: white !important;
}
.grey-A700[disabled] {
  background-color: rgba(97, 97, 97, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.grey-A700-fg {
  color: #616161 !important;
}

.grey-A700-border {
  border-color: #616161 !important;
}

.grey-A700-border-top {
  border-top-color: #616161 !important;
}

.grey-A700-border-right {
  border-right-color: #616161 !important;
}

.grey-A700-border-bottom {
  border-bottom-color: #616161 !important;
}

.grey-A700-border-left {
  border-left-color: #616161 !important;
}

.blue-grey-50-bg {
  background-color: #eceff1 !important;
}

.blue-grey-50 {
  background-color: #eceff1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.blue-grey-50[disabled] {
  background-color: rgba(236, 239, 241, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.blue-grey-50-fg {
  color: #eceff1 !important;
}

.blue-grey-50-border {
  border-color: #eceff1 !important;
}

.blue-grey-50-border-top {
  border-top-color: #eceff1 !important;
}

.blue-grey-50-border-right {
  border-right-color: #eceff1 !important;
}

.blue-grey-50-border-bottom {
  border-bottom-color: #eceff1 !important;
}

.blue-grey-50-border-left {
  border-left-color: #eceff1 !important;
}

.blue-grey-100-bg {
  background-color: #cfd8dc !important;
}

.blue-grey-100 {
  background-color: #cfd8dc !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.blue-grey-100[disabled] {
  background-color: rgba(207, 216, 220, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.blue-grey-100-fg {
  color: #cfd8dc !important;
}

.blue-grey-100-border {
  border-color: #cfd8dc !important;
}

.blue-grey-100-border-top {
  border-top-color: #cfd8dc !important;
}

.blue-grey-100-border-right {
  border-right-color: #cfd8dc !important;
}

.blue-grey-100-border-bottom {
  border-bottom-color: #cfd8dc !important;
}

.blue-grey-100-border-left {
  border-left-color: #cfd8dc !important;
}

.blue-grey-200-bg {
  background-color: #b0bec5 !important;
}

.blue-grey-200 {
  background-color: #b0bec5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.blue-grey-200[disabled] {
  background-color: rgba(176, 190, 197, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.blue-grey-200-fg {
  color: #b0bec5 !important;
}

.blue-grey-200-border {
  border-color: #b0bec5 !important;
}

.blue-grey-200-border-top {
  border-top-color: #b0bec5 !important;
}

.blue-grey-200-border-right {
  border-right-color: #b0bec5 !important;
}

.blue-grey-200-border-bottom {
  border-bottom-color: #b0bec5 !important;
}

.blue-grey-200-border-left {
  border-left-color: #b0bec5 !important;
}

.blue-grey-300-bg {
  background-color: #90a4ae !important;
}

.blue-grey-300 {
  background-color: #90a4ae !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.blue-grey-300[disabled] {
  background-color: rgba(144, 164, 174, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.blue-grey-300-fg {
  color: #90a4ae !important;
}

.blue-grey-300-border {
  border-color: #90a4ae !important;
}

.blue-grey-300-border-top {
  border-top-color: #90a4ae !important;
}

.blue-grey-300-border-right {
  border-right-color: #90a4ae !important;
}

.blue-grey-300-border-bottom {
  border-bottom-color: #90a4ae !important;
}

.blue-grey-300-border-left {
  border-left-color: #90a4ae !important;
}

.blue-grey-400-bg {
  background-color: #78909c !important;
}

.blue-grey-400 {
  background-color: #78909c !important;
  color: white !important;
}
.blue-grey-400[disabled] {
  background-color: rgba(120, 144, 156, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.blue-grey-400-fg {
  color: #78909c !important;
}

.blue-grey-400-border {
  border-color: #78909c !important;
}

.blue-grey-400-border-top {
  border-top-color: #78909c !important;
}

.blue-grey-400-border-right {
  border-right-color: #78909c !important;
}

.blue-grey-400-border-bottom {
  border-bottom-color: #78909c !important;
}

.blue-grey-400-border-left {
  border-left-color: #78909c !important;
}

.blue-grey-500-bg {
  background-color: #607d8b !important;
}

.blue-grey-500 {
  background-color: #607d8b !important;
  color: white !important;
}
.blue-grey-500[disabled] {
  background-color: rgba(96, 125, 139, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.blue-grey-500-fg {
  color: #607d8b !important;
}

.blue-grey-500-border {
  border-color: #607d8b !important;
}

.blue-grey-500-border-top {
  border-top-color: #607d8b !important;
}

.blue-grey-500-border-right {
  border-right-color: #607d8b !important;
}

.blue-grey-500-border-bottom {
  border-bottom-color: #607d8b !important;
}

.blue-grey-500-border-left {
  border-left-color: #607d8b !important;
}

.blue-grey-bg {
  background-color: #607d8b !important;
}

.blue-grey {
  background-color: #607d8b !important;
  color: white !important;
}
.blue-grey[disabled] {
  background-color: rgba(96, 125, 139, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.blue-grey-fg {
  color: #607d8b !important;
}

.blue-grey-border {
  border-color: #607d8b !important;
}

.blue-grey-border-top {
  border-top-color: #607d8b !important;
}

.blue-grey-border-right {
  border-right-color: #607d8b !important;
}

.blue-grey-border-bottom {
  border-bottom-color: #607d8b !important;
}

.blue-grey-border-left {
  border-left-color: #607d8b !important;
}

.blue-grey-600-bg {
  background-color: #546e7a !important;
}

.blue-grey-600 {
  background-color: #546e7a !important;
  color: white !important;
}
.blue-grey-600[disabled] {
  background-color: rgba(84, 110, 122, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.blue-grey-600-fg {
  color: #546e7a !important;
}

.blue-grey-600-border {
  border-color: #546e7a !important;
}

.blue-grey-600-border-top {
  border-top-color: #546e7a !important;
}

.blue-grey-600-border-right {
  border-right-color: #546e7a !important;
}

.blue-grey-600-border-bottom {
  border-bottom-color: #546e7a !important;
}

.blue-grey-600-border-left {
  border-left-color: #546e7a !important;
}

.blue-grey-700-bg {
  background-color: #455a64 !important;
}

.blue-grey-700 {
  background-color: #455a64 !important;
  color: white !important;
}
.blue-grey-700[disabled] {
  background-color: rgba(69, 90, 100, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.blue-grey-700-fg {
  color: #455a64 !important;
}

.blue-grey-700-border {
  border-color: #455a64 !important;
}

.blue-grey-700-border-top {
  border-top-color: #455a64 !important;
}

.blue-grey-700-border-right {
  border-right-color: #455a64 !important;
}

.blue-grey-700-border-bottom {
  border-bottom-color: #455a64 !important;
}

.blue-grey-700-border-left {
  border-left-color: #455a64 !important;
}

.blue-grey-800-bg {
  background-color: #37474f !important;
}

.blue-grey-800 {
  background-color: #37474f !important;
  color: white !important;
}
.blue-grey-800[disabled] {
  background-color: rgba(55, 71, 79, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.blue-grey-800-fg {
  color: #37474f !important;
}

.blue-grey-800-border {
  border-color: #37474f !important;
}

.blue-grey-800-border-top {
  border-top-color: #37474f !important;
}

.blue-grey-800-border-right {
  border-right-color: #37474f !important;
}

.blue-grey-800-border-bottom {
  border-bottom-color: #37474f !important;
}

.blue-grey-800-border-left {
  border-left-color: #37474f !important;
}

.blue-grey-900-bg {
  background-color: #263238 !important;
}

.blue-grey-900 {
  background-color: #263238 !important;
  color: white !important;
}
.blue-grey-900[disabled] {
  background-color: rgba(38, 50, 56, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.blue-grey-900-fg {
  color: #263238 !important;
}

.blue-grey-900-border {
  border-color: #263238 !important;
}

.blue-grey-900-border-top {
  border-top-color: #263238 !important;
}

.blue-grey-900-border-right {
  border-right-color: #263238 !important;
}

.blue-grey-900-border-bottom {
  border-bottom-color: #263238 !important;
}

.blue-grey-900-border-left {
  border-left-color: #263238 !important;
}

.blue-grey-A100-bg {
  background-color: #cfd8dc !important;
}

.blue-grey-A100 {
  background-color: #cfd8dc !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.blue-grey-A100[disabled] {
  background-color: rgba(207, 216, 220, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.blue-grey-A100-fg {
  color: #cfd8dc !important;
}

.blue-grey-A100-border {
  border-color: #cfd8dc !important;
}

.blue-grey-A100-border-top {
  border-top-color: #cfd8dc !important;
}

.blue-grey-A100-border-right {
  border-right-color: #cfd8dc !important;
}

.blue-grey-A100-border-bottom {
  border-bottom-color: #cfd8dc !important;
}

.blue-grey-A100-border-left {
  border-left-color: #cfd8dc !important;
}

.blue-grey-A200-bg {
  background-color: #b0bec5 !important;
}

.blue-grey-A200 {
  background-color: #b0bec5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.blue-grey-A200[disabled] {
  background-color: rgba(176, 190, 197, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.blue-grey-A200-fg {
  color: #b0bec5 !important;
}

.blue-grey-A200-border {
  border-color: #b0bec5 !important;
}

.blue-grey-A200-border-top {
  border-top-color: #b0bec5 !important;
}

.blue-grey-A200-border-right {
  border-right-color: #b0bec5 !important;
}

.blue-grey-A200-border-bottom {
  border-bottom-color: #b0bec5 !important;
}

.blue-grey-A200-border-left {
  border-left-color: #b0bec5 !important;
}

.blue-grey-A400-bg {
  background-color: #78909c !important;
}

.blue-grey-A400 {
  background-color: #78909c !important;
  color: white !important;
}
.blue-grey-A400[disabled] {
  background-color: rgba(120, 144, 156, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.blue-grey-A400-fg {
  color: #78909c !important;
}

.blue-grey-A400-border {
  border-color: #78909c !important;
}

.blue-grey-A400-border-top {
  border-top-color: #78909c !important;
}

.blue-grey-A400-border-right {
  border-right-color: #78909c !important;
}

.blue-grey-A400-border-bottom {
  border-bottom-color: #78909c !important;
}

.blue-grey-A400-border-left {
  border-left-color: #78909c !important;
}

.blue-grey-A700-bg {
  background-color: #455a64 !important;
}

.blue-grey-A700 {
  background-color: #455a64 !important;
  color: white !important;
}
.blue-grey-A700[disabled] {
  background-color: rgba(69, 90, 100, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}

.blue-grey-A700-fg {
  color: #455a64 !important;
}

.blue-grey-A700-border {
  border-color: #455a64 !important;
}

.blue-grey-A700-border-top {
  border-top-color: #455a64 !important;
}

.blue-grey-A700-border-right {
  border-right-color: #455a64 !important;
}

.blue-grey-A700-border-bottom {
  border-bottom-color: #455a64 !important;
}

.blue-grey-A700-border-left {
  border-left-color: #455a64 !important;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 500 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.light-theme .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.light-theme .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-option:hover:not(.mat-option-disabled), .light-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.light-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.light-theme .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #009688;
}
.light-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ffc107;
}
.light-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.light-theme .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.light-theme .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.light-theme .mat-primary .mat-pseudo-checkbox-checked,
.light-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #009688;
}
.light-theme .mat-pseudo-checkbox-checked,
.light-theme .mat-pseudo-checkbox-indeterminate,
.light-theme .mat-accent .mat-pseudo-checkbox-checked,
.light-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ffc107;
}
.light-theme .mat-warn .mat-pseudo-checkbox-checked,
.light-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.light-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.light-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.light-theme .mat-app-background, .light-theme.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.light-theme .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.light-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-badge {
  position: relative;
}
.light-theme .mat-badge.mat-badge {
  overflow: visible;
}
.light-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.light-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.light-theme .ng-animate-disabled .mat-badge-content,
.light-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.light-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.light-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.light-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.light-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.light-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .light-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.light-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .light-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.light-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .light-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.light-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .light-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.light-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.light-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.light-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.light-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .light-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.light-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .light-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.light-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .light-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.light-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .light-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.light-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.light-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.light-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.light-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .light-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.light-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .light-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.light-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .light-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.light-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .light-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.light-theme .mat-badge-content {
  color: white;
  background: #009688;
}
.cdk-high-contrast-active .light-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.light-theme .mat-badge-accent .mat-badge-content {
  background: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.light-theme .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-button, .light-theme .mat-icon-button, .light-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.light-theme .mat-button.mat-primary, .light-theme .mat-icon-button.mat-primary, .light-theme .mat-stroked-button.mat-primary {
  color: #009688;
}
.light-theme .mat-button.mat-accent, .light-theme .mat-icon-button.mat-accent, .light-theme .mat-stroked-button.mat-accent {
  color: #ffc107;
}
.light-theme .mat-button.mat-warn, .light-theme .mat-icon-button.mat-warn, .light-theme .mat-stroked-button.mat-warn {
  color: #f44336;
}
.light-theme .mat-button.mat-primary.mat-button-disabled, .light-theme .mat-button.mat-accent.mat-button-disabled, .light-theme .mat-button.mat-warn.mat-button-disabled, .light-theme .mat-button.mat-button-disabled.mat-button-disabled, .light-theme .mat-icon-button.mat-primary.mat-button-disabled, .light-theme .mat-icon-button.mat-accent.mat-button-disabled, .light-theme .mat-icon-button.mat-warn.mat-button-disabled, .light-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .light-theme .mat-stroked-button.mat-primary.mat-button-disabled, .light-theme .mat-stroked-button.mat-accent.mat-button-disabled, .light-theme .mat-stroked-button.mat-warn.mat-button-disabled, .light-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.light-theme .mat-button.mat-primary .mat-button-focus-overlay, .light-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .light-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #009688;
}
.light-theme .mat-button.mat-accent .mat-button-focus-overlay, .light-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .light-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ffc107;
}
.light-theme .mat-button.mat-warn .mat-button-focus-overlay, .light-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .light-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.light-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .light-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .light-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.light-theme .mat-button .mat-ripple-element, .light-theme .mat-icon-button .mat-ripple-element, .light-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.light-theme .mat-button-focus-overlay {
  background: black;
}
.light-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-flat-button, .light-theme .mat-raised-button, .light-theme .mat-fab, .light-theme .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.light-theme .mat-flat-button.mat-primary, .light-theme .mat-raised-button.mat-primary, .light-theme .mat-fab.mat-primary, .light-theme .mat-mini-fab.mat-primary {
  color: white;
}
.light-theme .mat-flat-button.mat-accent, .light-theme .mat-raised-button.mat-accent, .light-theme .mat-fab.mat-accent, .light-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-flat-button.mat-warn, .light-theme .mat-raised-button.mat-warn, .light-theme .mat-fab.mat-warn, .light-theme .mat-mini-fab.mat-warn {
  color: white;
}
.light-theme .mat-flat-button.mat-primary.mat-button-disabled, .light-theme .mat-flat-button.mat-accent.mat-button-disabled, .light-theme .mat-flat-button.mat-warn.mat-button-disabled, .light-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .light-theme .mat-raised-button.mat-primary.mat-button-disabled, .light-theme .mat-raised-button.mat-accent.mat-button-disabled, .light-theme .mat-raised-button.mat-warn.mat-button-disabled, .light-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .light-theme .mat-fab.mat-primary.mat-button-disabled, .light-theme .mat-fab.mat-accent.mat-button-disabled, .light-theme .mat-fab.mat-warn.mat-button-disabled, .light-theme .mat-fab.mat-button-disabled.mat-button-disabled, .light-theme .mat-mini-fab.mat-primary.mat-button-disabled, .light-theme .mat-mini-fab.mat-accent.mat-button-disabled, .light-theme .mat-mini-fab.mat-warn.mat-button-disabled, .light-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.light-theme .mat-flat-button.mat-primary, .light-theme .mat-raised-button.mat-primary, .light-theme .mat-fab.mat-primary, .light-theme .mat-mini-fab.mat-primary {
  background-color: #009688;
}
.light-theme .mat-flat-button.mat-accent, .light-theme .mat-raised-button.mat-accent, .light-theme .mat-fab.mat-accent, .light-theme .mat-mini-fab.mat-accent {
  background-color: #ffc107;
}
.light-theme .mat-flat-button.mat-warn, .light-theme .mat-raised-button.mat-warn, .light-theme .mat-fab.mat-warn, .light-theme .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.light-theme .mat-flat-button.mat-primary.mat-button-disabled, .light-theme .mat-flat-button.mat-accent.mat-button-disabled, .light-theme .mat-flat-button.mat-warn.mat-button-disabled, .light-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .light-theme .mat-raised-button.mat-primary.mat-button-disabled, .light-theme .mat-raised-button.mat-accent.mat-button-disabled, .light-theme .mat-raised-button.mat-warn.mat-button-disabled, .light-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .light-theme .mat-fab.mat-primary.mat-button-disabled, .light-theme .mat-fab.mat-accent.mat-button-disabled, .light-theme .mat-fab.mat-warn.mat-button-disabled, .light-theme .mat-fab.mat-button-disabled.mat-button-disabled, .light-theme .mat-mini-fab.mat-primary.mat-button-disabled, .light-theme .mat-mini-fab.mat-accent.mat-button-disabled, .light-theme .mat-mini-fab.mat-warn.mat-button-disabled, .light-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-flat-button.mat-primary .mat-ripple-element, .light-theme .mat-raised-button.mat-primary .mat-ripple-element, .light-theme .mat-fab.mat-primary .mat-ripple-element, .light-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.light-theme .mat-flat-button.mat-accent .mat-ripple-element, .light-theme .mat-raised-button.mat-accent .mat-ripple-element, .light-theme .mat-fab.mat-accent .mat-ripple-element, .light-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.light-theme .mat-flat-button.mat-warn .mat-ripple-element, .light-theme .mat-raised-button.mat-warn .mat-ripple-element, .light-theme .mat-fab.mat-warn .mat-ripple-element, .light-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.light-theme .mat-stroked-button:not([class*=mat-elevation-z]), .light-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-fab:not([class*=mat-elevation-z]), .light-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .light-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .light-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.light-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.light-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.light-theme .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.light-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.light-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.light-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.light-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.light-theme .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.light-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.light-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.light-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.light-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.light-theme .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}
.light-theme .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-checkbox-checkmark {
  fill: #fafafa;
}
.light-theme .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.light-theme .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.light-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .light-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #009688;
}
.light-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .light-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ffc107;
}
.light-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .light-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.light-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .light-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.light-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.light-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.light-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.light-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #009688;
}
.light-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.light-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ffc107;
}
.light-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.light-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.light-theme .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.light-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.light-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.light-theme .mat-chip.mat-standard-chip::after {
  background: black;
}
.light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #009688;
  color: white;
}
.light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.light-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.light-theme .mat-table {
  background: white;
}
.light-theme .mat-table thead, .light-theme .mat-table tbody, .light-theme .mat-table tfoot,
.light-theme mat-header-row, .light-theme mat-row, .light-theme mat-footer-row,
.light-theme [mat-header-row], .light-theme [mat-row], .light-theme [mat-footer-row],
.light-theme .mat-table-sticky {
  background: inherit;
}
.light-theme mat-row, .light-theme mat-header-row, .light-theme mat-footer-row,
.light-theme th.mat-header-cell, .light-theme td.mat-cell, .light-theme td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-cell, .light-theme .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-datepicker-toggle,
.light-theme .mat-datepicker-content .mat-calendar-next-button,
.light-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-calendar-table-header,
.light-theme .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-calendar-body-cell-content,
.light-theme .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.light-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.light-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.light-theme .mat-calendar-body-in-range::before {
  background: rgba(0, 150, 136, 0.2);
}
.light-theme .mat-calendar-body-comparison-identical,
.light-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.light-theme .mat-calendar-body-comparison-bridge-start::before,
.light-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 150, 136, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.light-theme .mat-calendar-body-comparison-bridge-end::before,
.light-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 150, 136, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.light-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.light-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.light-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.light-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.light-theme .mat-calendar-body-selected {
  background-color: #009688;
  color: white;
}
.light-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 150, 136, 0.4);
}
.light-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.light-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.light-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 150, 136, 0.3);
}
@media (hover: hover) {
  .light-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 150, 136, 0.3);
  }
}
.light-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 193, 7, 0.2);
}
.light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.light-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 193, 7, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.light-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 193, 7, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 193, 7, 0.4);
}
.light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.light-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.light-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 193, 7, 0.3);
}
@media (hover: hover) {
  .light-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 193, 7, 0.3);
  }
}
.light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.light-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.light-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.light-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.light-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .light-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.light-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-datepicker-toggle-active {
  color: #009688;
}
.light-theme .mat-datepicker-toggle-active.mat-accent {
  color: #ffc107;
}
.light-theme .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.light-theme .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .light-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .light-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .light-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.light-theme .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-expansion-panel-header-description,
.light-theme .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.light-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.light-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.light-theme .mat-expansion-panel-header {
  height: 48px;
}
.light-theme .mat-expansion-panel-header.mat-expanded {
  height: 64px;
}
.light-theme .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.light-theme .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.light-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #009688;
}
.light-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ffc107;
}
.light-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.light-theme .mat-focused .mat-form-field-required-marker {
  color: #ffc107;
}
.light-theme .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #009688;
}
.light-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ffc107;
}
.light-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.light-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #009688;
}
.light-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ffc107;
}
.light-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.light-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.light-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.light-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.light-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.light-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.light-theme .mat-error {
  color: #f44336;
}
.light-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.light-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.light-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.light-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.light-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.light-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.light-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.light-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.light-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #009688;
}
.light-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ffc107;
}
.light-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.light-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.light-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.light-theme .mat-icon.mat-primary {
  color: #009688;
}
.light-theme .mat-icon.mat-accent {
  color: #ffc107;
}
.light-theme .mat-icon.mat-warn {
  color: #f44336;
}
.light-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-input-element:disabled,
.light-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-input-element {
  caret-color: #009688;
}
.light-theme .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.light-theme .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.light-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.light-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.light-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ffc107;
}
.light-theme .mat-form-field.mat-warn .mat-input-element,
.light-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.light-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.light-theme .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-list-option:hover, .light-theme .mat-list-option:focus,
.light-theme .mat-nav-list .mat-list-item:hover,
.light-theme .mat-nav-list .mat-list-item:focus,
.light-theme .mat-action-list .mat-list-item:hover,
.light-theme .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.light-theme .mat-list-single-selected-option, .light-theme .mat-list-single-selected-option:hover, .light-theme .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-menu-panel {
  background: white;
}
.light-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-menu-item[disabled],
.light-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.light-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-menu-item .mat-icon-no-color,
.light-theme .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-menu-item:hover:not([disabled]),
.light-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.light-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.light-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.light-theme .mat-paginator {
  background: white;
}
.light-theme .mat-paginator,
.light-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-paginator-decrement,
.light-theme .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.light-theme .mat-paginator-first,
.light-theme .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.light-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.light-theme .mat-icon-button[disabled] .mat-paginator-increment,
.light-theme .mat-icon-button[disabled] .mat-paginator-first,
.light-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-paginator-container {
  min-height: 56px;
}
.light-theme .mat-progress-bar-background {
  fill: #bce1de;
}
.light-theme .mat-progress-bar-buffer {
  background-color: #bce1de;
}
.light-theme .mat-progress-bar-fill::after {
  background-color: #009688;
}
.light-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #fbecbd;
}
.light-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #fbecbd;
}
.light-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ffc107;
}
.light-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.light-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.light-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.light-theme .mat-progress-spinner circle, .light-theme .mat-spinner circle {
  stroke: #009688;
}
.light-theme .mat-progress-spinner.mat-accent circle, .light-theme .mat-spinner.mat-accent circle {
  stroke: #ffc107;
}
.light-theme .mat-progress-spinner.mat-warn circle, .light-theme .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.light-theme .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #009688;
}
.light-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.light-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .light-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .light-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #009688;
}
.light-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffc107;
}
.light-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.light-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .light-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .light-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ffc107;
}
.light-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.light-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.light-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .light-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .light-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.light-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.light-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.light-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.light-theme .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.light-theme .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-select-panel {
  background: white;
}
.light-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #009688;
}
.light-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ffc107;
}
.light-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.light-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.light-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-drawer.mat-drawer-push {
  background-color: white;
}
.light-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.light-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.light-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.light-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffc107;
}
.light-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 193, 7, 0.54);
}
.light-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ffc107;
}
.light-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #009688;
}
.light-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 150, 136, 0.54);
}
.light-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #009688;
}
.light-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.light-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.light-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.light-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.light-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.light-theme .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.light-theme .mat-slider.mat-primary .mat-slider-track-fill,
.light-theme .mat-slider.mat-primary .mat-slider-thumb,
.light-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #009688;
}
.light-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.light-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 150, 136, 0.2);
}
.light-theme .mat-slider.mat-accent .mat-slider-track-fill,
.light-theme .mat-slider.mat-accent .mat-slider-thumb,
.light-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ffc107;
}
.light-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 193, 7, 0.2);
}
.light-theme .mat-slider.mat-warn .mat-slider-track-fill,
.light-theme .mat-slider.mat-warn .mat-slider-thumb,
.light-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.light-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.light-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.light-theme .mat-slider:hover .mat-slider-track-background,
.light-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.light-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.light-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.light-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.light-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.light-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.light-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.light-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.light-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .light-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .light-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.light-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.light-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.light-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.light-theme .mat-step-header.cdk-keyboard-focused, .light-theme .mat-step-header.cdk-program-focused, .light-theme .mat-step-header:hover:not([aria-disabled]), .light-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.light-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .light-theme .mat-step-header:hover {
    background: none;
  }
}
.light-theme .mat-step-header .mat-step-label,
.light-theme .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.light-theme .mat-step-header .mat-step-icon-selected,
.light-theme .mat-step-header .mat-step-icon-state-done,
.light-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #009688;
  color: white;
}
.light-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.light-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.light-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.light-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.light-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.light-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.light-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.light-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.light-theme .mat-stepper-horizontal, .light-theme .mat-stepper-vertical {
  background-color: white;
}
.light-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-horizontal-stepper-header::before,
.light-theme .mat-horizontal-stepper-header::after,
.light-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.light-theme .mat-horizontal-stepper-header {
  height: 72px;
}
.light-theme .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.light-theme .mat-vertical-stepper-header {
  padding: 24px 24px;
}
.light-theme .mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}
.light-theme .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .light-theme .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}
.light-theme .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}
.light-theme .mat-sort-header-arrow {
  color: #757575;
}
.light-theme .mat-tab-nav-bar,
.light-theme .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.light-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.light-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.light-theme .mat-tab-label, .light-theme .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-tab-label.mat-tab-disabled, .light-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.light-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.light-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.light-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(178, 223, 219, 0.3);
}
.light-theme .mat-tab-group.mat-primary .mat-ink-bar, .light-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #009688;
}
.light-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .light-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .light-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .light-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.light-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 224, 130, 0.3);
}
.light-theme .mat-tab-group.mat-accent .mat-ink-bar, .light-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ffc107;
}
.light-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .light-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .light-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .light-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.light-theme .mat-tab-group.mat-warn .mat-ink-bar, .light-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.light-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .light-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .light-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .light-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.light-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(178, 223, 219, 0.3);
}
.light-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .light-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .light-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #009688;
}
.light-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .light-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.light-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .light-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.light-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.light-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.light-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.light-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.light-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.light-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.light-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.light-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 224, 130, 0.3);
}
.light-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .light-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .light-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ffc107;
}
.light-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .light-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .light-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.light-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.light-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.light-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.light-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.light-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.light-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.light-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.light-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .light-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .light-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.light-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .light-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.light-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .light-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.light-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.light-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.light-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.light-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.light-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.light-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.light-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.light-theme .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-toolbar.mat-primary {
  background: #009688;
  color: white;
}
.light-theme .mat-toolbar.mat-accent {
  background: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.light-theme .mat-toolbar .mat-form-field-underline,
.light-theme .mat-toolbar .mat-form-field-ripple,
.light-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.light-theme .mat-toolbar .mat-form-field-label,
.light-theme .mat-toolbar .mat-focused .mat-form-field-label,
.light-theme .mat-toolbar .mat-select-value,
.light-theme .mat-toolbar .mat-select-arrow,
.light-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.light-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.light-theme .mat-toolbar-multiple-rows {
  min-height: 64px;
}
.light-theme .mat-toolbar-row, .light-theme .mat-toolbar-single-row {
  height: 64px;
}
@media (max-width: 599px) {
  .light-theme .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .light-theme .mat-toolbar-row, .light-theme .mat-toolbar-single-row {
    height: 56px;
  }
}
.light-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.light-theme .mat-tree {
  background: white;
}
.light-theme .mat-tree-node,
.light-theme .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme .mat-tree-node {
  min-height: 48px;
}
.light-theme .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.light-theme .mat-simple-snackbar-action {
  color: #ffc107;
}
.light-theme .list-item .title-row .item-title {
  font-size: 16px;
}
.light-theme .list-item .title-row .item-title-suffix {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .list-item .content-row {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .list-item.album .item-image:not(:empty) {
  background-color: whitesmoke;
}
.light-theme .list-item.album .title-row .item-title {
  color: rgba(0, 0, 0, 0.54);
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 4px;
  line-height: 11px;
}
.light-theme .list-item.album .title-row .item-title-suffix {
  line-height: 16px;
  margin-top: -5px;
}
.light-theme .list-item.album .subtitle-row {
  font-size: 16px;
  line-height: 24px;
}
.light-theme .main-app-scaffold {
  background-color: white;
}
.light-theme .main-app-scaffold .mat-nav-list .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  border-left: 0px solid currentColor;
  transition: background-color, 0.1s ease-out, color 0.1s ease-out, border 0.1s ease-out;
}
.light-theme .main-app-scaffold .mat-nav-list .mat-list-item mat-icon {
  color: inherit;
}
.light-theme .main-app-scaffold .mat-nav-list .mat-list-item .mat-list-item-content {
  transition: padding 0.1s ease-out;
}
.light-theme .main-app-scaffold .mat-nav-list .mat-list-item .mat-list-item-content mat-icon {
  transition: color 0.1s ease-out;
}
.light-theme .main-app-scaffold .mat-nav-list .mat-list-item .mat-list-item-content .mat-line {
  font-weight: inherit;
}
.light-theme .main-app-scaffold .mat-nav-list .mat-list-item.mat-list-item-active {
  border-left: 4px solid currentColor;
  background-color: rgba(0, 150, 136, 0.08) !important;
  color: #00635a;
  font-weight: 500;
}
.light-theme .main-app-scaffold .mat-nav-list .mat-list-item.mat-list-item-active .mat-list-item-content {
  padding-left: 12px;
}
.light-theme .toolbar-scaffold-wrapper .scroll-content {
  background-color: white;
}
.light-theme .toolbar-scaffold-wrapper .loading-overlay {
  background-color: rgba(250, 250, 250, 0.8);
}
.light-theme .profile-picture {
  background-color: #b2dfdb;
  color: white;
}
.light-theme .chat-bubble-wrapper .bubble.me {
  background-color: #ffe082;
}
.light-theme .chat-bubble-wrapper .bubble.me::before, .light-theme .chat-bubble-wrapper .bubble.me::after {
  color: #ffe082;
}
.light-theme .chat-bubble-wrapper .bubble.contact {
  background-color: #b2dfdb;
}
.light-theme .chat-bubble-wrapper .bubble.contact::before, .light-theme .chat-bubble-wrapper .bubble.contact::after {
  color: #b2dfdb;
}
.light-theme .chat-bubble-wrapper .bubble .message-time {
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.54);
}
.light-theme .primary-50-bg {
  background-color: #e0f2f1 !important;
}
.light-theme .primary-50 {
  background-color: #e0f2f1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .primary-50[disabled] {
  background-color: rgba(224, 242, 241, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .primary-50-fg {
  color: #e0f2f1 !important;
}
.light-theme .primary-50-border {
  border-color: #e0f2f1 !important;
}
.light-theme .primary-50-border-top {
  border-top-color: #e0f2f1 !important;
}
.light-theme .primary-50-border-right {
  border-right-color: #e0f2f1 !important;
}
.light-theme .primary-50-border-bottom {
  border-bottom-color: #e0f2f1 !important;
}
.light-theme .primary-50-border-left {
  border-left-color: #e0f2f1 !important;
}
.light-theme .primary-100-bg {
  background-color: #b2dfdb !important;
}
.light-theme .primary-100 {
  background-color: #b2dfdb !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .primary-100[disabled] {
  background-color: rgba(178, 223, 219, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .primary-100-fg {
  color: #b2dfdb !important;
}
.light-theme .primary-100-border {
  border-color: #b2dfdb !important;
}
.light-theme .primary-100-border-top {
  border-top-color: #b2dfdb !important;
}
.light-theme .primary-100-border-right {
  border-right-color: #b2dfdb !important;
}
.light-theme .primary-100-border-bottom {
  border-bottom-color: #b2dfdb !important;
}
.light-theme .primary-100-border-left {
  border-left-color: #b2dfdb !important;
}
.light-theme .primary-200-bg {
  background-color: #80cbc4 !important;
}
.light-theme .primary-200 {
  background-color: #80cbc4 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .primary-200[disabled] {
  background-color: rgba(128, 203, 196, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .primary-200-fg {
  color: #80cbc4 !important;
}
.light-theme .primary-200-border {
  border-color: #80cbc4 !important;
}
.light-theme .primary-200-border-top {
  border-top-color: #80cbc4 !important;
}
.light-theme .primary-200-border-right {
  border-right-color: #80cbc4 !important;
}
.light-theme .primary-200-border-bottom {
  border-bottom-color: #80cbc4 !important;
}
.light-theme .primary-200-border-left {
  border-left-color: #80cbc4 !important;
}
.light-theme .primary-300-bg {
  background-color: #4db6ac !important;
}
.light-theme .primary-300 {
  background-color: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .primary-300[disabled] {
  background-color: rgba(77, 182, 172, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .primary-300-fg {
  color: #4db6ac !important;
}
.light-theme .primary-300-border {
  border-color: #4db6ac !important;
}
.light-theme .primary-300-border-top {
  border-top-color: #4db6ac !important;
}
.light-theme .primary-300-border-right {
  border-right-color: #4db6ac !important;
}
.light-theme .primary-300-border-bottom {
  border-bottom-color: #4db6ac !important;
}
.light-theme .primary-300-border-left {
  border-left-color: #4db6ac !important;
}
.light-theme .primary-400-bg {
  background-color: #26a69a !important;
}
.light-theme .primary-400 {
  background-color: #26a69a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .primary-400[disabled] {
  background-color: rgba(38, 166, 154, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .primary-400-fg {
  color: #26a69a !important;
}
.light-theme .primary-400-border {
  border-color: #26a69a !important;
}
.light-theme .primary-400-border-top {
  border-top-color: #26a69a !important;
}
.light-theme .primary-400-border-right {
  border-right-color: #26a69a !important;
}
.light-theme .primary-400-border-bottom {
  border-bottom-color: #26a69a !important;
}
.light-theme .primary-400-border-left {
  border-left-color: #26a69a !important;
}
.light-theme .primary-500-bg {
  background-color: #009688 !important;
}
.light-theme .primary-500 {
  background-color: #009688 !important;
  color: white !important;
}
.light-theme .primary-500[disabled] {
  background-color: rgba(0, 150, 136, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.light-theme .primary-500-fg {
  color: #009688 !important;
}
.light-theme .primary-500-border {
  border-color: #009688 !important;
}
.light-theme .primary-500-border-top {
  border-top-color: #009688 !important;
}
.light-theme .primary-500-border-right {
  border-right-color: #009688 !important;
}
.light-theme .primary-500-border-bottom {
  border-bottom-color: #009688 !important;
}
.light-theme .primary-500-border-left {
  border-left-color: #009688 !important;
}
.light-theme .primary-600-bg {
  background-color: #00897b !important;
}
.light-theme .primary-600 {
  background-color: #00897b !important;
  color: white !important;
}
.light-theme .primary-600[disabled] {
  background-color: rgba(0, 137, 123, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.light-theme .primary-600-fg {
  color: #00897b !important;
}
.light-theme .primary-600-border {
  border-color: #00897b !important;
}
.light-theme .primary-600-border-top {
  border-top-color: #00897b !important;
}
.light-theme .primary-600-border-right {
  border-right-color: #00897b !important;
}
.light-theme .primary-600-border-bottom {
  border-bottom-color: #00897b !important;
}
.light-theme .primary-600-border-left {
  border-left-color: #00897b !important;
}
.light-theme .primary-700-bg {
  background-color: #00796b !important;
}
.light-theme .primary-700 {
  background-color: #00796b !important;
  color: white !important;
}
.light-theme .primary-700[disabled] {
  background-color: rgba(0, 121, 107, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.light-theme .primary-700-fg {
  color: #00796b !important;
}
.light-theme .primary-700-border {
  border-color: #00796b !important;
}
.light-theme .primary-700-border-top {
  border-top-color: #00796b !important;
}
.light-theme .primary-700-border-right {
  border-right-color: #00796b !important;
}
.light-theme .primary-700-border-bottom {
  border-bottom-color: #00796b !important;
}
.light-theme .primary-700-border-left {
  border-left-color: #00796b !important;
}
.light-theme .primary-800-bg {
  background-color: #00695c !important;
}
.light-theme .primary-800 {
  background-color: #00695c !important;
  color: white !important;
}
.light-theme .primary-800[disabled] {
  background-color: rgba(0, 105, 92, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.light-theme .primary-800-fg {
  color: #00695c !important;
}
.light-theme .primary-800-border {
  border-color: #00695c !important;
}
.light-theme .primary-800-border-top {
  border-top-color: #00695c !important;
}
.light-theme .primary-800-border-right {
  border-right-color: #00695c !important;
}
.light-theme .primary-800-border-bottom {
  border-bottom-color: #00695c !important;
}
.light-theme .primary-800-border-left {
  border-left-color: #00695c !important;
}
.light-theme .primary-900-bg {
  background-color: #004d40 !important;
}
.light-theme .primary-900 {
  background-color: #004d40 !important;
  color: white !important;
}
.light-theme .primary-900[disabled] {
  background-color: rgba(0, 77, 64, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.light-theme .primary-900-fg {
  color: #004d40 !important;
}
.light-theme .primary-900-border {
  border-color: #004d40 !important;
}
.light-theme .primary-900-border-top {
  border-top-color: #004d40 !important;
}
.light-theme .primary-900-border-right {
  border-right-color: #004d40 !important;
}
.light-theme .primary-900-border-bottom {
  border-bottom-color: #004d40 !important;
}
.light-theme .primary-900-border-left {
  border-left-color: #004d40 !important;
}
.light-theme .primary-A100-bg {
  background-color: #a7ffeb !important;
}
.light-theme .primary-A100 {
  background-color: #a7ffeb !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .primary-A100[disabled] {
  background-color: rgba(167, 255, 235, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .primary-A100-fg {
  color: #a7ffeb !important;
}
.light-theme .primary-A100-border {
  border-color: #a7ffeb !important;
}
.light-theme .primary-A100-border-top {
  border-top-color: #a7ffeb !important;
}
.light-theme .primary-A100-border-right {
  border-right-color: #a7ffeb !important;
}
.light-theme .primary-A100-border-bottom {
  border-bottom-color: #a7ffeb !important;
}
.light-theme .primary-A100-border-left {
  border-left-color: #a7ffeb !important;
}
.light-theme .primary-A200-bg {
  background-color: #64ffda !important;
}
.light-theme .primary-A200 {
  background-color: #64ffda !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .primary-A200[disabled] {
  background-color: rgba(100, 255, 218, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .primary-A200-fg {
  color: #64ffda !important;
}
.light-theme .primary-A200-border {
  border-color: #64ffda !important;
}
.light-theme .primary-A200-border-top {
  border-top-color: #64ffda !important;
}
.light-theme .primary-A200-border-right {
  border-right-color: #64ffda !important;
}
.light-theme .primary-A200-border-bottom {
  border-bottom-color: #64ffda !important;
}
.light-theme .primary-A200-border-left {
  border-left-color: #64ffda !important;
}
.light-theme .primary-A400-bg {
  background-color: #1de9b6 !important;
}
.light-theme .primary-A400 {
  background-color: #1de9b6 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .primary-A400[disabled] {
  background-color: rgba(29, 233, 182, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .primary-A400-fg {
  color: #1de9b6 !important;
}
.light-theme .primary-A400-border {
  border-color: #1de9b6 !important;
}
.light-theme .primary-A400-border-top {
  border-top-color: #1de9b6 !important;
}
.light-theme .primary-A400-border-right {
  border-right-color: #1de9b6 !important;
}
.light-theme .primary-A400-border-bottom {
  border-bottom-color: #1de9b6 !important;
}
.light-theme .primary-A400-border-left {
  border-left-color: #1de9b6 !important;
}
.light-theme .primary-A700-bg {
  background-color: #00bfa5 !important;
}
.light-theme .primary-A700 {
  background-color: #00bfa5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .primary-A700[disabled] {
  background-color: rgba(0, 191, 165, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .primary-A700-fg {
  color: #00bfa5 !important;
}
.light-theme .primary-A700-border {
  border-color: #00bfa5 !important;
}
.light-theme .primary-A700-border-top {
  border-top-color: #00bfa5 !important;
}
.light-theme .primary-A700-border-right {
  border-right-color: #00bfa5 !important;
}
.light-theme .primary-A700-border-bottom {
  border-bottom-color: #00bfa5 !important;
}
.light-theme .primary-A700-border-left {
  border-left-color: #00bfa5 !important;
}
.light-theme .primary-bg {
  background-color: #009688 !important;
}
.light-theme .primary {
  background-color: #009688 !important;
  color: white !important;
}
.light-theme .primary[disabled] {
  background-color: rgba(0, 150, 136, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.light-theme .primary-fg {
  color: #009688 !important;
}
.light-theme .primary-border {
  border-color: #009688 !important;
}
.light-theme .primary-border-top {
  border-top-color: #009688 !important;
}
.light-theme .primary-border-right {
  border-right-color: #009688 !important;
}
.light-theme .primary-border-bottom {
  border-bottom-color: #009688 !important;
}
.light-theme .primary-border-left {
  border-left-color: #009688 !important;
}
.light-theme .accent-50-bg {
  background-color: #fff8e1 !important;
}
.light-theme .accent-50 {
  background-color: #fff8e1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .accent-50[disabled] {
  background-color: rgba(255, 248, 225, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .accent-50-fg {
  color: #fff8e1 !important;
}
.light-theme .accent-50-border {
  border-color: #fff8e1 !important;
}
.light-theme .accent-50-border-top {
  border-top-color: #fff8e1 !important;
}
.light-theme .accent-50-border-right {
  border-right-color: #fff8e1 !important;
}
.light-theme .accent-50-border-bottom {
  border-bottom-color: #fff8e1 !important;
}
.light-theme .accent-50-border-left {
  border-left-color: #fff8e1 !important;
}
.light-theme .accent-100-bg {
  background-color: #ffecb3 !important;
}
.light-theme .accent-100 {
  background-color: #ffecb3 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .accent-100[disabled] {
  background-color: rgba(255, 236, 179, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .accent-100-fg {
  color: #ffecb3 !important;
}
.light-theme .accent-100-border {
  border-color: #ffecb3 !important;
}
.light-theme .accent-100-border-top {
  border-top-color: #ffecb3 !important;
}
.light-theme .accent-100-border-right {
  border-right-color: #ffecb3 !important;
}
.light-theme .accent-100-border-bottom {
  border-bottom-color: #ffecb3 !important;
}
.light-theme .accent-100-border-left {
  border-left-color: #ffecb3 !important;
}
.light-theme .accent-200-bg {
  background-color: #ffe082 !important;
}
.light-theme .accent-200 {
  background-color: #ffe082 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .accent-200[disabled] {
  background-color: rgba(255, 224, 130, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .accent-200-fg {
  color: #ffe082 !important;
}
.light-theme .accent-200-border {
  border-color: #ffe082 !important;
}
.light-theme .accent-200-border-top {
  border-top-color: #ffe082 !important;
}
.light-theme .accent-200-border-right {
  border-right-color: #ffe082 !important;
}
.light-theme .accent-200-border-bottom {
  border-bottom-color: #ffe082 !important;
}
.light-theme .accent-200-border-left {
  border-left-color: #ffe082 !important;
}
.light-theme .accent-300-bg {
  background-color: #ffd54f !important;
}
.light-theme .accent-300 {
  background-color: #ffd54f !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .accent-300[disabled] {
  background-color: rgba(255, 213, 79, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .accent-300-fg {
  color: #ffd54f !important;
}
.light-theme .accent-300-border {
  border-color: #ffd54f !important;
}
.light-theme .accent-300-border-top {
  border-top-color: #ffd54f !important;
}
.light-theme .accent-300-border-right {
  border-right-color: #ffd54f !important;
}
.light-theme .accent-300-border-bottom {
  border-bottom-color: #ffd54f !important;
}
.light-theme .accent-300-border-left {
  border-left-color: #ffd54f !important;
}
.light-theme .accent-400-bg {
  background-color: #ffca28 !important;
}
.light-theme .accent-400 {
  background-color: #ffca28 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .accent-400[disabled] {
  background-color: rgba(255, 202, 40, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .accent-400-fg {
  color: #ffca28 !important;
}
.light-theme .accent-400-border {
  border-color: #ffca28 !important;
}
.light-theme .accent-400-border-top {
  border-top-color: #ffca28 !important;
}
.light-theme .accent-400-border-right {
  border-right-color: #ffca28 !important;
}
.light-theme .accent-400-border-bottom {
  border-bottom-color: #ffca28 !important;
}
.light-theme .accent-400-border-left {
  border-left-color: #ffca28 !important;
}
.light-theme .accent-500-bg {
  background-color: #ffc107 !important;
}
.light-theme .accent-500 {
  background-color: #ffc107 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .accent-500[disabled] {
  background-color: rgba(255, 193, 7, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .accent-500-fg {
  color: #ffc107 !important;
}
.light-theme .accent-500-border {
  border-color: #ffc107 !important;
}
.light-theme .accent-500-border-top {
  border-top-color: #ffc107 !important;
}
.light-theme .accent-500-border-right {
  border-right-color: #ffc107 !important;
}
.light-theme .accent-500-border-bottom {
  border-bottom-color: #ffc107 !important;
}
.light-theme .accent-500-border-left {
  border-left-color: #ffc107 !important;
}
.light-theme .accent-600-bg {
  background-color: #ffb300 !important;
}
.light-theme .accent-600 {
  background-color: #ffb300 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .accent-600[disabled] {
  background-color: rgba(255, 179, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .accent-600-fg {
  color: #ffb300 !important;
}
.light-theme .accent-600-border {
  border-color: #ffb300 !important;
}
.light-theme .accent-600-border-top {
  border-top-color: #ffb300 !important;
}
.light-theme .accent-600-border-right {
  border-right-color: #ffb300 !important;
}
.light-theme .accent-600-border-bottom {
  border-bottom-color: #ffb300 !important;
}
.light-theme .accent-600-border-left {
  border-left-color: #ffb300 !important;
}
.light-theme .accent-700-bg {
  background-color: #ffa000 !important;
}
.light-theme .accent-700 {
  background-color: #ffa000 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .accent-700[disabled] {
  background-color: rgba(255, 160, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .accent-700-fg {
  color: #ffa000 !important;
}
.light-theme .accent-700-border {
  border-color: #ffa000 !important;
}
.light-theme .accent-700-border-top {
  border-top-color: #ffa000 !important;
}
.light-theme .accent-700-border-right {
  border-right-color: #ffa000 !important;
}
.light-theme .accent-700-border-bottom {
  border-bottom-color: #ffa000 !important;
}
.light-theme .accent-700-border-left {
  border-left-color: #ffa000 !important;
}
.light-theme .accent-800-bg {
  background-color: #ff8f00 !important;
}
.light-theme .accent-800 {
  background-color: #ff8f00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .accent-800[disabled] {
  background-color: rgba(255, 143, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .accent-800-fg {
  color: #ff8f00 !important;
}
.light-theme .accent-800-border {
  border-color: #ff8f00 !important;
}
.light-theme .accent-800-border-top {
  border-top-color: #ff8f00 !important;
}
.light-theme .accent-800-border-right {
  border-right-color: #ff8f00 !important;
}
.light-theme .accent-800-border-bottom {
  border-bottom-color: #ff8f00 !important;
}
.light-theme .accent-800-border-left {
  border-left-color: #ff8f00 !important;
}
.light-theme .accent-900-bg {
  background-color: #ff6f00 !important;
}
.light-theme .accent-900 {
  background-color: #ff6f00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .accent-900[disabled] {
  background-color: rgba(255, 111, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .accent-900-fg {
  color: #ff6f00 !important;
}
.light-theme .accent-900-border {
  border-color: #ff6f00 !important;
}
.light-theme .accent-900-border-top {
  border-top-color: #ff6f00 !important;
}
.light-theme .accent-900-border-right {
  border-right-color: #ff6f00 !important;
}
.light-theme .accent-900-border-bottom {
  border-bottom-color: #ff6f00 !important;
}
.light-theme .accent-900-border-left {
  border-left-color: #ff6f00 !important;
}
.light-theme .accent-A100-bg {
  background-color: #ffe57f !important;
}
.light-theme .accent-A100 {
  background-color: #ffe57f !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .accent-A100[disabled] {
  background-color: rgba(255, 229, 127, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .accent-A100-fg {
  color: #ffe57f !important;
}
.light-theme .accent-A100-border {
  border-color: #ffe57f !important;
}
.light-theme .accent-A100-border-top {
  border-top-color: #ffe57f !important;
}
.light-theme .accent-A100-border-right {
  border-right-color: #ffe57f !important;
}
.light-theme .accent-A100-border-bottom {
  border-bottom-color: #ffe57f !important;
}
.light-theme .accent-A100-border-left {
  border-left-color: #ffe57f !important;
}
.light-theme .accent-A200-bg {
  background-color: #ffd740 !important;
}
.light-theme .accent-A200 {
  background-color: #ffd740 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .accent-A200[disabled] {
  background-color: rgba(255, 215, 64, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .accent-A200-fg {
  color: #ffd740 !important;
}
.light-theme .accent-A200-border {
  border-color: #ffd740 !important;
}
.light-theme .accent-A200-border-top {
  border-top-color: #ffd740 !important;
}
.light-theme .accent-A200-border-right {
  border-right-color: #ffd740 !important;
}
.light-theme .accent-A200-border-bottom {
  border-bottom-color: #ffd740 !important;
}
.light-theme .accent-A200-border-left {
  border-left-color: #ffd740 !important;
}
.light-theme .accent-A400-bg {
  background-color: #ffc400 !important;
}
.light-theme .accent-A400 {
  background-color: #ffc400 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .accent-A400[disabled] {
  background-color: rgba(255, 196, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .accent-A400-fg {
  color: #ffc400 !important;
}
.light-theme .accent-A400-border {
  border-color: #ffc400 !important;
}
.light-theme .accent-A400-border-top {
  border-top-color: #ffc400 !important;
}
.light-theme .accent-A400-border-right {
  border-right-color: #ffc400 !important;
}
.light-theme .accent-A400-border-bottom {
  border-bottom-color: #ffc400 !important;
}
.light-theme .accent-A400-border-left {
  border-left-color: #ffc400 !important;
}
.light-theme .accent-A700-bg {
  background-color: #ffab00 !important;
}
.light-theme .accent-A700 {
  background-color: #ffab00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .accent-A700[disabled] {
  background-color: rgba(255, 171, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .accent-A700-fg {
  color: #ffab00 !important;
}
.light-theme .accent-A700-border {
  border-color: #ffab00 !important;
}
.light-theme .accent-A700-border-top {
  border-top-color: #ffab00 !important;
}
.light-theme .accent-A700-border-right {
  border-right-color: #ffab00 !important;
}
.light-theme .accent-A700-border-bottom {
  border-bottom-color: #ffab00 !important;
}
.light-theme .accent-A700-border-left {
  border-left-color: #ffab00 !important;
}
.light-theme .accent-bg {
  background-color: #ffc107 !important;
}
.light-theme .accent {
  background-color: #ffc107 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .accent[disabled] {
  background-color: rgba(255, 193, 7, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .accent-fg {
  color: #ffc107 !important;
}
.light-theme .accent-border {
  border-color: #ffc107 !important;
}
.light-theme .accent-border-top {
  border-top-color: #ffc107 !important;
}
.light-theme .accent-border-right {
  border-right-color: #ffc107 !important;
}
.light-theme .accent-border-bottom {
  border-bottom-color: #ffc107 !important;
}
.light-theme .accent-border-left {
  border-left-color: #ffc107 !important;
}
.light-theme .white-50-bg {
  background-color: white !important;
}
.light-theme .white-50 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .white-50[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .white-50-fg {
  color: white !important;
}
.light-theme .white-50-border {
  border-color: white !important;
}
.light-theme .white-50-border-top {
  border-top-color: white !important;
}
.light-theme .white-50-border-right {
  border-right-color: white !important;
}
.light-theme .white-50-border-bottom {
  border-bottom-color: white !important;
}
.light-theme .white-50-border-left {
  border-left-color: white !important;
}
.light-theme .white-100-bg {
  background-color: white !important;
}
.light-theme .white-100 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .white-100[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .white-100-fg {
  color: white !important;
}
.light-theme .white-100-border {
  border-color: white !important;
}
.light-theme .white-100-border-top {
  border-top-color: white !important;
}
.light-theme .white-100-border-right {
  border-right-color: white !important;
}
.light-theme .white-100-border-bottom {
  border-bottom-color: white !important;
}
.light-theme .white-100-border-left {
  border-left-color: white !important;
}
.light-theme .white-200-bg {
  background-color: white !important;
}
.light-theme .white-200 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .white-200[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .white-200-fg {
  color: white !important;
}
.light-theme .white-200-border {
  border-color: white !important;
}
.light-theme .white-200-border-top {
  border-top-color: white !important;
}
.light-theme .white-200-border-right {
  border-right-color: white !important;
}
.light-theme .white-200-border-bottom {
  border-bottom-color: white !important;
}
.light-theme .white-200-border-left {
  border-left-color: white !important;
}
.light-theme .white-300-bg {
  background-color: white !important;
}
.light-theme .white-300 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .white-300[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .white-300-fg {
  color: white !important;
}
.light-theme .white-300-border {
  border-color: white !important;
}
.light-theme .white-300-border-top {
  border-top-color: white !important;
}
.light-theme .white-300-border-right {
  border-right-color: white !important;
}
.light-theme .white-300-border-bottom {
  border-bottom-color: white !important;
}
.light-theme .white-300-border-left {
  border-left-color: white !important;
}
.light-theme .white-400-bg {
  background-color: white !important;
}
.light-theme .white-400 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .white-400[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .white-400-fg {
  color: white !important;
}
.light-theme .white-400-border {
  border-color: white !important;
}
.light-theme .white-400-border-top {
  border-top-color: white !important;
}
.light-theme .white-400-border-right {
  border-right-color: white !important;
}
.light-theme .white-400-border-bottom {
  border-bottom-color: white !important;
}
.light-theme .white-400-border-left {
  border-left-color: white !important;
}
.light-theme .white-500-bg {
  background-color: white !important;
}
.light-theme .white-500 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .white-500[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .white-500-fg {
  color: white !important;
}
.light-theme .white-500-border {
  border-color: white !important;
}
.light-theme .white-500-border-top {
  border-top-color: white !important;
}
.light-theme .white-500-border-right {
  border-right-color: white !important;
}
.light-theme .white-500-border-bottom {
  border-bottom-color: white !important;
}
.light-theme .white-500-border-left {
  border-left-color: white !important;
}
.light-theme .white-bg {
  background-color: white !important;
}
.light-theme .white {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .white[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .white-fg {
  color: white !important;
}
.light-theme .white-border {
  border-color: white !important;
}
.light-theme .white-border-top {
  border-top-color: white !important;
}
.light-theme .white-border-right {
  border-right-color: white !important;
}
.light-theme .white-border-bottom {
  border-bottom-color: white !important;
}
.light-theme .white-border-left {
  border-left-color: white !important;
}
.light-theme .white-600-bg {
  background-color: white !important;
}
.light-theme .white-600 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .white-600[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .white-600-fg {
  color: white !important;
}
.light-theme .white-600-border {
  border-color: white !important;
}
.light-theme .white-600-border-top {
  border-top-color: white !important;
}
.light-theme .white-600-border-right {
  border-right-color: white !important;
}
.light-theme .white-600-border-bottom {
  border-bottom-color: white !important;
}
.light-theme .white-600-border-left {
  border-left-color: white !important;
}
.light-theme .white-700-bg {
  background-color: white !important;
}
.light-theme .white-700 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .white-700[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .white-700-fg {
  color: white !important;
}
.light-theme .white-700-border {
  border-color: white !important;
}
.light-theme .white-700-border-top {
  border-top-color: white !important;
}
.light-theme .white-700-border-right {
  border-right-color: white !important;
}
.light-theme .white-700-border-bottom {
  border-bottom-color: white !important;
}
.light-theme .white-700-border-left {
  border-left-color: white !important;
}
.light-theme .white-800-bg {
  background-color: white !important;
}
.light-theme .white-800 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .white-800[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .white-800-fg {
  color: white !important;
}
.light-theme .white-800-border {
  border-color: white !important;
}
.light-theme .white-800-border-top {
  border-top-color: white !important;
}
.light-theme .white-800-border-right {
  border-right-color: white !important;
}
.light-theme .white-800-border-bottom {
  border-bottom-color: white !important;
}
.light-theme .white-800-border-left {
  border-left-color: white !important;
}
.light-theme .white-900-bg {
  background-color: white !important;
}
.light-theme .white-900 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .white-900[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .white-900-fg {
  color: white !important;
}
.light-theme .white-900-border {
  border-color: white !important;
}
.light-theme .white-900-border-top {
  border-top-color: white !important;
}
.light-theme .white-900-border-right {
  border-right-color: white !important;
}
.light-theme .white-900-border-bottom {
  border-bottom-color: white !important;
}
.light-theme .white-900-border-left {
  border-left-color: white !important;
}
.light-theme .white-A100-bg {
  background-color: white !important;
}
.light-theme .white-A100 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .white-A100[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .white-A100-fg {
  color: white !important;
}
.light-theme .white-A100-border {
  border-color: white !important;
}
.light-theme .white-A100-border-top {
  border-top-color: white !important;
}
.light-theme .white-A100-border-right {
  border-right-color: white !important;
}
.light-theme .white-A100-border-bottom {
  border-bottom-color: white !important;
}
.light-theme .white-A100-border-left {
  border-left-color: white !important;
}
.light-theme .white-A200-bg {
  background-color: white !important;
}
.light-theme .white-A200 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .white-A200[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .white-A200-fg {
  color: white !important;
}
.light-theme .white-A200-border {
  border-color: white !important;
}
.light-theme .white-A200-border-top {
  border-top-color: white !important;
}
.light-theme .white-A200-border-right {
  border-right-color: white !important;
}
.light-theme .white-A200-border-bottom {
  border-bottom-color: white !important;
}
.light-theme .white-A200-border-left {
  border-left-color: white !important;
}
.light-theme .white-A400-bg {
  background-color: white !important;
}
.light-theme .white-A400 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .white-A400[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .white-A400-fg {
  color: white !important;
}
.light-theme .white-A400-border {
  border-color: white !important;
}
.light-theme .white-A400-border-top {
  border-top-color: white !important;
}
.light-theme .white-A400-border-right {
  border-right-color: white !important;
}
.light-theme .white-A400-border-bottom {
  border-bottom-color: white !important;
}
.light-theme .white-A400-border-left {
  border-left-color: white !important;
}
.light-theme .white-A700-bg {
  background-color: white !important;
}
.light-theme .white-A700 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .white-A700[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .white-A700-fg {
  color: white !important;
}
.light-theme .white-A700-border {
  border-color: white !important;
}
.light-theme .white-A700-border-top {
  border-top-color: white !important;
}
.light-theme .white-A700-border-right {
  border-right-color: white !important;
}
.light-theme .white-A700-border-bottom {
  border-bottom-color: white !important;
}
.light-theme .white-A700-border-left {
  border-left-color: white !important;
}
.light-theme .warn-50-bg {
  background-color: #ffebee !important;
}
.light-theme .warn-50 {
  background-color: #ffebee !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .warn-50[disabled] {
  background-color: rgba(255, 235, 238, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .warn-50-fg {
  color: #ffebee !important;
}
.light-theme .warn-50-border {
  border-color: #ffebee !important;
}
.light-theme .warn-50-border-top {
  border-top-color: #ffebee !important;
}
.light-theme .warn-50-border-right {
  border-right-color: #ffebee !important;
}
.light-theme .warn-50-border-bottom {
  border-bottom-color: #ffebee !important;
}
.light-theme .warn-50-border-left {
  border-left-color: #ffebee !important;
}
.light-theme .warn-100-bg {
  background-color: #ffcdd2 !important;
}
.light-theme .warn-100 {
  background-color: #ffcdd2 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .warn-100[disabled] {
  background-color: rgba(255, 205, 210, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .warn-100-fg {
  color: #ffcdd2 !important;
}
.light-theme .warn-100-border {
  border-color: #ffcdd2 !important;
}
.light-theme .warn-100-border-top {
  border-top-color: #ffcdd2 !important;
}
.light-theme .warn-100-border-right {
  border-right-color: #ffcdd2 !important;
}
.light-theme .warn-100-border-bottom {
  border-bottom-color: #ffcdd2 !important;
}
.light-theme .warn-100-border-left {
  border-left-color: #ffcdd2 !important;
}
.light-theme .warn-200-bg {
  background-color: #ef9a9a !important;
}
.light-theme .warn-200 {
  background-color: #ef9a9a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .warn-200[disabled] {
  background-color: rgba(239, 154, 154, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .warn-200-fg {
  color: #ef9a9a !important;
}
.light-theme .warn-200-border {
  border-color: #ef9a9a !important;
}
.light-theme .warn-200-border-top {
  border-top-color: #ef9a9a !important;
}
.light-theme .warn-200-border-right {
  border-right-color: #ef9a9a !important;
}
.light-theme .warn-200-border-bottom {
  border-bottom-color: #ef9a9a !important;
}
.light-theme .warn-200-border-left {
  border-left-color: #ef9a9a !important;
}
.light-theme .warn-300-bg {
  background-color: #e57373 !important;
}
.light-theme .warn-300 {
  background-color: #e57373 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .warn-300[disabled] {
  background-color: rgba(229, 115, 115, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .warn-300-fg {
  color: #e57373 !important;
}
.light-theme .warn-300-border {
  border-color: #e57373 !important;
}
.light-theme .warn-300-border-top {
  border-top-color: #e57373 !important;
}
.light-theme .warn-300-border-right {
  border-right-color: #e57373 !important;
}
.light-theme .warn-300-border-bottom {
  border-bottom-color: #e57373 !important;
}
.light-theme .warn-300-border-left {
  border-left-color: #e57373 !important;
}
.light-theme .warn-400-bg {
  background-color: #ef5350 !important;
}
.light-theme .warn-400 {
  background-color: #ef5350 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .warn-400[disabled] {
  background-color: rgba(239, 83, 80, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .warn-400-fg {
  color: #ef5350 !important;
}
.light-theme .warn-400-border {
  border-color: #ef5350 !important;
}
.light-theme .warn-400-border-top {
  border-top-color: #ef5350 !important;
}
.light-theme .warn-400-border-right {
  border-right-color: #ef5350 !important;
}
.light-theme .warn-400-border-bottom {
  border-bottom-color: #ef5350 !important;
}
.light-theme .warn-400-border-left {
  border-left-color: #ef5350 !important;
}
.light-theme .warn-500-bg {
  background-color: #f44336 !important;
}
.light-theme .warn-500 {
  background-color: #f44336 !important;
  color: white !important;
}
.light-theme .warn-500[disabled] {
  background-color: rgba(244, 67, 54, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.light-theme .warn-500-fg {
  color: #f44336 !important;
}
.light-theme .warn-500-border {
  border-color: #f44336 !important;
}
.light-theme .warn-500-border-top {
  border-top-color: #f44336 !important;
}
.light-theme .warn-500-border-right {
  border-right-color: #f44336 !important;
}
.light-theme .warn-500-border-bottom {
  border-bottom-color: #f44336 !important;
}
.light-theme .warn-500-border-left {
  border-left-color: #f44336 !important;
}
.light-theme .warn-600-bg {
  background-color: #e53935 !important;
}
.light-theme .warn-600 {
  background-color: #e53935 !important;
  color: white !important;
}
.light-theme .warn-600[disabled] {
  background-color: rgba(229, 57, 53, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.light-theme .warn-600-fg {
  color: #e53935 !important;
}
.light-theme .warn-600-border {
  border-color: #e53935 !important;
}
.light-theme .warn-600-border-top {
  border-top-color: #e53935 !important;
}
.light-theme .warn-600-border-right {
  border-right-color: #e53935 !important;
}
.light-theme .warn-600-border-bottom {
  border-bottom-color: #e53935 !important;
}
.light-theme .warn-600-border-left {
  border-left-color: #e53935 !important;
}
.light-theme .warn-700-bg {
  background-color: #d32f2f !important;
}
.light-theme .warn-700 {
  background-color: #d32f2f !important;
  color: white !important;
}
.light-theme .warn-700[disabled] {
  background-color: rgba(211, 47, 47, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.light-theme .warn-700-fg {
  color: #d32f2f !important;
}
.light-theme .warn-700-border {
  border-color: #d32f2f !important;
}
.light-theme .warn-700-border-top {
  border-top-color: #d32f2f !important;
}
.light-theme .warn-700-border-right {
  border-right-color: #d32f2f !important;
}
.light-theme .warn-700-border-bottom {
  border-bottom-color: #d32f2f !important;
}
.light-theme .warn-700-border-left {
  border-left-color: #d32f2f !important;
}
.light-theme .warn-800-bg {
  background-color: #c62828 !important;
}
.light-theme .warn-800 {
  background-color: #c62828 !important;
  color: white !important;
}
.light-theme .warn-800[disabled] {
  background-color: rgba(198, 40, 40, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.light-theme .warn-800-fg {
  color: #c62828 !important;
}
.light-theme .warn-800-border {
  border-color: #c62828 !important;
}
.light-theme .warn-800-border-top {
  border-top-color: #c62828 !important;
}
.light-theme .warn-800-border-right {
  border-right-color: #c62828 !important;
}
.light-theme .warn-800-border-bottom {
  border-bottom-color: #c62828 !important;
}
.light-theme .warn-800-border-left {
  border-left-color: #c62828 !important;
}
.light-theme .warn-900-bg {
  background-color: #b71c1c !important;
}
.light-theme .warn-900 {
  background-color: #b71c1c !important;
  color: white !important;
}
.light-theme .warn-900[disabled] {
  background-color: rgba(183, 28, 28, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.light-theme .warn-900-fg {
  color: #b71c1c !important;
}
.light-theme .warn-900-border {
  border-color: #b71c1c !important;
}
.light-theme .warn-900-border-top {
  border-top-color: #b71c1c !important;
}
.light-theme .warn-900-border-right {
  border-right-color: #b71c1c !important;
}
.light-theme .warn-900-border-bottom {
  border-bottom-color: #b71c1c !important;
}
.light-theme .warn-900-border-left {
  border-left-color: #b71c1c !important;
}
.light-theme .warn-A100-bg {
  background-color: #ff8a80 !important;
}
.light-theme .warn-A100 {
  background-color: #ff8a80 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.light-theme .warn-A100[disabled] {
  background-color: rgba(255, 138, 128, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.light-theme .warn-A100-fg {
  color: #ff8a80 !important;
}
.light-theme .warn-A100-border {
  border-color: #ff8a80 !important;
}
.light-theme .warn-A100-border-top {
  border-top-color: #ff8a80 !important;
}
.light-theme .warn-A100-border-right {
  border-right-color: #ff8a80 !important;
}
.light-theme .warn-A100-border-bottom {
  border-bottom-color: #ff8a80 !important;
}
.light-theme .warn-A100-border-left {
  border-left-color: #ff8a80 !important;
}
.light-theme .warn-A200-bg {
  background-color: #ff5252 !important;
}
.light-theme .warn-A200 {
  background-color: #ff5252 !important;
  color: white !important;
}
.light-theme .warn-A200[disabled] {
  background-color: rgba(255, 82, 82, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.light-theme .warn-A200-fg {
  color: #ff5252 !important;
}
.light-theme .warn-A200-border {
  border-color: #ff5252 !important;
}
.light-theme .warn-A200-border-top {
  border-top-color: #ff5252 !important;
}
.light-theme .warn-A200-border-right {
  border-right-color: #ff5252 !important;
}
.light-theme .warn-A200-border-bottom {
  border-bottom-color: #ff5252 !important;
}
.light-theme .warn-A200-border-left {
  border-left-color: #ff5252 !important;
}
.light-theme .warn-A400-bg {
  background-color: #ff1744 !important;
}
.light-theme .warn-A400 {
  background-color: #ff1744 !important;
  color: white !important;
}
.light-theme .warn-A400[disabled] {
  background-color: rgba(255, 23, 68, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.light-theme .warn-A400-fg {
  color: #ff1744 !important;
}
.light-theme .warn-A400-border {
  border-color: #ff1744 !important;
}
.light-theme .warn-A400-border-top {
  border-top-color: #ff1744 !important;
}
.light-theme .warn-A400-border-right {
  border-right-color: #ff1744 !important;
}
.light-theme .warn-A400-border-bottom {
  border-bottom-color: #ff1744 !important;
}
.light-theme .warn-A400-border-left {
  border-left-color: #ff1744 !important;
}
.light-theme .warn-A700-bg {
  background-color: #d50000 !important;
}
.light-theme .warn-A700 {
  background-color: #d50000 !important;
  color: white !important;
}
.light-theme .warn-A700[disabled] {
  background-color: rgba(213, 0, 0, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.light-theme .warn-A700-fg {
  color: #d50000 !important;
}
.light-theme .warn-A700-border {
  border-color: #d50000 !important;
}
.light-theme .warn-A700-border-top {
  border-top-color: #d50000 !important;
}
.light-theme .warn-A700-border-right {
  border-right-color: #d50000 !important;
}
.light-theme .warn-A700-border-bottom {
  border-bottom-color: #d50000 !important;
}
.light-theme .warn-A700-border-left {
  border-left-color: #d50000 !important;
}
.light-theme .warn-bg {
  background-color: #f44336 !important;
}
.light-theme .warn {
  background-color: #f44336 !important;
  color: white !important;
}
.light-theme .warn[disabled] {
  background-color: rgba(244, 67, 54, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.light-theme .warn-fg {
  color: #f44336 !important;
}
.light-theme .warn-border {
  border-color: #f44336 !important;
}
.light-theme .warn-border-top {
  border-top-color: #f44336 !important;
}
.light-theme .warn-border-right {
  border-right-color: #f44336 !important;
}
.light-theme .warn-border-bottom {
  border-bottom-color: #f44336 !important;
}
.light-theme .warn-border-left {
  border-left-color: #f44336 !important;
}
.light-theme #theme-color-ref {
  background-color: #00796b !important;
}
.light-theme mat-chip.mat-chip.mat-standard-chip {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.light-theme mat-chip.mat-chip.mat-standard-chip .mat-icon {
  color: rgba(0, 0, 0, 0.54);
}

.dark-theme .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-option {
  color: white;
}
.dark-theme .mat-option:hover:not(.mat-option-disabled), .dark-theme .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.dark-theme .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.dark-theme .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.dark-theme .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #80cbc4;
}
.dark-theme .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ffc107;
}
.dark-theme .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ff5722;
}
.dark-theme .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-pseudo-checkbox::after {
  color: #303030;
}
.dark-theme .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.dark-theme .mat-primary .mat-pseudo-checkbox-checked,
.dark-theme .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #80cbc4;
}
.dark-theme .mat-pseudo-checkbox-checked,
.dark-theme .mat-pseudo-checkbox-indeterminate,
.dark-theme .mat-accent .mat-pseudo-checkbox-checked,
.dark-theme .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ffc107;
}
.dark-theme .mat-warn .mat-pseudo-checkbox-checked,
.dark-theme .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #ff5722;
}
.dark-theme .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.dark-theme .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.dark-theme .mat-app-background, .dark-theme.mat-app-background {
  background-color: #303030;
  color: white;
}
.dark-theme .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.dark-theme .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.dark-theme .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.dark-theme .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.dark-theme .mat-badge {
  position: relative;
}
.dark-theme .mat-badge.mat-badge {
  overflow: visible;
}
.dark-theme .mat-badge-hidden .mat-badge-content {
  display: none;
}
.dark-theme .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.dark-theme .ng-animate-disabled .mat-badge-content,
.dark-theme .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.dark-theme .mat-badge-content.mat-badge-active {
  transform: none;
}
.dark-theme .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.dark-theme .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.dark-theme .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.dark-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .dark-theme .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.dark-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .dark-theme .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.dark-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .dark-theme .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.dark-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .dark-theme .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.dark-theme .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.dark-theme .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.dark-theme .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.dark-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .dark-theme .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.dark-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .dark-theme .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.dark-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .dark-theme .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.dark-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .dark-theme .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.dark-theme .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.dark-theme .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.dark-theme .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.dark-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .dark-theme .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.dark-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .dark-theme .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.dark-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .dark-theme .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.dark-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .dark-theme .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.dark-theme .mat-badge-content {
  color: rgba(0, 0, 0, 0.87);
  background: #80cbc4;
}
.cdk-high-contrast-active .dark-theme .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.dark-theme .mat-badge-accent .mat-badge-content {
  background: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.dark-theme .mat-badge-warn .mat-badge-content {
  color: white;
  background: #ff5722;
}
.dark-theme .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.dark-theme .mat-button, .dark-theme .mat-icon-button, .dark-theme .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.dark-theme .mat-button.mat-primary, .dark-theme .mat-icon-button.mat-primary, .dark-theme .mat-stroked-button.mat-primary {
  color: #80cbc4;
}
.dark-theme .mat-button.mat-accent, .dark-theme .mat-icon-button.mat-accent, .dark-theme .mat-stroked-button.mat-accent {
  color: #ffc107;
}
.dark-theme .mat-button.mat-warn, .dark-theme .mat-icon-button.mat-warn, .dark-theme .mat-stroked-button.mat-warn {
  color: #ff5722;
}
.dark-theme .mat-button.mat-primary.mat-button-disabled, .dark-theme .mat-button.mat-accent.mat-button-disabled, .dark-theme .mat-button.mat-warn.mat-button-disabled, .dark-theme .mat-button.mat-button-disabled.mat-button-disabled, .dark-theme .mat-icon-button.mat-primary.mat-button-disabled, .dark-theme .mat-icon-button.mat-accent.mat-button-disabled, .dark-theme .mat-icon-button.mat-warn.mat-button-disabled, .dark-theme .mat-icon-button.mat-button-disabled.mat-button-disabled, .dark-theme .mat-stroked-button.mat-primary.mat-button-disabled, .dark-theme .mat-stroked-button.mat-accent.mat-button-disabled, .dark-theme .mat-stroked-button.mat-warn.mat-button-disabled, .dark-theme .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.dark-theme .mat-button.mat-primary .mat-button-focus-overlay, .dark-theme .mat-icon-button.mat-primary .mat-button-focus-overlay, .dark-theme .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #80cbc4;
}
.dark-theme .mat-button.mat-accent .mat-button-focus-overlay, .dark-theme .mat-icon-button.mat-accent .mat-button-focus-overlay, .dark-theme .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ffc107;
}
.dark-theme .mat-button.mat-warn .mat-button-focus-overlay, .dark-theme .mat-icon-button.mat-warn .mat-button-focus-overlay, .dark-theme .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #ff5722;
}
.dark-theme .mat-button.mat-button-disabled .mat-button-focus-overlay, .dark-theme .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .dark-theme .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.dark-theme .mat-button .mat-ripple-element, .dark-theme .mat-icon-button .mat-ripple-element, .dark-theme .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.dark-theme .mat-button-focus-overlay {
  background: white;
}
.dark-theme .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-flat-button, .dark-theme .mat-raised-button, .dark-theme .mat-fab, .dark-theme .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.dark-theme .mat-flat-button.mat-primary, .dark-theme .mat-raised-button.mat-primary, .dark-theme .mat-fab.mat-primary, .dark-theme .mat-mini-fab.mat-primary {
  color: rgba(0, 0, 0, 0.87);
}
.dark-theme .mat-flat-button.mat-accent, .dark-theme .mat-raised-button.mat-accent, .dark-theme .mat-fab.mat-accent, .dark-theme .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.dark-theme .mat-flat-button.mat-warn, .dark-theme .mat-raised-button.mat-warn, .dark-theme .mat-fab.mat-warn, .dark-theme .mat-mini-fab.mat-warn {
  color: white;
}
.dark-theme .mat-flat-button.mat-primary.mat-button-disabled, .dark-theme .mat-flat-button.mat-accent.mat-button-disabled, .dark-theme .mat-flat-button.mat-warn.mat-button-disabled, .dark-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .dark-theme .mat-raised-button.mat-primary.mat-button-disabled, .dark-theme .mat-raised-button.mat-accent.mat-button-disabled, .dark-theme .mat-raised-button.mat-warn.mat-button-disabled, .dark-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .dark-theme .mat-fab.mat-primary.mat-button-disabled, .dark-theme .mat-fab.mat-accent.mat-button-disabled, .dark-theme .mat-fab.mat-warn.mat-button-disabled, .dark-theme .mat-fab.mat-button-disabled.mat-button-disabled, .dark-theme .mat-mini-fab.mat-primary.mat-button-disabled, .dark-theme .mat-mini-fab.mat-accent.mat-button-disabled, .dark-theme .mat-mini-fab.mat-warn.mat-button-disabled, .dark-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.dark-theme .mat-flat-button.mat-primary, .dark-theme .mat-raised-button.mat-primary, .dark-theme .mat-fab.mat-primary, .dark-theme .mat-mini-fab.mat-primary {
  background-color: #80cbc4;
}
.dark-theme .mat-flat-button.mat-accent, .dark-theme .mat-raised-button.mat-accent, .dark-theme .mat-fab.mat-accent, .dark-theme .mat-mini-fab.mat-accent {
  background-color: #ffc107;
}
.dark-theme .mat-flat-button.mat-warn, .dark-theme .mat-raised-button.mat-warn, .dark-theme .mat-fab.mat-warn, .dark-theme .mat-mini-fab.mat-warn {
  background-color: #ff5722;
}
.dark-theme .mat-flat-button.mat-primary.mat-button-disabled, .dark-theme .mat-flat-button.mat-accent.mat-button-disabled, .dark-theme .mat-flat-button.mat-warn.mat-button-disabled, .dark-theme .mat-flat-button.mat-button-disabled.mat-button-disabled, .dark-theme .mat-raised-button.mat-primary.mat-button-disabled, .dark-theme .mat-raised-button.mat-accent.mat-button-disabled, .dark-theme .mat-raised-button.mat-warn.mat-button-disabled, .dark-theme .mat-raised-button.mat-button-disabled.mat-button-disabled, .dark-theme .mat-fab.mat-primary.mat-button-disabled, .dark-theme .mat-fab.mat-accent.mat-button-disabled, .dark-theme .mat-fab.mat-warn.mat-button-disabled, .dark-theme .mat-fab.mat-button-disabled.mat-button-disabled, .dark-theme .mat-mini-fab.mat-primary.mat-button-disabled, .dark-theme .mat-mini-fab.mat-accent.mat-button-disabled, .dark-theme .mat-mini-fab.mat-warn.mat-button-disabled, .dark-theme .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-flat-button.mat-primary .mat-ripple-element, .dark-theme .mat-raised-button.mat-primary .mat-ripple-element, .dark-theme .mat-fab.mat-primary .mat-ripple-element, .dark-theme .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.dark-theme .mat-flat-button.mat-accent .mat-ripple-element, .dark-theme .mat-raised-button.mat-accent .mat-ripple-element, .dark-theme .mat-fab.mat-accent .mat-ripple-element, .dark-theme .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.dark-theme .mat-flat-button.mat-warn .mat-ripple-element, .dark-theme .mat-raised-button.mat-warn .mat-ripple-element, .dark-theme .mat-fab.mat-warn .mat-ripple-element, .dark-theme .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-stroked-button:not([class*=mat-elevation-z]), .dark-theme .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-fab:not([class*=mat-elevation-z]), .dark-theme .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .dark-theme .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .dark-theme .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.dark-theme .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.dark-theme .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.dark-theme .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.dark-theme .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.dark-theme .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.dark-theme [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.dark-theme .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.dark-theme .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.dark-theme .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.dark-theme .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.dark-theme .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.dark-theme .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.dark-theme .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.dark-theme .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}
.dark-theme .mat-card {
  background: #424242;
  color: white;
}
.dark-theme .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-checkbox-checkmark {
  fill: #303030;
}
.dark-theme .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.dark-theme .mat-checkbox-mixedmark {
  background-color: #303030;
}
.dark-theme .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .dark-theme .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #80cbc4;
}
.dark-theme .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .dark-theme .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ffc107;
}
.dark-theme .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .dark-theme .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #ff5722;
}
.dark-theme .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .dark-theme .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.dark-theme .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.dark-theme .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.dark-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.dark-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #80cbc4;
}
.dark-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.dark-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ffc107;
}
.dark-theme .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.dark-theme .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #ff5722;
}
.dark-theme .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.dark-theme .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.dark-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.dark-theme .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.dark-theme .mat-chip.mat-standard-chip::after {
  background: white;
}
.dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #80cbc4;
  color: rgba(0, 0, 0, 0.87);
}
.dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #ff5722;
  color: white;
}
.dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.dark-theme .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.dark-theme .mat-table {
  background: #424242;
}
.dark-theme .mat-table thead, .dark-theme .mat-table tbody, .dark-theme .mat-table tfoot,
.dark-theme mat-header-row, .dark-theme mat-row, .dark-theme mat-footer-row,
.dark-theme [mat-header-row], .dark-theme [mat-row], .dark-theme [mat-footer-row],
.dark-theme .mat-table-sticky {
  background: inherit;
}
.dark-theme mat-row, .dark-theme mat-header-row, .dark-theme mat-footer-row,
.dark-theme th.mat-header-cell, .dark-theme td.mat-cell, .dark-theme td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-cell, .dark-theme .mat-footer-cell {
  color: white;
}
.dark-theme .mat-calendar-arrow {
  fill: white;
}
.dark-theme .mat-datepicker-toggle,
.dark-theme .mat-datepicker-content .mat-calendar-next-button,
.dark-theme .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.dark-theme .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-calendar-table-header,
.dark-theme .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-calendar-body-cell-content,
.dark-theme .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.dark-theme .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.dark-theme .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.dark-theme .mat-calendar-body-in-range::before {
  background: rgba(128, 203, 196, 0.2);
}
.dark-theme .mat-calendar-body-comparison-identical,
.dark-theme .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.dark-theme .mat-calendar-body-comparison-bridge-start::before,
.dark-theme [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(128, 203, 196, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme .mat-calendar-body-comparison-bridge-end::before,
.dark-theme [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(128, 203, 196, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.dark-theme .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.dark-theme .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.dark-theme .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.dark-theme .mat-calendar-body-selected {
  background-color: #80cbc4;
  color: rgba(0, 0, 0, 0.87);
}
.dark-theme .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(128, 203, 196, 0.4);
}
.dark-theme .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.dark-theme .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.dark-theme .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(128, 203, 196, 0.3);
}
@media (hover: hover) {
  .dark-theme .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(128, 203, 196, 0.3);
  }
}
.dark-theme .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 193, 7, 0.2);
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.dark-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 193, 7, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.dark-theme .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 193, 7, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 193, 7, 0.4);
}
.dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.dark-theme .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.dark-theme .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 193, 7, 0.3);
}
@media (hover: hover) {
  .dark-theme .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 193, 7, 0.3);
  }
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(255, 87, 34, 0.2);
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.dark-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 87, 34, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.dark-theme .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 87, 34, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #ff5722;
  color: white;
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 87, 34, 0.4);
}
.dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.dark-theme .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.dark-theme .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 87, 34, 0.3);
}
@media (hover: hover) {
  .dark-theme .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 87, 34, 0.3);
  }
}
.dark-theme .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-datepicker-toggle-active {
  color: #80cbc4;
}
.dark-theme .mat-datepicker-toggle-active.mat-accent {
  color: #ffc107;
}
.dark-theme .mat-datepicker-toggle-active.mat-warn {
  color: #ff5722;
}
.dark-theme .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.dark-theme .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-expansion-panel {
  background: #424242;
  color: white;
}
.dark-theme .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .dark-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .dark-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .dark-theme .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.dark-theme .mat-expansion-panel-header-title {
  color: white;
}
.dark-theme .mat-expansion-panel-header-description,
.dark-theme .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.dark-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.dark-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.dark-theme .mat-expansion-panel-header {
  height: 48px;
}
.dark-theme .mat-expansion-panel-header.mat-expanded {
  height: 64px;
}
.dark-theme .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: #80cbc4;
}
.dark-theme .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ffc107;
}
.dark-theme .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #ff5722;
}
.dark-theme .mat-focused .mat-form-field-required-marker {
  color: #ffc107;
}
.dark-theme .mat-form-field-ripple {
  background-color: white;
}
.dark-theme .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #80cbc4;
}
.dark-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ffc107;
}
.dark-theme .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #ff5722;
}
.dark-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #80cbc4;
}
.dark-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ffc107;
}
.dark-theme .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #ff5722;
}
.dark-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #ff5722;
}
.dark-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.dark-theme .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #ff5722;
}
.dark-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.dark-theme .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #ff5722;
}
.dark-theme .mat-error {
  color: #ff5722;
}
.dark-theme .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.dark-theme .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.dark-theme .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.dark-theme .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.dark-theme .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.dark-theme .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.dark-theme .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #80cbc4;
}
.dark-theme .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ffc107;
}
.dark-theme .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #ff5722;
}
.dark-theme .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #ff5722;
}
.dark-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.dark-theme .mat-icon.mat-primary {
  color: #80cbc4;
}
.dark-theme .mat-icon.mat-accent {
  color: #ffc107;
}
.dark-theme .mat-icon.mat-warn {
  color: #ff5722;
}
.dark-theme .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-input-element:disabled,
.dark-theme .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-input-element {
  caret-color: #80cbc4;
}
.dark-theme .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.dark-theme .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.dark-theme .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ffc107;
}
.dark-theme .mat-form-field.mat-warn .mat-input-element,
.dark-theme .mat-form-field-invalid .mat-input-element {
  caret-color: #ff5722;
}
.dark-theme .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #ff5722;
}
.dark-theme .mat-list-base .mat-list-item {
  color: white;
}
.dark-theme .mat-list-base .mat-list-option {
  color: white;
}
.dark-theme .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-list-option:hover, .dark-theme .mat-list-option:focus,
.dark-theme .mat-nav-list .mat-list-item:hover,
.dark-theme .mat-nav-list .mat-list-item:focus,
.dark-theme .mat-action-list .mat-list-item:hover,
.dark-theme .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.dark-theme .mat-list-single-selected-option, .dark-theme .mat-list-single-selected-option:hover, .dark-theme .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-menu-panel {
  background: #424242;
}
.dark-theme .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-menu-item {
  background: transparent;
  color: white;
}
.dark-theme .mat-menu-item[disabled],
.dark-theme .mat-menu-item[disabled] .mat-menu-submenu-icon,
.dark-theme .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-menu-item .mat-icon-no-color,
.dark-theme .mat-menu-submenu-icon {
  color: white;
}
.dark-theme .mat-menu-item:hover:not([disabled]),
.dark-theme .mat-menu-item.cdk-program-focused:not([disabled]),
.dark-theme .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.dark-theme .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.dark-theme .mat-paginator {
  background: #424242;
}
.dark-theme .mat-paginator,
.dark-theme .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-paginator-decrement,
.dark-theme .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.dark-theme .mat-paginator-first,
.dark-theme .mat-paginator-last {
  border-top: 2px solid white;
}
.dark-theme .mat-icon-button[disabled] .mat-paginator-decrement,
.dark-theme .mat-icon-button[disabled] .mat-paginator-increment,
.dark-theme .mat-icon-button[disabled] .mat-paginator-first,
.dark-theme .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-paginator-container {
  min-height: 56px;
}
.dark-theme .mat-progress-bar-background {
  fill: #445755;
}
.dark-theme .mat-progress-bar-buffer {
  background-color: #445755;
}
.dark-theme .mat-progress-bar-fill::after {
  background-color: #80cbc4;
}
.dark-theme .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #645426;
}
.dark-theme .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #645426;
}
.dark-theme .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ffc107;
}
.dark-theme .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #643a2d;
}
.dark-theme .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #643a2d;
}
.dark-theme .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #ff5722;
}
.dark-theme .mat-progress-spinner circle, .dark-theme .mat-spinner circle {
  stroke: #80cbc4;
}
.dark-theme .mat-progress-spinner.mat-accent circle, .dark-theme .mat-spinner.mat-accent circle {
  stroke: #ffc107;
}
.dark-theme .mat-progress-spinner.mat-warn circle, .dark-theme .mat-spinner.mat-warn circle {
  stroke: #ff5722;
}
.dark-theme .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #80cbc4;
}
.dark-theme .mat-radio-button.mat-primary .mat-radio-inner-circle,
.dark-theme .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .dark-theme .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .dark-theme .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #80cbc4;
}
.dark-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffc107;
}
.dark-theme .mat-radio-button.mat-accent .mat-radio-inner-circle,
.dark-theme .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .dark-theme .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .dark-theme .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ffc107;
}
.dark-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ff5722;
}
.dark-theme .mat-radio-button.mat-warn .mat-radio-inner-circle,
.dark-theme .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .dark-theme .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .dark-theme .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #ff5722;
}
.dark-theme .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.dark-theme .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.dark-theme .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.dark-theme .mat-select-value {
  color: white;
}
.dark-theme .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-select-panel {
  background: #424242;
}
.dark-theme .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #80cbc4;
}
.dark-theme .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ffc107;
}
.dark-theme .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #ff5722;
}
.dark-theme .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #ff5722;
}
.dark-theme .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.dark-theme .mat-drawer {
  background-color: #424242;
  color: white;
}
.dark-theme .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.dark-theme .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.dark-theme [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.dark-theme [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.dark-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffc107;
}
.dark-theme .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 193, 7, 0.54);
}
.dark-theme .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ffc107;
}
.dark-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #80cbc4;
}
.dark-theme .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(128, 203, 196, 0.54);
}
.dark-theme .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #80cbc4;
}
.dark-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #ff5722;
}
.dark-theme .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 87, 34, 0.54);
}
.dark-theme .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #ff5722;
}
.dark-theme .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.dark-theme .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.dark-theme .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.dark-theme .mat-slider.mat-primary .mat-slider-track-fill,
.dark-theme .mat-slider.mat-primary .mat-slider-thumb,
.dark-theme .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #80cbc4;
}
.dark-theme .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.dark-theme .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(128, 203, 196, 0.2);
}
.dark-theme .mat-slider.mat-accent .mat-slider-track-fill,
.dark-theme .mat-slider.mat-accent .mat-slider-thumb,
.dark-theme .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ffc107;
}
.dark-theme .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.dark-theme .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 193, 7, 0.2);
}
.dark-theme .mat-slider.mat-warn .mat-slider-track-fill,
.dark-theme .mat-slider.mat-warn .mat-slider-thumb,
.dark-theme .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #ff5722;
}
.dark-theme .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.dark-theme .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(255, 87, 34, 0.2);
}
.dark-theme .mat-slider:hover .mat-slider-track-background,
.dark-theme .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.dark-theme .mat-slider.mat-slider-disabled .mat-slider-track-background,
.dark-theme .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.dark-theme .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.dark-theme .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.dark-theme .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.dark-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.dark-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.dark-theme .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.dark-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.dark-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .dark-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.dark-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .dark-theme .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.dark-theme .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.dark-theme .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.dark-theme .mat-step-header.cdk-keyboard-focused, .dark-theme .mat-step-header.cdk-program-focused, .dark-theme .mat-step-header:hover:not([aria-disabled]), .dark-theme .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.dark-theme .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .dark-theme .mat-step-header:hover {
    background: none;
  }
}
.dark-theme .mat-step-header .mat-step-label,
.dark-theme .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: rgba(0, 0, 0, 0.87);
}
.dark-theme .mat-step-header .mat-step-icon-selected,
.dark-theme .mat-step-header .mat-step-icon-state-done,
.dark-theme .mat-step-header .mat-step-icon-state-edit {
  background-color: #80cbc4;
  color: rgba(0, 0, 0, 0.87);
}
.dark-theme .mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.dark-theme .mat-step-header.mat-accent .mat-step-icon-selected,
.dark-theme .mat-step-header.mat-accent .mat-step-icon-state-done,
.dark-theme .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.dark-theme .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.dark-theme .mat-step-header.mat-warn .mat-step-icon-selected,
.dark-theme .mat-step-header.mat-warn .mat-step-icon-state-done,
.dark-theme .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #ff5722;
  color: white;
}
.dark-theme .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #ff5722;
}
.dark-theme .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.dark-theme .mat-step-header .mat-step-label.mat-step-label-error {
  color: #ff5722;
}
.dark-theme .mat-stepper-horizontal, .dark-theme .mat-stepper-vertical {
  background-color: #424242;
}
.dark-theme .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-horizontal-stepper-header::before,
.dark-theme .mat-horizontal-stepper-header::after,
.dark-theme .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-horizontal-stepper-header {
  height: 72px;
}
.dark-theme .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.dark-theme .mat-vertical-stepper-header {
  padding: 24px 24px;
}
.dark-theme .mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}
.dark-theme .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .dark-theme .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}
.dark-theme .mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}
.dark-theme .mat-sort-header-arrow {
  color: #c6c6c6;
}
.dark-theme .mat-tab-nav-bar,
.dark-theme .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.dark-theme .mat-tab-group-inverted-header .mat-tab-nav-bar,
.dark-theme .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.dark-theme .mat-tab-label, .dark-theme .mat-tab-link {
  color: white;
}
.dark-theme .mat-tab-label.mat-tab-disabled, .dark-theme .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-tab-header-pagination-chevron {
  border-color: white;
}
.dark-theme .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.dark-theme .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.dark-theme .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.dark-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(178, 223, 219, 0.3);
}
.dark-theme .mat-tab-group.mat-primary .mat-ink-bar, .dark-theme .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #80cbc4;
}
.dark-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .dark-theme .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .dark-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .dark-theme .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.dark-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 224, 130, 0.3);
}
.dark-theme .mat-tab-group.mat-accent .mat-ink-bar, .dark-theme .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ffc107;
}
.dark-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .dark-theme .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .dark-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .dark-theme .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.dark-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 188, 0.3);
}
.dark-theme .mat-tab-group.mat-warn .mat-ink-bar, .dark-theme .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #ff5722;
}
.dark-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .dark-theme .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .dark-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .dark-theme .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.dark-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(178, 223, 219, 0.3);
}
.dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header, .dark-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container, .dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #80cbc4;
}
.dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .dark-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .dark-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.dark-theme .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.dark-theme .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.dark-theme .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.dark-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 224, 130, 0.3);
}
.dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header, .dark-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container, .dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ffc107;
}
.dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .dark-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .dark-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.dark-theme .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.dark-theme .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.dark-theme .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.dark-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 204, 188, 0.3);
}
.dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header, .dark-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container, .dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #ff5722;
}
.dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .dark-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .dark-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.dark-theme .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.dark-theme .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.dark-theme .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.dark-theme .mat-toolbar {
  background: #212121;
  color: white;
}
.dark-theme .mat-toolbar.mat-primary {
  background: #80cbc4;
  color: rgba(0, 0, 0, 0.87);
}
.dark-theme .mat-toolbar.mat-accent {
  background: #ffc107;
  color: rgba(0, 0, 0, 0.87);
}
.dark-theme .mat-toolbar.mat-warn {
  background: #ff5722;
  color: white;
}
.dark-theme .mat-toolbar .mat-form-field-underline,
.dark-theme .mat-toolbar .mat-form-field-ripple,
.dark-theme .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.dark-theme .mat-toolbar .mat-form-field-label,
.dark-theme .mat-toolbar .mat-focused .mat-form-field-label,
.dark-theme .mat-toolbar .mat-select-value,
.dark-theme .mat-toolbar .mat-select-arrow,
.dark-theme .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.dark-theme .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.dark-theme .mat-toolbar-multiple-rows {
  min-height: 64px;
}
.dark-theme .mat-toolbar-row, .dark-theme .mat-toolbar-single-row {
  height: 64px;
}
@media (max-width: 599px) {
  .dark-theme .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .dark-theme .mat-toolbar-row, .dark-theme .mat-toolbar-single-row {
    height: 56px;
  }
}
.dark-theme .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.dark-theme .mat-tree {
  background: #424242;
}
.dark-theme .mat-tree-node,
.dark-theme .mat-nested-tree-node {
  color: white;
}
.dark-theme .mat-tree-node {
  min-height: 48px;
}
.dark-theme .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme .mat-simple-snackbar-action {
  color: inherit;
}
.dark-theme .list-item .title-row .item-title {
  font-size: 16px;
}
.dark-theme .list-item .title-row .item-title-suffix {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .list-item .content-row {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .list-item.album .item-image:not(:empty) {
  background-color: #212121;
}
.dark-theme .list-item.album .title-row .item-title {
  color: rgba(255, 255, 255, 0.7);
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 4px;
  line-height: 11px;
}
.dark-theme .list-item.album .title-row .item-title-suffix {
  line-height: 16px;
  margin-top: -5px;
}
.dark-theme .list-item.album .subtitle-row {
  font-size: 16px;
  line-height: 24px;
}
.dark-theme .main-app-scaffold {
  background-color: #424242;
}
.dark-theme .main-app-scaffold .mat-nav-list .mat-list-item {
  color: white;
  box-sizing: border-box;
  border-left: 0px solid currentColor;
  transition: background-color, 0.1s ease-out, color 0.1s ease-out, border 0.1s ease-out;
}
.dark-theme .main-app-scaffold .mat-nav-list .mat-list-item mat-icon {
  color: inherit;
}
.dark-theme .main-app-scaffold .mat-nav-list .mat-list-item .mat-list-item-content {
  transition: padding 0.1s ease-out;
}
.dark-theme .main-app-scaffold .mat-nav-list .mat-list-item .mat-list-item-content mat-icon {
  transition: color 0.1s ease-out;
}
.dark-theme .main-app-scaffold .mat-nav-list .mat-list-item .mat-list-item-content .mat-line {
  font-weight: inherit;
}
.dark-theme .main-app-scaffold .mat-nav-list .mat-list-item.mat-list-item-active {
  border-left: 4px solid currentColor;
  background-color: rgba(128, 203, 196, 0.08) !important;
  color: #5cbcb3;
  font-weight: 500;
}
.dark-theme .main-app-scaffold .mat-nav-list .mat-list-item.mat-list-item-active .mat-list-item-content {
  padding-left: 12px;
}
.dark-theme .toolbar-scaffold-wrapper .scroll-content {
  background-color: #424242;
}
.dark-theme .toolbar-scaffold-wrapper .loading-overlay {
  background-color: rgba(48, 48, 48, 0.8);
}
.dark-theme .profile-picture {
  background-color: #b2dfdb;
  color: rgba(0, 0, 0, 0.87);
}
.dark-theme .chat-bubble-wrapper .bubble.me {
  background-color: #ffe082;
}
.dark-theme .chat-bubble-wrapper .bubble.me::before, .dark-theme .chat-bubble-wrapper .bubble.me::after {
  color: #ffe082;
}
.dark-theme .chat-bubble-wrapper .bubble.contact {
  background-color: #b2dfdb;
}
.dark-theme .chat-bubble-wrapper .bubble.contact::before, .dark-theme .chat-bubble-wrapper .bubble.contact::after {
  color: #b2dfdb;
}
.dark-theme .chat-bubble-wrapper .bubble .message-time {
  font-size: 12px;
  line-height: 14px;
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .primary-50-bg {
  background-color: #e0f2f1 !important;
}
.dark-theme .primary-50 {
  background-color: #e0f2f1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .primary-50[disabled] {
  background-color: rgba(224, 242, 241, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .primary-50-fg {
  color: #e0f2f1 !important;
}
.dark-theme .primary-50-border {
  border-color: #e0f2f1 !important;
}
.dark-theme .primary-50-border-top {
  border-top-color: #e0f2f1 !important;
}
.dark-theme .primary-50-border-right {
  border-right-color: #e0f2f1 !important;
}
.dark-theme .primary-50-border-bottom {
  border-bottom-color: #e0f2f1 !important;
}
.dark-theme .primary-50-border-left {
  border-left-color: #e0f2f1 !important;
}
.dark-theme .primary-100-bg {
  background-color: #b2dfdb !important;
}
.dark-theme .primary-100 {
  background-color: #b2dfdb !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .primary-100[disabled] {
  background-color: rgba(178, 223, 219, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .primary-100-fg {
  color: #b2dfdb !important;
}
.dark-theme .primary-100-border {
  border-color: #b2dfdb !important;
}
.dark-theme .primary-100-border-top {
  border-top-color: #b2dfdb !important;
}
.dark-theme .primary-100-border-right {
  border-right-color: #b2dfdb !important;
}
.dark-theme .primary-100-border-bottom {
  border-bottom-color: #b2dfdb !important;
}
.dark-theme .primary-100-border-left {
  border-left-color: #b2dfdb !important;
}
.dark-theme .primary-200-bg {
  background-color: #80cbc4 !important;
}
.dark-theme .primary-200 {
  background-color: #80cbc4 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .primary-200[disabled] {
  background-color: rgba(128, 203, 196, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .primary-200-fg {
  color: #80cbc4 !important;
}
.dark-theme .primary-200-border {
  border-color: #80cbc4 !important;
}
.dark-theme .primary-200-border-top {
  border-top-color: #80cbc4 !important;
}
.dark-theme .primary-200-border-right {
  border-right-color: #80cbc4 !important;
}
.dark-theme .primary-200-border-bottom {
  border-bottom-color: #80cbc4 !important;
}
.dark-theme .primary-200-border-left {
  border-left-color: #80cbc4 !important;
}
.dark-theme .primary-300-bg {
  background-color: #4db6ac !important;
}
.dark-theme .primary-300 {
  background-color: #4db6ac !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .primary-300[disabled] {
  background-color: rgba(77, 182, 172, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .primary-300-fg {
  color: #4db6ac !important;
}
.dark-theme .primary-300-border {
  border-color: #4db6ac !important;
}
.dark-theme .primary-300-border-top {
  border-top-color: #4db6ac !important;
}
.dark-theme .primary-300-border-right {
  border-right-color: #4db6ac !important;
}
.dark-theme .primary-300-border-bottom {
  border-bottom-color: #4db6ac !important;
}
.dark-theme .primary-300-border-left {
  border-left-color: #4db6ac !important;
}
.dark-theme .primary-400-bg {
  background-color: #26a69a !important;
}
.dark-theme .primary-400 {
  background-color: #26a69a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .primary-400[disabled] {
  background-color: rgba(38, 166, 154, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .primary-400-fg {
  color: #26a69a !important;
}
.dark-theme .primary-400-border {
  border-color: #26a69a !important;
}
.dark-theme .primary-400-border-top {
  border-top-color: #26a69a !important;
}
.dark-theme .primary-400-border-right {
  border-right-color: #26a69a !important;
}
.dark-theme .primary-400-border-bottom {
  border-bottom-color: #26a69a !important;
}
.dark-theme .primary-400-border-left {
  border-left-color: #26a69a !important;
}
.dark-theme .primary-500-bg {
  background-color: #009688 !important;
}
.dark-theme .primary-500 {
  background-color: #009688 !important;
  color: white !important;
}
.dark-theme .primary-500[disabled] {
  background-color: rgba(0, 150, 136, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.dark-theme .primary-500-fg {
  color: #009688 !important;
}
.dark-theme .primary-500-border {
  border-color: #009688 !important;
}
.dark-theme .primary-500-border-top {
  border-top-color: #009688 !important;
}
.dark-theme .primary-500-border-right {
  border-right-color: #009688 !important;
}
.dark-theme .primary-500-border-bottom {
  border-bottom-color: #009688 !important;
}
.dark-theme .primary-500-border-left {
  border-left-color: #009688 !important;
}
.dark-theme .primary-600-bg {
  background-color: #00897b !important;
}
.dark-theme .primary-600 {
  background-color: #00897b !important;
  color: white !important;
}
.dark-theme .primary-600[disabled] {
  background-color: rgba(0, 137, 123, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.dark-theme .primary-600-fg {
  color: #00897b !important;
}
.dark-theme .primary-600-border {
  border-color: #00897b !important;
}
.dark-theme .primary-600-border-top {
  border-top-color: #00897b !important;
}
.dark-theme .primary-600-border-right {
  border-right-color: #00897b !important;
}
.dark-theme .primary-600-border-bottom {
  border-bottom-color: #00897b !important;
}
.dark-theme .primary-600-border-left {
  border-left-color: #00897b !important;
}
.dark-theme .primary-700-bg {
  background-color: #00796b !important;
}
.dark-theme .primary-700 {
  background-color: #00796b !important;
  color: white !important;
}
.dark-theme .primary-700[disabled] {
  background-color: rgba(0, 121, 107, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.dark-theme .primary-700-fg {
  color: #00796b !important;
}
.dark-theme .primary-700-border {
  border-color: #00796b !important;
}
.dark-theme .primary-700-border-top {
  border-top-color: #00796b !important;
}
.dark-theme .primary-700-border-right {
  border-right-color: #00796b !important;
}
.dark-theme .primary-700-border-bottom {
  border-bottom-color: #00796b !important;
}
.dark-theme .primary-700-border-left {
  border-left-color: #00796b !important;
}
.dark-theme .primary-800-bg {
  background-color: #00695c !important;
}
.dark-theme .primary-800 {
  background-color: #00695c !important;
  color: white !important;
}
.dark-theme .primary-800[disabled] {
  background-color: rgba(0, 105, 92, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.dark-theme .primary-800-fg {
  color: #00695c !important;
}
.dark-theme .primary-800-border {
  border-color: #00695c !important;
}
.dark-theme .primary-800-border-top {
  border-top-color: #00695c !important;
}
.dark-theme .primary-800-border-right {
  border-right-color: #00695c !important;
}
.dark-theme .primary-800-border-bottom {
  border-bottom-color: #00695c !important;
}
.dark-theme .primary-800-border-left {
  border-left-color: #00695c !important;
}
.dark-theme .primary-900-bg {
  background-color: #004d40 !important;
}
.dark-theme .primary-900 {
  background-color: #004d40 !important;
  color: white !important;
}
.dark-theme .primary-900[disabled] {
  background-color: rgba(0, 77, 64, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.dark-theme .primary-900-fg {
  color: #004d40 !important;
}
.dark-theme .primary-900-border {
  border-color: #004d40 !important;
}
.dark-theme .primary-900-border-top {
  border-top-color: #004d40 !important;
}
.dark-theme .primary-900-border-right {
  border-right-color: #004d40 !important;
}
.dark-theme .primary-900-border-bottom {
  border-bottom-color: #004d40 !important;
}
.dark-theme .primary-900-border-left {
  border-left-color: #004d40 !important;
}
.dark-theme .primary-A100-bg {
  background-color: #a7ffeb !important;
}
.dark-theme .primary-A100 {
  background-color: #a7ffeb !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .primary-A100[disabled] {
  background-color: rgba(167, 255, 235, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .primary-A100-fg {
  color: #a7ffeb !important;
}
.dark-theme .primary-A100-border {
  border-color: #a7ffeb !important;
}
.dark-theme .primary-A100-border-top {
  border-top-color: #a7ffeb !important;
}
.dark-theme .primary-A100-border-right {
  border-right-color: #a7ffeb !important;
}
.dark-theme .primary-A100-border-bottom {
  border-bottom-color: #a7ffeb !important;
}
.dark-theme .primary-A100-border-left {
  border-left-color: #a7ffeb !important;
}
.dark-theme .primary-A200-bg {
  background-color: #64ffda !important;
}
.dark-theme .primary-A200 {
  background-color: #64ffda !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .primary-A200[disabled] {
  background-color: rgba(100, 255, 218, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .primary-A200-fg {
  color: #64ffda !important;
}
.dark-theme .primary-A200-border {
  border-color: #64ffda !important;
}
.dark-theme .primary-A200-border-top {
  border-top-color: #64ffda !important;
}
.dark-theme .primary-A200-border-right {
  border-right-color: #64ffda !important;
}
.dark-theme .primary-A200-border-bottom {
  border-bottom-color: #64ffda !important;
}
.dark-theme .primary-A200-border-left {
  border-left-color: #64ffda !important;
}
.dark-theme .primary-A400-bg {
  background-color: #1de9b6 !important;
}
.dark-theme .primary-A400 {
  background-color: #1de9b6 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .primary-A400[disabled] {
  background-color: rgba(29, 233, 182, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .primary-A400-fg {
  color: #1de9b6 !important;
}
.dark-theme .primary-A400-border {
  border-color: #1de9b6 !important;
}
.dark-theme .primary-A400-border-top {
  border-top-color: #1de9b6 !important;
}
.dark-theme .primary-A400-border-right {
  border-right-color: #1de9b6 !important;
}
.dark-theme .primary-A400-border-bottom {
  border-bottom-color: #1de9b6 !important;
}
.dark-theme .primary-A400-border-left {
  border-left-color: #1de9b6 !important;
}
.dark-theme .primary-A700-bg {
  background-color: #00bfa5 !important;
}
.dark-theme .primary-A700 {
  background-color: #00bfa5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .primary-A700[disabled] {
  background-color: rgba(0, 191, 165, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .primary-A700-fg {
  color: #00bfa5 !important;
}
.dark-theme .primary-A700-border {
  border-color: #00bfa5 !important;
}
.dark-theme .primary-A700-border-top {
  border-top-color: #00bfa5 !important;
}
.dark-theme .primary-A700-border-right {
  border-right-color: #00bfa5 !important;
}
.dark-theme .primary-A700-border-bottom {
  border-bottom-color: #00bfa5 !important;
}
.dark-theme .primary-A700-border-left {
  border-left-color: #00bfa5 !important;
}
.dark-theme .primary-bg {
  background-color: #009688 !important;
}
.dark-theme .primary {
  background-color: #009688 !important;
  color: white !important;
}
.dark-theme .primary[disabled] {
  background-color: rgba(0, 150, 136, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.dark-theme .primary-fg {
  color: #009688 !important;
}
.dark-theme .primary-border {
  border-color: #009688 !important;
}
.dark-theme .primary-border-top {
  border-top-color: #009688 !important;
}
.dark-theme .primary-border-right {
  border-right-color: #009688 !important;
}
.dark-theme .primary-border-bottom {
  border-bottom-color: #009688 !important;
}
.dark-theme .primary-border-left {
  border-left-color: #009688 !important;
}
.dark-theme .accent-50-bg {
  background-color: #fff8e1 !important;
}
.dark-theme .accent-50 {
  background-color: #fff8e1 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .accent-50[disabled] {
  background-color: rgba(255, 248, 225, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .accent-50-fg {
  color: #fff8e1 !important;
}
.dark-theme .accent-50-border {
  border-color: #fff8e1 !important;
}
.dark-theme .accent-50-border-top {
  border-top-color: #fff8e1 !important;
}
.dark-theme .accent-50-border-right {
  border-right-color: #fff8e1 !important;
}
.dark-theme .accent-50-border-bottom {
  border-bottom-color: #fff8e1 !important;
}
.dark-theme .accent-50-border-left {
  border-left-color: #fff8e1 !important;
}
.dark-theme .accent-100-bg {
  background-color: #ffecb3 !important;
}
.dark-theme .accent-100 {
  background-color: #ffecb3 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .accent-100[disabled] {
  background-color: rgba(255, 236, 179, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .accent-100-fg {
  color: #ffecb3 !important;
}
.dark-theme .accent-100-border {
  border-color: #ffecb3 !important;
}
.dark-theme .accent-100-border-top {
  border-top-color: #ffecb3 !important;
}
.dark-theme .accent-100-border-right {
  border-right-color: #ffecb3 !important;
}
.dark-theme .accent-100-border-bottom {
  border-bottom-color: #ffecb3 !important;
}
.dark-theme .accent-100-border-left {
  border-left-color: #ffecb3 !important;
}
.dark-theme .accent-200-bg {
  background-color: #ffe082 !important;
}
.dark-theme .accent-200 {
  background-color: #ffe082 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .accent-200[disabled] {
  background-color: rgba(255, 224, 130, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .accent-200-fg {
  color: #ffe082 !important;
}
.dark-theme .accent-200-border {
  border-color: #ffe082 !important;
}
.dark-theme .accent-200-border-top {
  border-top-color: #ffe082 !important;
}
.dark-theme .accent-200-border-right {
  border-right-color: #ffe082 !important;
}
.dark-theme .accent-200-border-bottom {
  border-bottom-color: #ffe082 !important;
}
.dark-theme .accent-200-border-left {
  border-left-color: #ffe082 !important;
}
.dark-theme .accent-300-bg {
  background-color: #ffd54f !important;
}
.dark-theme .accent-300 {
  background-color: #ffd54f !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .accent-300[disabled] {
  background-color: rgba(255, 213, 79, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .accent-300-fg {
  color: #ffd54f !important;
}
.dark-theme .accent-300-border {
  border-color: #ffd54f !important;
}
.dark-theme .accent-300-border-top {
  border-top-color: #ffd54f !important;
}
.dark-theme .accent-300-border-right {
  border-right-color: #ffd54f !important;
}
.dark-theme .accent-300-border-bottom {
  border-bottom-color: #ffd54f !important;
}
.dark-theme .accent-300-border-left {
  border-left-color: #ffd54f !important;
}
.dark-theme .accent-400-bg {
  background-color: #ffca28 !important;
}
.dark-theme .accent-400 {
  background-color: #ffca28 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .accent-400[disabled] {
  background-color: rgba(255, 202, 40, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .accent-400-fg {
  color: #ffca28 !important;
}
.dark-theme .accent-400-border {
  border-color: #ffca28 !important;
}
.dark-theme .accent-400-border-top {
  border-top-color: #ffca28 !important;
}
.dark-theme .accent-400-border-right {
  border-right-color: #ffca28 !important;
}
.dark-theme .accent-400-border-bottom {
  border-bottom-color: #ffca28 !important;
}
.dark-theme .accent-400-border-left {
  border-left-color: #ffca28 !important;
}
.dark-theme .accent-500-bg {
  background-color: #ffc107 !important;
}
.dark-theme .accent-500 {
  background-color: #ffc107 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .accent-500[disabled] {
  background-color: rgba(255, 193, 7, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .accent-500-fg {
  color: #ffc107 !important;
}
.dark-theme .accent-500-border {
  border-color: #ffc107 !important;
}
.dark-theme .accent-500-border-top {
  border-top-color: #ffc107 !important;
}
.dark-theme .accent-500-border-right {
  border-right-color: #ffc107 !important;
}
.dark-theme .accent-500-border-bottom {
  border-bottom-color: #ffc107 !important;
}
.dark-theme .accent-500-border-left {
  border-left-color: #ffc107 !important;
}
.dark-theme .accent-600-bg {
  background-color: #ffb300 !important;
}
.dark-theme .accent-600 {
  background-color: #ffb300 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .accent-600[disabled] {
  background-color: rgba(255, 179, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .accent-600-fg {
  color: #ffb300 !important;
}
.dark-theme .accent-600-border {
  border-color: #ffb300 !important;
}
.dark-theme .accent-600-border-top {
  border-top-color: #ffb300 !important;
}
.dark-theme .accent-600-border-right {
  border-right-color: #ffb300 !important;
}
.dark-theme .accent-600-border-bottom {
  border-bottom-color: #ffb300 !important;
}
.dark-theme .accent-600-border-left {
  border-left-color: #ffb300 !important;
}
.dark-theme .accent-700-bg {
  background-color: #ffa000 !important;
}
.dark-theme .accent-700 {
  background-color: #ffa000 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .accent-700[disabled] {
  background-color: rgba(255, 160, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .accent-700-fg {
  color: #ffa000 !important;
}
.dark-theme .accent-700-border {
  border-color: #ffa000 !important;
}
.dark-theme .accent-700-border-top {
  border-top-color: #ffa000 !important;
}
.dark-theme .accent-700-border-right {
  border-right-color: #ffa000 !important;
}
.dark-theme .accent-700-border-bottom {
  border-bottom-color: #ffa000 !important;
}
.dark-theme .accent-700-border-left {
  border-left-color: #ffa000 !important;
}
.dark-theme .accent-800-bg {
  background-color: #ff8f00 !important;
}
.dark-theme .accent-800 {
  background-color: #ff8f00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .accent-800[disabled] {
  background-color: rgba(255, 143, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .accent-800-fg {
  color: #ff8f00 !important;
}
.dark-theme .accent-800-border {
  border-color: #ff8f00 !important;
}
.dark-theme .accent-800-border-top {
  border-top-color: #ff8f00 !important;
}
.dark-theme .accent-800-border-right {
  border-right-color: #ff8f00 !important;
}
.dark-theme .accent-800-border-bottom {
  border-bottom-color: #ff8f00 !important;
}
.dark-theme .accent-800-border-left {
  border-left-color: #ff8f00 !important;
}
.dark-theme .accent-900-bg {
  background-color: #ff6f00 !important;
}
.dark-theme .accent-900 {
  background-color: #ff6f00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .accent-900[disabled] {
  background-color: rgba(255, 111, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .accent-900-fg {
  color: #ff6f00 !important;
}
.dark-theme .accent-900-border {
  border-color: #ff6f00 !important;
}
.dark-theme .accent-900-border-top {
  border-top-color: #ff6f00 !important;
}
.dark-theme .accent-900-border-right {
  border-right-color: #ff6f00 !important;
}
.dark-theme .accent-900-border-bottom {
  border-bottom-color: #ff6f00 !important;
}
.dark-theme .accent-900-border-left {
  border-left-color: #ff6f00 !important;
}
.dark-theme .accent-A100-bg {
  background-color: #ffe57f !important;
}
.dark-theme .accent-A100 {
  background-color: #ffe57f !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .accent-A100[disabled] {
  background-color: rgba(255, 229, 127, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .accent-A100-fg {
  color: #ffe57f !important;
}
.dark-theme .accent-A100-border {
  border-color: #ffe57f !important;
}
.dark-theme .accent-A100-border-top {
  border-top-color: #ffe57f !important;
}
.dark-theme .accent-A100-border-right {
  border-right-color: #ffe57f !important;
}
.dark-theme .accent-A100-border-bottom {
  border-bottom-color: #ffe57f !important;
}
.dark-theme .accent-A100-border-left {
  border-left-color: #ffe57f !important;
}
.dark-theme .accent-A200-bg {
  background-color: #ffd740 !important;
}
.dark-theme .accent-A200 {
  background-color: #ffd740 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .accent-A200[disabled] {
  background-color: rgba(255, 215, 64, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .accent-A200-fg {
  color: #ffd740 !important;
}
.dark-theme .accent-A200-border {
  border-color: #ffd740 !important;
}
.dark-theme .accent-A200-border-top {
  border-top-color: #ffd740 !important;
}
.dark-theme .accent-A200-border-right {
  border-right-color: #ffd740 !important;
}
.dark-theme .accent-A200-border-bottom {
  border-bottom-color: #ffd740 !important;
}
.dark-theme .accent-A200-border-left {
  border-left-color: #ffd740 !important;
}
.dark-theme .accent-A400-bg {
  background-color: #ffc400 !important;
}
.dark-theme .accent-A400 {
  background-color: #ffc400 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .accent-A400[disabled] {
  background-color: rgba(255, 196, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .accent-A400-fg {
  color: #ffc400 !important;
}
.dark-theme .accent-A400-border {
  border-color: #ffc400 !important;
}
.dark-theme .accent-A400-border-top {
  border-top-color: #ffc400 !important;
}
.dark-theme .accent-A400-border-right {
  border-right-color: #ffc400 !important;
}
.dark-theme .accent-A400-border-bottom {
  border-bottom-color: #ffc400 !important;
}
.dark-theme .accent-A400-border-left {
  border-left-color: #ffc400 !important;
}
.dark-theme .accent-A700-bg {
  background-color: #ffab00 !important;
}
.dark-theme .accent-A700 {
  background-color: #ffab00 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .accent-A700[disabled] {
  background-color: rgba(255, 171, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .accent-A700-fg {
  color: #ffab00 !important;
}
.dark-theme .accent-A700-border {
  border-color: #ffab00 !important;
}
.dark-theme .accent-A700-border-top {
  border-top-color: #ffab00 !important;
}
.dark-theme .accent-A700-border-right {
  border-right-color: #ffab00 !important;
}
.dark-theme .accent-A700-border-bottom {
  border-bottom-color: #ffab00 !important;
}
.dark-theme .accent-A700-border-left {
  border-left-color: #ffab00 !important;
}
.dark-theme .accent-bg {
  background-color: #ffc107 !important;
}
.dark-theme .accent {
  background-color: #ffc107 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .accent[disabled] {
  background-color: rgba(255, 193, 7, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .accent-fg {
  color: #ffc107 !important;
}
.dark-theme .accent-border {
  border-color: #ffc107 !important;
}
.dark-theme .accent-border-top {
  border-top-color: #ffc107 !important;
}
.dark-theme .accent-border-right {
  border-right-color: #ffc107 !important;
}
.dark-theme .accent-border-bottom {
  border-bottom-color: #ffc107 !important;
}
.dark-theme .accent-border-left {
  border-left-color: #ffc107 !important;
}
.dark-theme .white-50-bg {
  background-color: white !important;
}
.dark-theme .white-50 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .white-50[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .white-50-fg {
  color: white !important;
}
.dark-theme .white-50-border {
  border-color: white !important;
}
.dark-theme .white-50-border-top {
  border-top-color: white !important;
}
.dark-theme .white-50-border-right {
  border-right-color: white !important;
}
.dark-theme .white-50-border-bottom {
  border-bottom-color: white !important;
}
.dark-theme .white-50-border-left {
  border-left-color: white !important;
}
.dark-theme .white-100-bg {
  background-color: white !important;
}
.dark-theme .white-100 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .white-100[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .white-100-fg {
  color: white !important;
}
.dark-theme .white-100-border {
  border-color: white !important;
}
.dark-theme .white-100-border-top {
  border-top-color: white !important;
}
.dark-theme .white-100-border-right {
  border-right-color: white !important;
}
.dark-theme .white-100-border-bottom {
  border-bottom-color: white !important;
}
.dark-theme .white-100-border-left {
  border-left-color: white !important;
}
.dark-theme .white-200-bg {
  background-color: white !important;
}
.dark-theme .white-200 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .white-200[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .white-200-fg {
  color: white !important;
}
.dark-theme .white-200-border {
  border-color: white !important;
}
.dark-theme .white-200-border-top {
  border-top-color: white !important;
}
.dark-theme .white-200-border-right {
  border-right-color: white !important;
}
.dark-theme .white-200-border-bottom {
  border-bottom-color: white !important;
}
.dark-theme .white-200-border-left {
  border-left-color: white !important;
}
.dark-theme .white-300-bg {
  background-color: white !important;
}
.dark-theme .white-300 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .white-300[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .white-300-fg {
  color: white !important;
}
.dark-theme .white-300-border {
  border-color: white !important;
}
.dark-theme .white-300-border-top {
  border-top-color: white !important;
}
.dark-theme .white-300-border-right {
  border-right-color: white !important;
}
.dark-theme .white-300-border-bottom {
  border-bottom-color: white !important;
}
.dark-theme .white-300-border-left {
  border-left-color: white !important;
}
.dark-theme .white-400-bg {
  background-color: white !important;
}
.dark-theme .white-400 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .white-400[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .white-400-fg {
  color: white !important;
}
.dark-theme .white-400-border {
  border-color: white !important;
}
.dark-theme .white-400-border-top {
  border-top-color: white !important;
}
.dark-theme .white-400-border-right {
  border-right-color: white !important;
}
.dark-theme .white-400-border-bottom {
  border-bottom-color: white !important;
}
.dark-theme .white-400-border-left {
  border-left-color: white !important;
}
.dark-theme .white-500-bg {
  background-color: white !important;
}
.dark-theme .white-500 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .white-500[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .white-500-fg {
  color: white !important;
}
.dark-theme .white-500-border {
  border-color: white !important;
}
.dark-theme .white-500-border-top {
  border-top-color: white !important;
}
.dark-theme .white-500-border-right {
  border-right-color: white !important;
}
.dark-theme .white-500-border-bottom {
  border-bottom-color: white !important;
}
.dark-theme .white-500-border-left {
  border-left-color: white !important;
}
.dark-theme .white-bg {
  background-color: white !important;
}
.dark-theme .white {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .white[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .white-fg {
  color: white !important;
}
.dark-theme .white-border {
  border-color: white !important;
}
.dark-theme .white-border-top {
  border-top-color: white !important;
}
.dark-theme .white-border-right {
  border-right-color: white !important;
}
.dark-theme .white-border-bottom {
  border-bottom-color: white !important;
}
.dark-theme .white-border-left {
  border-left-color: white !important;
}
.dark-theme .white-600-bg {
  background-color: white !important;
}
.dark-theme .white-600 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .white-600[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .white-600-fg {
  color: white !important;
}
.dark-theme .white-600-border {
  border-color: white !important;
}
.dark-theme .white-600-border-top {
  border-top-color: white !important;
}
.dark-theme .white-600-border-right {
  border-right-color: white !important;
}
.dark-theme .white-600-border-bottom {
  border-bottom-color: white !important;
}
.dark-theme .white-600-border-left {
  border-left-color: white !important;
}
.dark-theme .white-700-bg {
  background-color: white !important;
}
.dark-theme .white-700 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .white-700[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .white-700-fg {
  color: white !important;
}
.dark-theme .white-700-border {
  border-color: white !important;
}
.dark-theme .white-700-border-top {
  border-top-color: white !important;
}
.dark-theme .white-700-border-right {
  border-right-color: white !important;
}
.dark-theme .white-700-border-bottom {
  border-bottom-color: white !important;
}
.dark-theme .white-700-border-left {
  border-left-color: white !important;
}
.dark-theme .white-800-bg {
  background-color: white !important;
}
.dark-theme .white-800 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .white-800[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .white-800-fg {
  color: white !important;
}
.dark-theme .white-800-border {
  border-color: white !important;
}
.dark-theme .white-800-border-top {
  border-top-color: white !important;
}
.dark-theme .white-800-border-right {
  border-right-color: white !important;
}
.dark-theme .white-800-border-bottom {
  border-bottom-color: white !important;
}
.dark-theme .white-800-border-left {
  border-left-color: white !important;
}
.dark-theme .white-900-bg {
  background-color: white !important;
}
.dark-theme .white-900 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .white-900[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .white-900-fg {
  color: white !important;
}
.dark-theme .white-900-border {
  border-color: white !important;
}
.dark-theme .white-900-border-top {
  border-top-color: white !important;
}
.dark-theme .white-900-border-right {
  border-right-color: white !important;
}
.dark-theme .white-900-border-bottom {
  border-bottom-color: white !important;
}
.dark-theme .white-900-border-left {
  border-left-color: white !important;
}
.dark-theme .white-A100-bg {
  background-color: white !important;
}
.dark-theme .white-A100 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .white-A100[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .white-A100-fg {
  color: white !important;
}
.dark-theme .white-A100-border {
  border-color: white !important;
}
.dark-theme .white-A100-border-top {
  border-top-color: white !important;
}
.dark-theme .white-A100-border-right {
  border-right-color: white !important;
}
.dark-theme .white-A100-border-bottom {
  border-bottom-color: white !important;
}
.dark-theme .white-A100-border-left {
  border-left-color: white !important;
}
.dark-theme .white-A200-bg {
  background-color: white !important;
}
.dark-theme .white-A200 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .white-A200[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .white-A200-fg {
  color: white !important;
}
.dark-theme .white-A200-border {
  border-color: white !important;
}
.dark-theme .white-A200-border-top {
  border-top-color: white !important;
}
.dark-theme .white-A200-border-right {
  border-right-color: white !important;
}
.dark-theme .white-A200-border-bottom {
  border-bottom-color: white !important;
}
.dark-theme .white-A200-border-left {
  border-left-color: white !important;
}
.dark-theme .white-A400-bg {
  background-color: white !important;
}
.dark-theme .white-A400 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .white-A400[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .white-A400-fg {
  color: white !important;
}
.dark-theme .white-A400-border {
  border-color: white !important;
}
.dark-theme .white-A400-border-top {
  border-top-color: white !important;
}
.dark-theme .white-A400-border-right {
  border-right-color: white !important;
}
.dark-theme .white-A400-border-bottom {
  border-bottom-color: white !important;
}
.dark-theme .white-A400-border-left {
  border-left-color: white !important;
}
.dark-theme .white-A700-bg {
  background-color: white !important;
}
.dark-theme .white-A700 {
  background-color: white !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .white-A700[disabled] {
  background-color: rgba(255, 255, 255, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .white-A700-fg {
  color: white !important;
}
.dark-theme .white-A700-border {
  border-color: white !important;
}
.dark-theme .white-A700-border-top {
  border-top-color: white !important;
}
.dark-theme .white-A700-border-right {
  border-right-color: white !important;
}
.dark-theme .white-A700-border-bottom {
  border-bottom-color: white !important;
}
.dark-theme .white-A700-border-left {
  border-left-color: white !important;
}
.dark-theme .warn-50-bg {
  background-color: #ffebee !important;
}
.dark-theme .warn-50 {
  background-color: #ffebee !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .warn-50[disabled] {
  background-color: rgba(255, 235, 238, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .warn-50-fg {
  color: #ffebee !important;
}
.dark-theme .warn-50-border {
  border-color: #ffebee !important;
}
.dark-theme .warn-50-border-top {
  border-top-color: #ffebee !important;
}
.dark-theme .warn-50-border-right {
  border-right-color: #ffebee !important;
}
.dark-theme .warn-50-border-bottom {
  border-bottom-color: #ffebee !important;
}
.dark-theme .warn-50-border-left {
  border-left-color: #ffebee !important;
}
.dark-theme .warn-100-bg {
  background-color: #ffcdd2 !important;
}
.dark-theme .warn-100 {
  background-color: #ffcdd2 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .warn-100[disabled] {
  background-color: rgba(255, 205, 210, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .warn-100-fg {
  color: #ffcdd2 !important;
}
.dark-theme .warn-100-border {
  border-color: #ffcdd2 !important;
}
.dark-theme .warn-100-border-top {
  border-top-color: #ffcdd2 !important;
}
.dark-theme .warn-100-border-right {
  border-right-color: #ffcdd2 !important;
}
.dark-theme .warn-100-border-bottom {
  border-bottom-color: #ffcdd2 !important;
}
.dark-theme .warn-100-border-left {
  border-left-color: #ffcdd2 !important;
}
.dark-theme .warn-200-bg {
  background-color: #ef9a9a !important;
}
.dark-theme .warn-200 {
  background-color: #ef9a9a !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .warn-200[disabled] {
  background-color: rgba(239, 154, 154, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .warn-200-fg {
  color: #ef9a9a !important;
}
.dark-theme .warn-200-border {
  border-color: #ef9a9a !important;
}
.dark-theme .warn-200-border-top {
  border-top-color: #ef9a9a !important;
}
.dark-theme .warn-200-border-right {
  border-right-color: #ef9a9a !important;
}
.dark-theme .warn-200-border-bottom {
  border-bottom-color: #ef9a9a !important;
}
.dark-theme .warn-200-border-left {
  border-left-color: #ef9a9a !important;
}
.dark-theme .warn-300-bg {
  background-color: #e57373 !important;
}
.dark-theme .warn-300 {
  background-color: #e57373 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .warn-300[disabled] {
  background-color: rgba(229, 115, 115, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .warn-300-fg {
  color: #e57373 !important;
}
.dark-theme .warn-300-border {
  border-color: #e57373 !important;
}
.dark-theme .warn-300-border-top {
  border-top-color: #e57373 !important;
}
.dark-theme .warn-300-border-right {
  border-right-color: #e57373 !important;
}
.dark-theme .warn-300-border-bottom {
  border-bottom-color: #e57373 !important;
}
.dark-theme .warn-300-border-left {
  border-left-color: #e57373 !important;
}
.dark-theme .warn-400-bg {
  background-color: #ef5350 !important;
}
.dark-theme .warn-400 {
  background-color: #ef5350 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .warn-400[disabled] {
  background-color: rgba(239, 83, 80, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .warn-400-fg {
  color: #ef5350 !important;
}
.dark-theme .warn-400-border {
  border-color: #ef5350 !important;
}
.dark-theme .warn-400-border-top {
  border-top-color: #ef5350 !important;
}
.dark-theme .warn-400-border-right {
  border-right-color: #ef5350 !important;
}
.dark-theme .warn-400-border-bottom {
  border-bottom-color: #ef5350 !important;
}
.dark-theme .warn-400-border-left {
  border-left-color: #ef5350 !important;
}
.dark-theme .warn-500-bg {
  background-color: #f44336 !important;
}
.dark-theme .warn-500 {
  background-color: #f44336 !important;
  color: white !important;
}
.dark-theme .warn-500[disabled] {
  background-color: rgba(244, 67, 54, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.dark-theme .warn-500-fg {
  color: #f44336 !important;
}
.dark-theme .warn-500-border {
  border-color: #f44336 !important;
}
.dark-theme .warn-500-border-top {
  border-top-color: #f44336 !important;
}
.dark-theme .warn-500-border-right {
  border-right-color: #f44336 !important;
}
.dark-theme .warn-500-border-bottom {
  border-bottom-color: #f44336 !important;
}
.dark-theme .warn-500-border-left {
  border-left-color: #f44336 !important;
}
.dark-theme .warn-600-bg {
  background-color: #e53935 !important;
}
.dark-theme .warn-600 {
  background-color: #e53935 !important;
  color: white !important;
}
.dark-theme .warn-600[disabled] {
  background-color: rgba(229, 57, 53, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.dark-theme .warn-600-fg {
  color: #e53935 !important;
}
.dark-theme .warn-600-border {
  border-color: #e53935 !important;
}
.dark-theme .warn-600-border-top {
  border-top-color: #e53935 !important;
}
.dark-theme .warn-600-border-right {
  border-right-color: #e53935 !important;
}
.dark-theme .warn-600-border-bottom {
  border-bottom-color: #e53935 !important;
}
.dark-theme .warn-600-border-left {
  border-left-color: #e53935 !important;
}
.dark-theme .warn-700-bg {
  background-color: #d32f2f !important;
}
.dark-theme .warn-700 {
  background-color: #d32f2f !important;
  color: white !important;
}
.dark-theme .warn-700[disabled] {
  background-color: rgba(211, 47, 47, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.dark-theme .warn-700-fg {
  color: #d32f2f !important;
}
.dark-theme .warn-700-border {
  border-color: #d32f2f !important;
}
.dark-theme .warn-700-border-top {
  border-top-color: #d32f2f !important;
}
.dark-theme .warn-700-border-right {
  border-right-color: #d32f2f !important;
}
.dark-theme .warn-700-border-bottom {
  border-bottom-color: #d32f2f !important;
}
.dark-theme .warn-700-border-left {
  border-left-color: #d32f2f !important;
}
.dark-theme .warn-800-bg {
  background-color: #c62828 !important;
}
.dark-theme .warn-800 {
  background-color: #c62828 !important;
  color: white !important;
}
.dark-theme .warn-800[disabled] {
  background-color: rgba(198, 40, 40, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.dark-theme .warn-800-fg {
  color: #c62828 !important;
}
.dark-theme .warn-800-border {
  border-color: #c62828 !important;
}
.dark-theme .warn-800-border-top {
  border-top-color: #c62828 !important;
}
.dark-theme .warn-800-border-right {
  border-right-color: #c62828 !important;
}
.dark-theme .warn-800-border-bottom {
  border-bottom-color: #c62828 !important;
}
.dark-theme .warn-800-border-left {
  border-left-color: #c62828 !important;
}
.dark-theme .warn-900-bg {
  background-color: #b71c1c !important;
}
.dark-theme .warn-900 {
  background-color: #b71c1c !important;
  color: white !important;
}
.dark-theme .warn-900[disabled] {
  background-color: rgba(183, 28, 28, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.dark-theme .warn-900-fg {
  color: #b71c1c !important;
}
.dark-theme .warn-900-border {
  border-color: #b71c1c !important;
}
.dark-theme .warn-900-border-top {
  border-top-color: #b71c1c !important;
}
.dark-theme .warn-900-border-right {
  border-right-color: #b71c1c !important;
}
.dark-theme .warn-900-border-bottom {
  border-bottom-color: #b71c1c !important;
}
.dark-theme .warn-900-border-left {
  border-left-color: #b71c1c !important;
}
.dark-theme .warn-A100-bg {
  background-color: #ff8a80 !important;
}
.dark-theme .warn-A100 {
  background-color: #ff8a80 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.dark-theme .warn-A100[disabled] {
  background-color: rgba(255, 138, 128, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}
.dark-theme .warn-A100-fg {
  color: #ff8a80 !important;
}
.dark-theme .warn-A100-border {
  border-color: #ff8a80 !important;
}
.dark-theme .warn-A100-border-top {
  border-top-color: #ff8a80 !important;
}
.dark-theme .warn-A100-border-right {
  border-right-color: #ff8a80 !important;
}
.dark-theme .warn-A100-border-bottom {
  border-bottom-color: #ff8a80 !important;
}
.dark-theme .warn-A100-border-left {
  border-left-color: #ff8a80 !important;
}
.dark-theme .warn-A200-bg {
  background-color: #ff5252 !important;
}
.dark-theme .warn-A200 {
  background-color: #ff5252 !important;
  color: white !important;
}
.dark-theme .warn-A200[disabled] {
  background-color: rgba(255, 82, 82, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.dark-theme .warn-A200-fg {
  color: #ff5252 !important;
}
.dark-theme .warn-A200-border {
  border-color: #ff5252 !important;
}
.dark-theme .warn-A200-border-top {
  border-top-color: #ff5252 !important;
}
.dark-theme .warn-A200-border-right {
  border-right-color: #ff5252 !important;
}
.dark-theme .warn-A200-border-bottom {
  border-bottom-color: #ff5252 !important;
}
.dark-theme .warn-A200-border-left {
  border-left-color: #ff5252 !important;
}
.dark-theme .warn-A400-bg {
  background-color: #ff1744 !important;
}
.dark-theme .warn-A400 {
  background-color: #ff1744 !important;
  color: white !important;
}
.dark-theme .warn-A400[disabled] {
  background-color: rgba(255, 23, 68, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.dark-theme .warn-A400-fg {
  color: #ff1744 !important;
}
.dark-theme .warn-A400-border {
  border-color: #ff1744 !important;
}
.dark-theme .warn-A400-border-top {
  border-top-color: #ff1744 !important;
}
.dark-theme .warn-A400-border-right {
  border-right-color: #ff1744 !important;
}
.dark-theme .warn-A400-border-bottom {
  border-bottom-color: #ff1744 !important;
}
.dark-theme .warn-A400-border-left {
  border-left-color: #ff1744 !important;
}
.dark-theme .warn-A700-bg {
  background-color: #d50000 !important;
}
.dark-theme .warn-A700 {
  background-color: #d50000 !important;
  color: white !important;
}
.dark-theme .warn-A700[disabled] {
  background-color: rgba(213, 0, 0, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.dark-theme .warn-A700-fg {
  color: #d50000 !important;
}
.dark-theme .warn-A700-border {
  border-color: #d50000 !important;
}
.dark-theme .warn-A700-border-top {
  border-top-color: #d50000 !important;
}
.dark-theme .warn-A700-border-right {
  border-right-color: #d50000 !important;
}
.dark-theme .warn-A700-border-bottom {
  border-bottom-color: #d50000 !important;
}
.dark-theme .warn-A700-border-left {
  border-left-color: #d50000 !important;
}
.dark-theme .warn-bg {
  background-color: #f44336 !important;
}
.dark-theme .warn {
  background-color: #f44336 !important;
  color: white !important;
}
.dark-theme .warn[disabled] {
  background-color: rgba(244, 67, 54, 0.12) !important;
  color: rgba(255, 255, 255, 0.26) !important;
}
.dark-theme .warn-fg {
  color: #f44336 !important;
}
.dark-theme .warn-border {
  border-color: #f44336 !important;
}
.dark-theme .warn-border-top {
  border-top-color: #f44336 !important;
}
.dark-theme .warn-border-right {
  border-right-color: #f44336 !important;
}
.dark-theme .warn-border-bottom {
  border-bottom-color: #f44336 !important;
}
.dark-theme .warn-border-left {
  border-left-color: #f44336 !important;
}
.dark-theme #theme-color-ref {
  background-color: #000000 !important;
}
.dark-theme .mat-progress-bar-background {
  fill: #323232;
}
.dark-theme .mat-progress-bar-buffer {
  background-color: #323232;
}
.dark-theme mat-chip.mat-chip.mat-standard-chip {
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.dark-theme mat-chip.mat-chip.mat-standard-chip .mat-icon {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

mat-toolbar.mat-toolbar {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
mat-toolbar.mat-toolbar .width-container {
  max-width: 500px;
}
mat-toolbar.mat-toolbar .toolbar-items {
  display: flex;
  align-items: center;
  width: 100%;
}
mat-toolbar.mat-toolbar .toolbar-items button[mat-icon-button]:first-child {
  margin-left: -8px;
  margin-right: 22px;
}
mat-toolbar.mat-toolbar .toolbar-items .mat-button-base:last-child {
  margin-right: -8px;
}
mat-toolbar.mat-toolbar .toolbar-items .mat-button-base {
  flex-grow: 0;
  flex-shrink: 0;
}
mat-toolbar.mat-toolbar .titles {
  display: inline-flex;
  flex: 1 1 auto;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  min-width: 0;
  padding: 6px 0;
  white-space: pre-wrap;
}
mat-toolbar.mat-toolbar .titles span.title,
mat-toolbar.mat-toolbar .titles span.subtitle:not(.overflow) {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  max-width: 100%;
}
mat-toolbar.mat-toolbar .titles span.title {
  line-height: 24px;
  margin: 0;
}
mat-toolbar.mat-toolbar .titles span.subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.87;
}

nav.mat-tab-nav-bar[mat-bottom-navigation] {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: #009688;
  color: white;
  border-bottom: 0;
}
nav.mat-tab-nav-bar[mat-bottom-navigation] mat-ink-bar.mat-ink-bar {
  background-color: white !important;
}
nav.mat-tab-nav-bar[mat-bottom-navigation] a.mat-tab-link {
  text-transform: uppercase;
  letter-spacing: 0.089em;
  font-weight: 500;
  color: white;
}
nav.mat-tab-nav-bar[mat-bottom-navigation] a.mat-tab-link.mat-tab-label-active {
  opacity: 1;
}
nav.mat-tab-nav-bar[mat-bottom-navigation] a.mat-tab-link[has-icon] {
  height: 56px;
}

.app-sheet-maxwidth .mat-bottom-sheet-container {
  max-width: 532px;
  min-width: 0px;
  width: 100vw;
  padding: 0;
}
.app-sheet-maxwidth.full-height .mat-bottom-sheet-container {
  max-height: calc(100vh - 56px - 56px);
  height: calc(100vh - 56px - 56px);
}

.app-darker-backdrop {
  background-color: rgba(0, 0, 0, 0.32);
}

.light-theme mat-chip.mat-chip.mat-standard-chip, .dark-theme mat-chip.mat-chip.mat-standard-chip {
  background-color: transparent;
  font-weight: 400;
}
.light-theme mat-chip.mat-chip.mat-standard-chip .mat-icon, .dark-theme mat-chip.mat-chip.mat-standard-chip .mat-icon {
  opacity: 1;
}

.mat-fab[has-text] {
  width: auto;
  border-radius: 24px;
  height: 48px;
  pointer-events: all;
}
.mat-fab[has-text] .mat-button-wrapper {
  padding: 12px;
  padding-right: 20px;
  height: 24px;
}
.mat-fab[has-text] .mat-button-wrapper span {
  display: flex;
}
.mat-fab[has-text] .mat-button-wrapper > * {
  vertical-align: top;
}
.mat-fab[has-text] .mat-button-wrapper mat-icon {
  margin-right: 8px;
}

.mat-mini-fab:not([has-text]) .mat-button-wrapper > *,
.mat-fab:not([has-text]) .mat-button-wrapper > * {
  vertical-align: initial !important;
}

.mat-button-base[has-icon] {
  padding: 0 16px 0 12px;
}
.mat-button-base[has-icon] .mat-button-wrapper {
  display: flex;
  align-items: center;
}
.mat-button-base[has-icon] .mat-button-wrapper mat-icon {
  margin-right: 8px;
}

[icon-with-text] {
  display: flex;
  align-items: center;
}
[icon-with-text][is-inline] {
  display: inline-flex;
}
[icon-with-text] mat-icon {
  margin-right: 4px;
  flex-shrink: 0;
}
[icon-with-text][icon-start] {
  align-items: flex-start;
}